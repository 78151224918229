<div class="content">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    class="mat-elevation-z8 vehicleTable"
  >
    <!-- name column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Name</th>
      <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
        <a aria-label="Click to view vehicle" matTooltip="Click to view vehicle" (click)="viewVehicle(vehicle)">
          {{ vehicle.name }}
        </a>
      </td>
    </ng-container>

    <!-- designation column -->
    <ng-container matColumnDef="designation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
      <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
        <a aria-label="Click to view vehicle" matTooltip="Click to view vehicle" (click)="viewVehicle(vehicle)">
          {{ vehicle.designation }}
        </a>
      </td>
    </ng-container>

    <!-- updatedAt column -->
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
      <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
        <a aria-label="Click to view vehicle" matTooltip="Click to view vehicle" (click)="viewVehicle(vehicle)">
          {{ vehicle.updatedAt | date: 'short' }}
        </a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let vehicle">
        <mat-toolbar class="actionButtonBar">
          <a
            mat-icon-button
            color="primary"
            aria-label="View Vehicle"
            matTooltip="View Vehicle"
            (click)="viewVehicle(vehicle)"
          >
            <img src="assets/img/Search.png" alt="View" />
          </a>
          <a
            mat-icon-button
            color="warn"
            aria-label="Delete Vehicle"
            matTooltip="Delete Vehicle"
            (click)="deleteVehicle(vehicle)"
            *ngIf="canDelete(vehicle)"
          >
            <img src="assets/img/TrashGray.png" alt="Delete" />
          </a>
        </mat-toolbar>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedVehicleColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedVehicleColumns"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching vehicles ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of vehicles"
  </mat-paginator>
</div>
