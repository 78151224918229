import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Note, Project, Report, ReportSection, Ship, User, Vehicle } from '@shared/models';
import {
  ErrorService,
  LogService,
  NoteService,
  ProjectService,
  ReportService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { ReportItemDialogComponent } from '../report/report-item-dialog/report-item-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-note-table',
    templateUrl: './note-table.component.html',
    styleUrls: ['./note-table.component.css'],
    standalone: false
})
export class NoteTableComponent implements OnInit {
  @Input()
  isSnapshotOrVideoNotes: boolean;

  @Input()
  notes: Note[];

  @Input()
  project: Project;

  @Input()
  report: Report;

  @Input()
  reportSections: ReportSection[];

  @Input()
  ship: Ship;

  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  displayedNoteColumns: string[];
  errorMsg: boolean = false;
  errorText: string = '';
  reportSections$: Observable<ReportSection[]>;
  private noteErrorSubject = new BehaviorSubject<string>(null);
  noteError$: Observable<string> = this.noteErrorSubject.asObservable();

  constructor(
    private dialog: MatDialog,
    private errorService: ErrorService,
    private logService: LogService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private VehicleService: VehicleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.reportSections$ = this.reportService.reportSections$;
    this.displayedNoteColumns = this.isSnapshotOrVideoNotes
      ? ['noteText', 'actions']
      : ['reportSection', 'noteText', 'actions'];
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  canDelete(note: Note): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === note.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  canEdit(note: Note): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === note.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  deleteNote(note: Note): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    Swal.fire({
      title: `Delete This Note?`,
      text: `${note.noteText}`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        _this.noteService
          .deleteNote(note._id, note.parent.collection, note.parent._id, currentUser)
          .then(function (deleteResults) {
            console.log(`deleted noteId ${note._id}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              let errorTitle = 'Error Deleting Note';
              Swal.fire(errorTitle, deleteError.message, 'error');
            }
          });
      }
    });
  }

  editNote(note: Note) {
    const currentUser = this.userService.getCurrentUser();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewNote: false,
      note: note,
      project: this.project,
      report: this.report,
      ship: this.ship,
      vehicle: this.vehicle,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  getReportSectionTitleCase(reportSectionId: string) {
    const matching = this.reportSections.find((reportSection) => reportSection._id === reportSectionId);
    return matching ? matching.titleCase : '';
  }
}
