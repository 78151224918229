import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Ship, ShipClass, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ProjectService,
  SettingsService,
  ScanService,
  ShipService,
  UnrealServerService,
  UserService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-ship-card',
    templateUrl: './ship-card.component.html',
    styleUrls: ['./ship-card.component.css'],
    standalone: false
})
export class ShipCardComponent implements OnInit {
  @Input()
  ship: Ship;

  currentUser$: Observable<User>;
  shipClass$: Observable<ShipClass>;
  private shipErrorSubject = new BehaviorSubject<string>(null);
  shipError$: Observable<string> = this.shipErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.shipClass$ = this.shipService.currentShipClass$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.ship.creatorId) {
      let projectCount = (this.ship.usedInProjects ? this.ship.usedInProjects.length : 0)  
      + (this.ship.usedInProjects ? this.ship.usedInProjects.length : 0);
      if (projectCount === 0) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get shipDesignation(): string {
    return this.ship.designationWithHullNumber || this.ship.hullNumber.toString();
  }


  deleteShip(ship: Ship): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    this.shipService
      .getShipById(ship._id, currentUser)
      .then((s: Ship) => {
        Swal.fire({
          title: `Delete Ship:  ${ship.name} | ${ship.designationWithHullNumber}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.shipService
              .deleteShip(ship._id, currentUser)
              .then((ships: Ship[]) => {
                this.router.navigate(['/ships']);
              })
              .catch((error) => {
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    `Error Deleting Ship`,
                    `There was an error deleting the ship.  Please email ${environment.techSupportEmail}.`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((error) => {
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error retrieving the ship info needed for deletion.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  viewShip(ship: Ship): void {
    const currentUser = this.userService.getCurrentUser();

    if (ship && currentUser) {
      this.settingsService.setIsLoading(true);
      //first, make sure there is no project selected, that will clear any project, ship and vehicle so that the appropriate values are there if they load the unreal viewer
      const promises = [];
      promises.push(this.projectService.getProjectById(null, currentUser));
      promises.push(this.shipService.getShipById(ship._id, currentUser));

      Promise.allSettled(promises)
        .then((results) => {
          if (results[1].status === 'fulfilled') {
            const navigationUrl = `/ships/${ship._id}`;
            this.router.navigateByUrl(navigationUrl);
          } else {
            this.settingsService.setIsLoading(false);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Loading Ship`,
                `There was an error loading the ship information.  Please email  ${environment.techSupportEmail}.`,
                'error'
              );
            }
          }
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Loading Ship',
          `Unable to load ship and user data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
