import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { ImageDoc, User } from '@shared/models';
import { ErrorService, FileService, ImageDocService, LogService, SettingsService, UserService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { getYesOrNo } from '@shared/utils';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-image-doc-table',
    templateUrl: './image-doc-table.component.html',
    styleUrls: ['./image-doc-table.component.css'],
    standalone: false
})
export class ImageDocTableComponent implements OnInit {
  @Input()
  images: ImageDoc[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  displayedImageDocColumns: string[] = ['name', 'isMainImage', 'isSnapshot', 'createdAt', 'actions'];
  private imageDocErrorSubject = new BehaviorSubject<string>(null);
  private imageSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  imageDocError$: Observable<string> = this.imageDocErrorSubject.asObservable();
  imageSafeUrl$: Observable<SafeResourceUrl> = this.imageSafeUrlSubject.asObservable();
  dataSource;

  constructor(
        private domSanitizer: DomSanitizer,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.images);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeImageUrl(): SafeResourceUrl {
    return this.imageSafeUrlSubject.getValue();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async download(img: ImageDoc, currentUser: User) {
    if (img?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, false);
        this.imageSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(downloadUrl));

        if (downloadUrl) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Image ${img.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No image was provided to download.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  getYesOrNo(value: boolean): string {
    return getYesOrNo(value);
  }

  async viewImage(img: ImageDoc, currentUser: User) {
    if (img?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, true);
        if (downloadUrl) {
          window.open(downloadUrl, '_blank');
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Viewing Image ${img.name}`,
            `There was an error viewing the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No image was provided to view.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
