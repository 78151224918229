<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <div class="content">
    <ng-container *ngIf="{
        allowVehicleModels3d: allowVehicleModels3d$ | async,
        allowVehicleScans: allowVehicleScans$ | async,
        reportSections: reportSections$ | async,
        updatedModel3dId: updatedModel3dId$ | async,
        vehicle: vehicle$ | async,
        vehicleDesignation: vehicleDesignation$ | async,
        vehicleDocuments: vehicleDocuments$ | async,
        vehicleImages: vehicleImages$ | async,
        vehicleModels3d: vehicleModels3d$ | async,
        vehicleNotes: vehicleNotes$ | async,
        vehicleScans: vehicleScans$ | async,
        vehicleVideos: vehicleVideos$ | async
      } as data">
      <div *ngIf="data.vehicle">
        <h1 style="display: inline-block">{{ data.vehicle.name }} | {{ data.vehicle.designation }}</h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="toolbar-action-buttons">
          <button mat-stroked-button aria-label="Expand all" (click)="accordion.openAll()">Expand All</button>
          <button mat-stroked-button aria-label="Close all" (click)="accordion.closeAll()">Collapse All</button>
          <button mat-stroked-button aria-label="Edit Project" (click)="editVehicle(data.vehicle, currentUser)">
            <img src="assets/img/Edit.png" alt="Edit" />
            <span>&nbsp;&nbsp;Edit Vehicle</span>
          </button>
          <div>
            <mat-checkbox class="example-margin" aria-label="Get Activity Notifications?"
              matTooltip="Get Activity Notifications?" [checked]="getIsGettingNotifications(data.vehicle)"
              (change)="updateNotifications($event.checked, data.vehicle)">
              <span>&nbsp;&nbsp;Get Notifications?</span>
            </mat-checkbox>
          </div>
        </div>

        <div>
          <p *ngIf="errorMsg">{{ errorText }}</p>
        </div>

        <mat-accordion class="example-headers-align">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Vehicle Information</mat-panel-title>
              <mat-panel-description>General information about this vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <div class="vehicle-grid-component">
              <div>
                <table style="width: 100%">
                  <tr>
                    <td style="width: 25%">Name</td>
                    <td style="width: 75%">{{ data.vehicle.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Model Number</td>
                    <td style="width: 75%">{{ data.vehicle.modelName }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Equipment Type</td>
                    <td style="width: 75%">{{ equipmentTypeName }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Purpose</td>
                    <td style="width: 75%">{{ data.vehicle.purpose }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Movement Type</td>
                    <td style="width: 75%">{{ movementTypeName }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Is Motorized</td>
                    <td style="width: 75%">{{ getYesOrNo(data.vehicle.isMotorized) }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Engine</td>
                    <td style="width: 75%" *ngIf="isMotorizedVehicle$ | async">{{ data.vehicle.engine }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Maximum Horsepower</td>
                    <td style="width: 75%" *ngIf="isMotorizedVehicle$ | async">
                      {{ data.vehicle.maximumHorsepower.value }} {{ data.vehicle.maximumHorsepower.units }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Torque</td>
                    <td style="width: 75%" *ngIf="isMotorizedVehicle$ | async">
                      {{ data.vehicle.maximumTorque.value }} {{ data.vehicle.maximumTorque.units }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Has Brakes</td>
                    <td style="width: 75%">{{ getYesOrNo(data.vehicle.hasBrakes) }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Braking</td>
                    <td style="width: 75%" *ngIf="hasBrakes$ | async">{{ data.vehicle.braking }}</td>
                  </tr>
                </table>
              </div>
              <div>
                <img class="vehicle-image"
                  src="{{ data.vehicle.displayThumbnailUrl || data.vehicle.displayUrl || data.vehicle.imageThumbnailUrl || data.vehicle.imageUrl }} "
                  alt="{{ data.vehicle.name }} image"
                  onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';" />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Maneuverability</mat-panel-title>
              <mat-panel-description>Maneuverability specfications for this vehicle. </mat-panel-description>
            </mat-expansion-panel-header>
            <table style="width: 100%">
              <tr>
                <td style="width: 25%"
                  matTooltip="Approach Angle:  Maximum angle of a ramp onto which a vehicle can climb from a horizontal plane without front end interference.">
                  Approach Angle
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.angleOfApproach">{{ data.vehicle.angleOfApproach }}&deg;</td>
              </tr>
              <tr>
                <td style="width: 25%"
                  matTooltip="Departure Angle: Maximum angle of ramp from which a vehicle can exit without damage.">
                  Departure Angle
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.angleOfDeparture">
                  {{ data.vehicle.angleOfDeparture }}&deg;
                </td>
              </tr>
              <tr *ngIf="isWheeledVehicle">
                <td style="width: 25%"
                  matTooltip="Ramp Over Angle:  Angle between ties and teh center of the vehicle's underbody.">
                  Ramp Over Angle
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.rampBreakoverAngle">
                  {{ data.vehicle.rampBreakoverAngle }}&deg;
                </td>
              </tr>
              <tr>
                <td style="width: 25%"
                  matTooltip="Ground Clearance:  Minimum distance between the lower end of the vehicle body and a horizontal surface the vehicle is on.">
                  Ground Clearance
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.groundClearance">
                  {{ data.vehicle.groundClearance.value }} {{ data.vehicle.groundClearance.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%"
                  matTooltip="Horizontal Center of Gravity:  Horizontal distance measured to the rear of the centerline of the vehicle from the front axle.">
                  Center of Gravity - Horizontal
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.centerOfGravity.horizontal">
                  {{ data.vehicle.centerOfGravity.horizontal.value }}
                  {{ data.vehicle.centerOfGravity.horizontal.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%" matToolTip="Vertical Center of Gravity:  Height measured from the ground.">
                  Center of Gravity - Vertical
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.centerOfGravity.vertical">
                  {{ data.vehicle.centerOfGravity.vertical.value }} {{ data.vehicle.centerOfGravity.vertical.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Curb Weight</td>
                <td style="width: 75%" *ngIf="data.vehicle.curbWeight">
                  {{ data.vehicle.curbWeight.value }} {{ data.vehicle.curbWeight.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Gross Vehicle Weight</td>
                <td style="width: 75%">
                  {{ data.vehicle.grossVehicleWeight.value }} {{ data.vehicle.grossVehicleWeight.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Overall Dimensions</td>
                <td *ngIf="data.vehicle.overallDimensions">
                  <table id="overallDimensionsTable" style="
                      width: auto;
                      border: 1px solid white;
                      border-collapse: collapse;
                      padding: 10px;
                      border-spacing: 10px;
                      text-align: center;
                    ">
                    <th style="border: 1px solid white; border-collapse: collapse">Length</th>
                    <th style="border: 1px solid white; border-collapse: collapse">Width</th>
                    <th style="border: 1px solid white; border-collapse: collapse">Height</th>
                    <tr style="border: 1px solid white; border-collapse: collapse">
                      <td style="border: 1px solid white; border-collapse: collapse">
                        {{ data.vehicle.overallDimensions.vehicleLength.value }}
                        {{ data.vehicle.overallDimensions.vehicleLength.units }}
                      </td>
                      <td style="border: 1px solid white; border-collapse: collapse">
                        {{ data.vehicle.overallDimensions.vehicleWidth.value }}
                        {{ data.vehicle.overallDimensions.vehicleWidth.units }}
                      </td>
                      <td style="border: 1px solid white; border-collapse: collapse">
                        {{ data.vehicle.overallDimensions.vehicleHeight.value }}
                        {{ data.vehicle.overallDimensions.vehicleHeight.units }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr *ngIf="isWheeledVehicle">
                <td style="width: 25%" matTooltip="Maximum Steering Angle:  Maximum deflection of the wheels.">
                  Maximum Steering Angle
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.maximumSteeringAngle">
                  {{ data.vehicle.maximumSteeringAngle }}&deg;
                </td>
              </tr>
              <tr>
                <td style="width: 25%"
                  matTooltip="Minimum Turning Radius:  Radius of the path of the vehicle's centerline when in it's tightest turn possible.">
                  Minimum Turning Radius
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.minimumTurningRadius">
                  {{ data.vehicle.minimumTurningRadius.value }} {{ data.vehicle.minimumTurningRadius.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Steering Type</td>
                <td style="width: 75%">{{ steeringTypeName }}</td>
              </tr>
              <tr *ngIf="isWheeledVehicle">
                <td style="width: 25%">Tire Radius</td>
                <td style="width: 75%" *ngIf="data.vehicle.tireRadius">
                  {{ data.vehicle.tireRadius }} {{ data.vehicle.tireRadius.units }}
                </td>
              </tr>
              <tr *ngIf="isWheeledVehicle">
                <td style="width: 25%">Number of Wheels</td>
                <td style="width: 75%" *ngIf="data.vehicle.numberOfWheels">{{ data.vehicle.numberOfWheels }}</td>
              </tr>
              <tr>
                <td style="width: 25%"
                  matTooltip="Wheel Track:  Distance between the centerline of two wheels on the same axle.">
                  Wheel Track
                </td>
                <td style="width: 75%" *ngIf="data.vehicle.wheelTrack">
                  {{ data.vehicle.wheelTrack.value }} {{ data.vehicle.wheelTrack.units }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Number of Axles</td>
                <td style="width: 75%" *ngIf="data.vehicle.numberOfAxles">{{ data.vehicle.numberOfAxles }}</td>
              </tr>
              <tr>
                <td style="width: 25%" matTooltip="Wheelbase:  Axle to axle distance from front to back.">Wheelbase</td>
                <td *ngIf="data.vehicle.wheelbase">
                  <table id="wheelbaseTable" style="
                      width: auto;
                      border: 1px solid white;
                      border-collapse: collapse;
                      padding: 10px;
                      border-spacing: 10px;
                      text-align: center;
                    ">
                    <th style="border: 1px solid white; border-collapse: collapse">From Axle</th>
                    <th style="border: 1px solid white; border-collapse: collapse">To Axle</th>
                    <th style="border: 1px solid white; border-collapse: collapse">
                      Distance ({{ data.vehicle.wheelbase.units }})
                    </th>
                    <tr *ngFor="let measurement of data.vehicle.wheelbase.measurements"
                      style="border: 1px solid white; border-collapse: collapse">
                      <td style="border: 1px solid white; border-collapse: collapse">
                        {{ measurement.fromAxlePosition }}
                      </td>
                      <td style="border: 1px solid white; border-collapse: collapse">
                        {{ measurement.toAxlePosition }}
                      </td>
                      <td style="border: 1px solid white; border-collapse: collapse">{{ measurement.value }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="data.allowVehicleModels3d">
            <mat-expansion-panel-header>
              <mat-panel-title>3D MODELS ({{ getNumberOfItemsInArray(data.vehicleModels3d) }}) </mat-panel-title>
              <mat-panel-description>3D models of this vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <button mat-raised-button color="accent" class="right" (click)="createModel3d(data.vehicle, currentUser)">
              <mat-icon>library_add</mat-icon>
              <span>Add 3D Model</span>
            </button>

            <div *ngIf="data.vehicleModels3d">
              <app-model3d-table [models3d]="data.vehicleModels3d" [showPaginator]="false" [showFilter]="true"
                [currentUser]="currentUser" [parentVehicle]="data.vehicle">
              </app-model3d-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="data.allowVehicleScans">
            <mat-expansion-panel-header>
              <mat-panel-title>SCANS ({{ getNumberOfItemsInArray(data.vehicleScans) }})</mat-panel-title>
              <mat-panel-description>Point clouds of this vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <button mat-raised-button color="accent" class="right" (click)="createScan(data.vehicle, currentUser)">
              <mat-icon>library_add</mat-icon>
              <span>Add Scan</span>
            </button>

            <div *ngIf="data.vehicleScans">
              <app-scan-table [currentUser]="currentUser" [isProject]="false" [scans]="data.vehicleScans"
                [showPaginator]="false" [showFilter]="true" [parentVehicle]="data.vehicle">
              </app-scan-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>NOTES ({{ getNumberOfItemsInArray(getNonSnapshotNotes(data.vehicleNotes)) }})
              </mat-panel-title>
              <mat-panel-description>Non-snapshot notes by report section.</mat-panel-description>
            </mat-expansion-panel-header>

            <button mat-raised-button color="accent" class="right" (click)="addNote(data.vehicle)">
              <mat-icon>library_add</mat-icon>
              <span>Add Note</span>
            </button>

            <div *ngIf="getNonSnapshotNotes(data.vehicleNotes) as notes">
              <app-note-table [notes]="notes" [vehicle]="data.vehicle" [reportSections]="data.reportSections"
                [isSnapshotOrVideoNotes]="false">
              </app-note-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>SNAPSHOTS ({{ getNumberOfItemsInArray(getSnapshotImages(data.vehicleImages))
                }})</mat-panel-title>
              <mat-panel-description>Snapshots with notes by report section.</mat-panel-description>
            </mat-expansion-panel-header>

            <div class="grid" *ngIf="getSnapshotImages(data.vehicleImages) as snapshotImages">
              <div *ngFor="let img of snapshotImages">
                <app-snapshot-card [img]="img" [notes]="getSnapshotNotes(data.vehicleNotes, img._id)"
                  [vehicle]="data.vehicle" [reportSections]="data.reportSections"></app-snapshot-card>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>IMAGES ({{ getNumberOfItemsInArray(getNonSnapshotImages(data.vehicleImages))
                }})</mat-panel-title>
              <mat-panel-description>Non-snapshot images added to this vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <span class="right">
              <button mat-raised-button color="accent" class="right" (click)="imageFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Image</span>
                <input #imageFileInput type="file" (change)="imageFileInputChange($event)" accept="image/*"
                  style="display: none" multiple />
              </button>
            </span>

            <div class="grid" *ngIf="getNonSnapshotImages(data.vehicleImages) as vehicleImages">
              <div *ngFor="let img of vehicleImages">
                <app-image-doc-card [img]="img" [vehicle]="data.vehicle"></app-image-doc-card>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>VIDEOS ({{ getNumberOfItemsInArray(data.vehicleVideos) }})</mat-panel-title>
              <mat-panel-description>Videos added to the vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <span class="right">
              <button mat-raised-button color="accent" (click)="videoFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Video</span>
                <input #videoFileInput type="file" (change)="videoFileInputChange($event)" accept=".mp4"
                  style="display: none" multiple />
              </button>
            </span>

            <div class="grid" *ngIf="data.vehicleVideos">
              <div *ngFor="let video of data.vehicleVideos">
                <app-video-card [video]="video" [notes]="getVideoNotes(data.vehicleNotes, video._id)"
                  [vehicle]="data.vehicle">
                </app-video-card>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>DOCUMENTS ({{ getNumberOfItemsInArray(data.vehicleDocuments) }}) </mat-panel-title>
              <mat-panel-description>Documents added to this vehicle.</mat-panel-description>
            </mat-expansion-panel-header>

            <span class="right">
              <button mat-raised-button color="accent" (click)="documentFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Document</span>
                <input #documentFileInput type="file" (change)="documentFileInputChange($event)"
                  accept="image/*,video/*,audio/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  style="display: none" multiple />
              </button>

              <input type="text" formControlName="imageFileName" readonly *ngIf="hasImageFileName"
                class="fileNameConfirmationInput" />
            </span>

            <br><br>

            <div class="grid" *ngIf="data.vehicleDocuments">
              <div *ngFor="let doc of data.vehicleDocuments">
                <app-document-card [doc]="doc" [vehicle]="data.vehicle"></app-document-card>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</div>