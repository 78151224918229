{
  "name": "sdat",
  "version": "4.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local",
    "start:dev": "ng serve --configuration=dev",
    "start:prod": "ng serve --configuration=production",
    "build:dev": "ng build --configuration=dev",
    "build:prod": "ng build --configuration=production",
    "test": "ng test",
    "lint": "ng lint",
    "lint-staged": "lint-staged",
    "e2e": "ng e2e",
    "analyze": "ng build --configuration=dev --stats-json && webpack-bundle-analyzer ./dist/stats.json",
    "prepare": "husky install"
  },
  "private": true,
  "lint-staged": {
    "*.ts": [
      "prettier --write",
      "eslint"
    ]
  },
  "dependencies": {
    "@angular/animations": "^19.0.6",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/material": "^19.0.5",
    "@angular/material-moment-adapter": "^19.0.5",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "@aws-sdk/client-s3": "^3.563.0",
    "@ng-idle/core": "^16.0.0",
    "@ng-idle/keepalive": "^16.0.0",
    "@ngneat/until-destroy": "^9.2.1",
    "@uppy/transloadit": "^2.3.2",
    "@videogular/ngx-videogular": "^5.0.1",
    "angular-loggly-logger": "^0.3.2",
    "axios": "^1.7.9",
    "bson-objectid": "^2.0.4",
    "detect-browser": "^5.3.0",
    "fs-web": "^1.0.1",
    "hammerjs": "^2.0.8",
    "html-to-image": "^1.9.0",
    "is-ip": "^5.0.1",
    "jquery": "^3.6.0",
    "lodash-es": "^4.17.21",
    "moment": "^2.29.4",
    "ngx-mask": "^14.0.3",
    "postmark": "^4.0.4",
    "rxjs": "~7.5.0",
    "save": "^2.5.0",
    "sweetalert2": "^11.4.20",
    "util": "^0.12.4",
    "ws": "^8.8.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular-eslint/builder": "^15.2.1",
    "@angular-eslint/eslint-plugin": "^15.2.1",
    "@angular-eslint/eslint-plugin-template": "^15.2.1",
    "@angular-eslint/schematics": "^19.0.0",
    "@angular-eslint/template-parser": "^15.2.1",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@types/core-js": "^2.5.5",
    "@types/jasmine": "~4.0.0",
    "@types/jquery": "^3.5.14",
    "@types/node": "^18.0.3",
    "@typescript-eslint/eslint-plugin": "5.46",
    "@typescript-eslint/parser": "5.46",
    "codelyzer": "^6.0.2",
    "eslint": "8.29",
    "eslint-config-prettier": "^8.5.0",
    "eslint-plugin-jsdoc": "^39.3.6",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-simple-import-sort": "^8.0.0",
    "husky": "^8.0.1",
    "jasmine-core": "~4.1.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "lint-staged": "^13.0.3",
    "prettier": "^2.7.1",
    "ts-node": "^10.9.1",
    "tslib": "^2.7.0",
    "typescript": "~5.6.3",
    "webpack-bundle-analyzer": "^4.6.1"
  }
}
