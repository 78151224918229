<div class="parentContainer" *ngIf="currentUser$ | async as currentUser">
  <button class="image" id="btnProject" (click)="goToProjects()">
    <span class="btnProjects"></span>
  </button>
  <button class="image" id="btnShip" (click)="goToShips()">
    <span class="btnShips"></span>
  </button>
  <button class="image" id="btnVehicle" (click)="goToVehicles()">
    <span class="btnVehicles"></span>
  </button>
  <button class="image" *ngIf="isAdmin" id="btnSystemAccess" (click)="goToSystemAccess()">
    <span class="btnSystemAccess"></span>
  </button>
</div>
