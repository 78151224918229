import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, ProjectOverview, UnrealServer, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  ProjectService,
  ScanService,
  SettingsService,
  UnrealServerService,
  UserService,
} from '@shared/services';
import { ModStatesEnum, UnrealScenesEnum, UnrealUIStatesEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['./project-table.component.css'],
    standalone: false
})
export class ProjectTableComponent implements OnInit {
  @Input()
  projects: ProjectOverview[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  displayedProjectColumns: string[] = ['name', 'updatedAt', 'actions'];
  private projectErrorSubject = new BehaviorSubject<string>(null);
  projectError$: Observable<string> = this.projectErrorSubject.asObservable();
  dataSource;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.projects);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get showViewerLite(): boolean {
    return environment.unreal.showViewerLite === true;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  get unrealViewerNameLite(): string {
    return environment.unreal.viewerNameLite;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  canDelete(project: Project): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === project.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  deleteProject(project: Project): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    this.projectService
      .getProjectById(project._id, currentUser)
      .then((p: Project) => {
        Swal.fire({
          title: `Delete Project:  ${project.name}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.projectService
              .deleteProject(project._id, currentUser)
              .then((projects: Project[]) => {
                this.router.navigate(['/projects']);
              })
              .catch((error) => {
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    `Error Deleting Project`,
                    `There was an error deleting the project.  Please email ${environment.techSupportEmail}.`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((error) => {
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(`Error`, `Error getting project to delete.  Please email ${environment.techSupportEmail}.`, 'error');
        }
      });
  }

  async openProjectViewer(project: ProjectOverview, openViewerLite: boolean) {
    this.settingsService.setIsLoading(true);
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.projectService
        .getProjectById(project._id, user)
        .then(async (p: Project) => {
          let shipMod;
          const vehicleMod = this.modService.getCurrentVehicleMod();
          let navigationUrl = `/projects/${project._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}`;

          if (openViewerLite) {
            shipMod = this.scanService.getCurrentShipPanoramicScan();
            navigationUrl += `&panoOnly=true`;
          } else {
            shipMod = this.modService.getCurrentShipMod();
            navigationUrl += `&panoOnly=false`;
          }

          if (shipMod) {
            navigationUrl += `&pcName=${shipMod.name}`;
          }

          if (vehicleMod) {
            navigationUrl += `&vehicleName=${vehicleMod.name}`;
          }

          this.router
            .navigateByUrl(navigationUrl)
            .then(() => {
              this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
            })
            .catch((unrealError) => {
              this.settingsService.setIsLoading(false);
              const errMessage = this.errorService.handleError(
                `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
              );
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error Opening ${environment.unreal.viewerName}`,
                  `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            })
            .finally(() => {
              this.settingsService.setIsLoading(false);
              this.settingsService.setLoadingId(null);
            });
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Error getting project information needed for the viewer.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error Opening ${environment.unreal.viewerName}`,
          `Project and user are required.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }

  viewProject(project: Project): void {
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.projectService
        .getProjectById(project._id, user)
        .then((p: Project) => {
          const navigationUrl = `/projects/${project._id}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Error getting project information.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Project',
          `Unable to load project data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
