<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <div class="content">
    <div
      *ngIf="{
        allowShipModels3d: allowShipModels3d$ | async,
        allowShipScans: allowShipScans$ | async,
        reportSections: reportSections$ | async,
        ship: ship$ | async,
        shipClass: shipClass$ | async,
        shipDocuments: shipDocuments$ | async,
        shipImages: shipImages$ | async,
        shipModels3d: shipModels3d$ | async,
        shipNotes: shipNotes$ | async, 
        shipPanoramicScans: shipPanoramicScans$ | async,
        shipPointCloudScans: shipPointCloudScans$ | async,
        shipScans: shipScans$ | async,
        shipVideos: shipVideos$ | async
      } as data"
    >
      <div *ngIf="data.ship">
        <h1 style="display: inline-block">
          {{ data.ship.name }} | {{ data.shipClass?.class }} | {{ data.ship.designationWithHullNumber }}
        </h1>
        <div class="toolbar-action-buttons">
          <button mat-stroked-button aria-label="Expand All" matToolTip="Expand All" (click)="accordion.openAll()">
            Expand All
          </button>
          <button mat-stroked-button aria-label="Close All" matTooltip="Close All" (click)="accordion.closeAll()">
            Collapse All
          </button>
          <button
            mat-stroked-button
            aria-label="Edit Ship"
            matTooltip="Edit Ship"
            (click)="editShip(data.ship, currentUser)"
          >
            <img src="assets/img/Edit.png" alt="Edit" />
            <span>&nbsp;&nbsp;Edit Ship</span>
          </button>
          <div>
            <mat-checkbox
              class="example-margin"
              aria-label="Get Activity Notifications?"
              matTooltip="Get Activity Notifications?"
              [checked]="getIsGettingNotifications(data.ship)"
              (change)="updateNotifications($event.checked, data.ship)"
            >
              <span>&nbsp;&nbsp;Get Notifications?</span>
            </mat-checkbox>
          </div>
        </div>

        <div>
          <p *ngIf="errorMsg">{{ errorText }}</p>
        </div>

        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>SHIP INFORMATION</mat-panel-title>
              <mat-panel-description>Overview of ship.</mat-panel-description>
            </mat-expansion-panel-header>

            <div class="ship-grid-container">
              <div>
                <table style="width: 100%">
                  <tr>
                    <td style="width: 25%">Name</td>
                    <td style="width: 75%">{{ data.ship.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Class</td>
                    <td style="width: 75%">{{ data.shipClass?.class }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Designation with Hull Number</td>
                    <td style="width: 75%">{{ data.ship.designationWithHullNumber }}</td>
                  </tr>
                </table>
              </div>
              <div>
                <img
                  class="ship-image"
                  src="{{ data.ship.displayThumbnailUrl || data.ship.displayUrl || data.ship.imageThumbnailUrl || data.ship.imageUrl  }} "
                  alt="{{ data.ship.name }} image"
                  onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';"
                />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true" *ngIf="data.allowShipScans">
            <mat-expansion-panel-header>
              <mat-panel-title>SCANS ({{ getNumberOfItemsInArray(data.shipScans) }})</mat-panel-title>
              <mat-panel-description>Point clouds of this ship.</mat-panel-description>
            </mat-expansion-panel-header>

            <button
              mat-raised-button
              color="accent"
              class="right"
              aria-label="Add Scan"
              matTooltip="Add Scan"
              (click)="createScan(data.ship, currentUser)"
            >
              <mat-icon>library_add</mat-icon>
              <span>Add Scan</span>
            </button>

            <div *ngIf="data.shipScans">
              <app-scan-table
                [currentUser]="currentUser"
                [isProject]="false"
                [panoramicScans]="data.shipPanoramicScans"
                [pointCloudScans]="data.shipPointCloudScans"
                [scans]="data.shipScans"
                [showPaginator]="false"
                [showFilter]="true"
                [parentShip]="data.ship"
              ></app-scan-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true" *ngIf="data.allowShipModels3d">
            <mat-expansion-panel-header>
              <mat-panel-title>3D MODELS ({{ getNumberOfItemsInArray(data.shipModels3d) }}) </mat-panel-title>
              <mat-panel-description>3D models of this ship.</mat-panel-description>
            </mat-expansion-panel-header>

            <button
              mat-raised-button
              color="accent"
              aria-label="Add 3D Model"
              matTooltip="Add 3D Model"
              class="right"
              (click)="createModel3d(data.ship, currentUser)"
            >
              <mat-icon>library_add</mat-icon>
              <span>Add 3D Model</span>
            </button>

            <div *ngIf="data.shipModels3d">
              <app-model3d-table
                [showPaginator]="false"
                [showFilter]="true"
                [currentUser]="currentUser"
                [parentShip]="data.ship"
              ></app-model3d-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                >NOTES ({{ getNumberOfItemsInArray(getNonSnapshotNotes(data.shipNotes)) }})
              </mat-panel-title>
              <mat-panel-description>Non-snapshot notes by report section.</mat-panel-description>
            </mat-expansion-panel-header>

            <button
              mat-raised-button
              color="accent"
              aria-label="Add Note"
              matTooltip="Add Note"
              class="right"
              (click)="addNote(data.ship)"
            >
              <mat-icon>library_add</mat-icon>
              <span>Add Note</span>
            </button>

            <div *ngIf="getNonSnapshotNotes(data.shipNotes) as notes">
              <app-note-table
                [notes]="notes"
                [ship]="data.ship"
                [reportSections]="data.reportSections"
                [isSnapshotOrVideoNotes]="false"
              ></app-note-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                >SNAPSHOTS ({{ getNumberOfItemsInArray(getSnapshotImages(data.shipImages)) }})
              </mat-panel-title>
              <mat-panel-description>Snapshots with notes by report section.</mat-panel-description>
            </mat-expansion-panel-header>

            <div class="grid" *ngIf="getSnapshotImages(data.shipImages) as snapshotImages">
              <div *ngFor="let img of snapshotImages">
                <app-snapshot-card
                [img]="img"
                [notes]="getSnapshotNotes(data.shipNotes, img._id)"
                [ship]="data.ship"
                [reportSections]="data.reportSections"
              ></app-snapshot-card>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                >IMAGES ({{ getNumberOfItemsInArray(getNonSnapshotImages(data.shipImages)) }})
              </mat-panel-title>
              <mat-panel-description>Non-snapshot images added to the ship.</mat-panel-description>
            </mat-expansion-panel-header>

            <span class="right">
              <button mat-raised-button color="accent" class="right" (click)="imageFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Image</span>
                <input
                  #imageFileInput
                  type="file"
                  (change)="imageFileInputChange($event)"
                  accept="image/*"
                  style="display: none" 
                  multiple 
                /></button
              >

              <input
                type="text"
                formControlName="imageFileName"
                readonly
                *ngIf="hasImageFileName"
                class="fileNameConfirmationInput"
                fileNameConfirmationInput
              />
            </span>

            <div  class="grid" *ngIf="getNonSnapshotImages(data.shipImages) as shipImages">
              <div *ngFor="let img of shipImages">
                <app-image-doc-card [img]="img" [ship]="data.ship"></app-image-doc-card>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>VIDEOS ({{ getNumberOfItemsInArray(data.shipVideos) }})</mat-panel-title>
              <mat-panel-description>Videos added to the ship.</mat-panel-description>
            </mat-expansion-panel-header>
  
            <span class="right">
              <button mat-raised-button color="accent" (click)="videoFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Video</span>
                <input
                  #videoFileInput
                  type="file"
                  (change)="videoFileInputChange($event)"
                  accept=".mp4"
                  style="display: none" 
                  multiple 
                />
              </button>
            </span>
  
            <div class="grid" *ngIf="data.shipVideos">
              <div *ngFor="let video of data.shipVideos">
                <app-video-card
                [video]="video"
                [notes]="getVideoNotes(data.shipNotes, video._id)"
                [ship]="data.ship"
              >
              </app-video-card>
              </div>
            </div>
          </mat-expansion-panel>

          
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>DOCUMENTS ({{ getNumberOfItemsInArray(data.shipDocuments) }}) </mat-panel-title>
              <mat-panel-description>Documents added to this ship.</mat-panel-description>
            </mat-expansion-panel-header>

            <span class="right">
              <button mat-raised-button color="accent" (click)="documentFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Add Document</span>
                <input
                  #documentFileInput
                  type="file"
                  (change)="documentFileInputChange($event)"
                  accept="image/*,video/*,audio/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  style="display: none" 
                  multiple 
                />
              </button>

              <br><br>

              <input
                type="text"
                formControlName="documentFileName"
                readonly
                *ngIf="hasDocumentFileName"
                class="fileNameConfirmationInput"
              />
            </span>

            <div class="grid" *ngIf="data.shipDocuments">
                <div *ngFor="let doc of data.shipDocuments">
                  <app-document-card [doc]="doc" [ship]="data.ship"></app-document-card>
                </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
