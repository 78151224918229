<div *ngIf="currentUser$ | async as currentUser">
  <mat-toolbar color="primary" class="mat-elevation-z8">
    <button mat-icon-button aria-label="Menu" matTooltip="Menu" (click)="sidenav.toggle()" *ngIf="isMobile" id="toggleSidenav">
      <mat-icon>{{ toolbarIconName$ | async }}</mat-icon>
    </button>
    
    <span id="title">SDAT</span> <!-- Centered title -->
  
    <!-- Spacer for larger screens, pushes content to the right -->
    <span *ngIf="!isMobile" class="toolbar-spacer" fxFlex="auto"></span>
  
    <span id="helloContainer">
      <a mat-flat-button id="helloMessage" (click)="editUser()">Hello {{ currentUser.firstName }} ({{ currentUser.role }})!</a>
    </span>
  
    <button mat-icon-button id="helpButton" aria-label="Help" matTooltip="Help" (click)="getHelp()">
      <mat-icon>help</mat-icon>
    </button>
  
    <button mat-icon-button aria-label="Logout" id="logoutButton" matTooltip="Logout" (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-toolbar>
  

  <nav mat-tab-nav-bar *ngIf="showTabs" class="custom-nav-bar">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      >{{ link.label }}
    </a>
  </nav>
</div>

<mat-sidenav-container [ngStyle]="{ 'height': (currentUser$ | async) === null || (currentUser$ | async) === undefined ? '98vh' : 'calc(97vh - 56px)' }">
  <mat-sidenav
    #sidenav="matSidenav"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="!isMobile && opened"
    (openedChange)="onSidenavOpenChange()"
    class="mat-elevation-z8"
    *ngIf="currentUser$ | async as currentUser"
    [ngStyle]="{ 'height': (currentUser$ | async) === null || (currentUser$ | async) === undefined ? '98vh' : 'calc(97vh - 56px)' }"
  >
    <mat-nav-list *ngIf="currentUser$ | async as currentUser">
      <img class="avatar mat-elevation-z8" src="{{ currentUser.displayThumbnailUrl || currentUser.displayUrl }}" alt="User Image" />

      <h4 class="name">{{ currentUser.fullName }}</h4>
      <p class="designation" *ngIf="currentUser.title">{{ currentUser.title }}</p>

      <mat-divider></mat-divider>

      <a mat-list-item (click)="goToHome()" aria-label="Home">
        <span>Home</span>
      </a>

      <a mat-list-item (click)="goToProjects()" aria-label="Projects">
        <span>Projects</span>
      </a>

      <a mat-list-item (click)="goToShips()" aria-label="Ships">
        <span>Ships</span>
      </a>

      <a mat-list-item (click)="goToVehicles()" aria-label="Vehicles">
        <span>Vehicles</span>
      </a>

      <a *ngIf="isAdmin" mat-list-item (click)="goToSystemAccess()" aria-label="System Access">
        <span>System Access</span>
      </a>

      <mat-divider></mat-divider>

      <a mat-list-item (click)="logout()" aria-label="Logout">
        <mat-icon>power_settings_new</mat-icon>
        <span>Logout</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [class.sidenav-open]="(currentUser$ | async) !== null && (currentUser$ | async) !== undefined" class="mat-elevation-z8">
    <app-progress-spinner></app-progress-spinner>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #genericDialog>
  <h2 matDilaogTitle #dialogTitle></h2>
  <p matDialogContent #dialogContent></p>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose #dialogCloseBtn></button>
  </mat-dialog-actions>
</ng-template>
