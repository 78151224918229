import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, Report, ReportOverview, User } from '@shared/models';
import {
  DocumentService,
  ErrorService,
  ImageDocService,
  LogService,
  ModService,
  Model3dService,
  NoteService,
  ProjectService,
  ReportService,
  ScanService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-report-overview-table',
    templateUrl: './report-overview-table.component.html',
    styleUrls: ['./report-overview-table.component.css'],
    standalone: false
})
export class ReportOverviewTableComponent implements OnInit {
  @Input()
  reportOverviews: ReportOverview[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  displayedReportOverviewColumns: string[] = [
    'name',
    'finalizedDate',
    'projectName',
    'lastUpdatedBy',
    'lastUpdatedAt',
    'actions',
  ];
  isOpeningReportViewer: boolean = false;
  private reportErrorSubject = new BehaviorSubject<string>(null);
  reportError$: Observable<string> = this.reportErrorSubject.asObservable();
  dataSource;

  constructor(
    private documentService: DocumentService,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.reportOverviews);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    if (this.isOpeningReportViewer) {
      //console.log(`keep existing project, opening viewer or report`);
    } else {
      this.projectService.getProjectById(null, null);
    }
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async viewReport(report: ReportOverview): Promise<any> {
    if (report.finalizedDate && (report.finalizedDisplayUrl || report.finalizedUrl)) {
      window.open(report.finalizedDisplayUrl || report.finalizedUrl, '_blank');
    } else {
      this.settingsService.setIsLoading(true);
      this.isOpeningReportViewer = true;

      const navigationUrl = `/projects/${report.projectId}/report/${report._id}`;
      const currentUser = this.userService.getCurrentUser();

      this.projectService
        .getProjectById(report.projectId, currentUser)
        .then((project: Project) => {
          zip(
            this.currentUser$,
            this.projectService.currentProject$,
            this.documentService.currentProjectDocuments$,
            this.imageDocService.currentProjectImages$,
            this.noteService.currentProjectNotes$,
            this.projectService.currentProjectReports$,
            this.reportService.reportSections$,
            this.shipService.currentShip$,
            this.modService.currentShipMod$,
            this.model3dService.currentShipModel3d$,
            this.scanService.currentShipScan$,
            this.vehicleService.currentVehicle$,
            this.modService.currentVehicleMod$,
            this.model3dService.currentVehicleModel3d$,
            this.scanService.currentVehicleScan$
          )
            .pipe(
              map(
                ([
                  currentUser,
                  currentProject,
                  currentProjectDocuments,
                  currentProjectImages,
                  currentProjectNotes,
                  currentProjectReports,
                  reportSections,
                  selectedShip,
                  selectedShipMod,
                  selectedShipModModel3d,
                  selectedShipModScan,
                  selectedVehicle,
                  selectedVehicleMod,
                  selectedVehicleModModel3d,
                  selectedVehicleModScan,
                ]) => ({
                  currentUser,
                  currentProject,
                  currentProjectDocuments,
                  currentProjectImages,
                  currentProjectNotes,
                  currentProjectReports,
                  reportSections,
                  selectedShip,
                  selectedShipMod,
                  selectedShipModModel3d,
                  selectedShipModScan,
                  selectedVehicle,
                  selectedVehicleMod,
                  selectedVehicleModModel3d,
                  selectedVehicleModScan,
                })
              )
            )
            .subscribe(
              ({
                currentUser,
                currentProject,
                currentProjectImages,
                currentProjectNotes,
                currentProjectReports,
                reportSections,
                selectedShip,
                selectedShipMod,
                selectedShipModModel3d,
                selectedShipModScan,
                selectedVehicle,
                selectedVehicleMod,
                selectedVehicleModModel3d,
                selectedVehicleModScan,
              }) => {
                this.reportService
                  .getReportById(
                    report._id,
                    project,
                    selectedShip,
                    selectedShipMod,
                    selectedShipModModel3d,
                    selectedShipModScan,
                    selectedVehicle,
                    selectedVehicleMod,
                    selectedVehicleModModel3d,
                    selectedVehicleModScan,
                    currentUser
                  )
                  .then((reportToOpen: Report) => {
                    this.settingsService.setIsLoading(false);
                    this.router.navigateByUrl(navigationUrl);
                  })
                  .catch((reportError) => {
                    this.settingsService.setIsLoading(false);
                    if (this.settingsService.getShowPopupErrorMessages()) {
                      Swal.fire(
                        `Error Displaying Report`,
                        `There was an error displaying the report.  Please email ${environment.techSupportEmail}.`,
                        'error'
                      );
                    }
                  });
              }
            );
        })
        .catch((error) => {
          const errMessage = this.errorService.handleError(
            `Error getting projectId ${report.projectId} for reportId ${report._id}: ${error.message}`
          );
          this.reportErrorSubject.next(errMessage);
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Displaying Report`,
              `There was an error displaying the report.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    }
  }
}
