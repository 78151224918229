<div id="container" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <div
      *ngIf="{
        isDebugging: isDebugging$ | async
      } as data"
    >
      <form [formGroup]="form">
        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="userInfoForm">
            <div [formGroup]="userInfoForm">
              <ng-template matStepLabel *ngIf="!isMobile">USER INFO</ng-template>

              <h1 *ngIf="isMobile" style="justify-content: center;">USER INFO</h1>

              <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput type="text" placeholder="First Name" formControlName="firstName" />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" placeholder="Last Name" formControlName="lastName" />
              </mat-form-field>

              <div [formGroup]="fileForm">
                <div>
                  <span>
                    <button (click)="imageFileInput.click()">
                      <mat-icon>library_add</mat-icon>
                      <span>Select Image File</span>
                      <input
                        #imageFileInput
                        type="file"
                        (change)="imageFileInputChange($event)"
                        accept="image/*"
                        style="display: none"
                      />
                    </button>
                  </span>

                  <br><br>

                  <!-- Image Preview -->
                  <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                    <img
                      [src]="imageURL"
                      alt="{{ imageFileName }}"
                      style="width: 250px; height: auto"
                      onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png' ;"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="data.isDebugging">
              <br>
              <div>firstName: {{ userInfoForm.get('firstName').value }} {{ userInfoForm.get('firstName').valid }}</div>
              <div>lastName: {{ userInfoForm.get('lastName').value }} {{ userInfoForm.get('lastName').valid }}</div>
              <div>editorId: {{ fileForm.get('editorId').value }} {{ fileForm.get('editorId').valid }}</div>
              <div>imageFileName: {{ imageFileName }} {{ fileForm.get('imageFileName').valid }}</div>
              <div>imageUrl: {{ fileForm.get('imageUrl').value }} {{ fileForm.get('imageUrl').valid }}</div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" [disabled]="userInfoForm.invalid" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="userOrganizationForm">
            <div [formGroup]="userOrganizationForm">
              <ng-template matStepLabel *ngIf="!isMobile">ORGANIZATION INFO</ng-template>

              <h1 *ngIf="isMobile" style="justify-content: center;">ORGANIZATION INFO</h1>

              <mat-form-field appearance="fill">
                <mat-label>Organization</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Enter an organization name"
                  formControlName="organizationName"
                />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Title / Position</mat-label>
                <input matInput type="text" placeholder="Enter a title / position" formControlName="title" />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Employee ID</mat-label>
                <input matInput type="text" placeholder="Enter an employee ID" formControlName="employeeId" />
              </mat-form-field>
            </div>

            <div *ngIf="data.isDebugging">
              <br>
              <div>
                organizationName: {{ userOrganizationForm.get('organizationName').value }}
                {{ userOrganizationForm.get('organizationName').valid }}
              </div>
              <div>
                title: {{ userOrganizationForm.get('title').value }} {{ userOrganizationForm.get('title').valid }}
              </div>
              <div>
                employeeId: {{ userOrganizationForm.get('employeeId').value }}
                {{ userOrganizationForm.get('employeeId').valid }}
              </div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" [disabled]="userOrganizationForm.invalid" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="userContactsForm">
            <div [formGroup]="userContactsForm">
              <ng-template matStepLabel *ngIf="!isMobile">CONTACT INFO</ng-template>

              <h1 *ngIf="isMobile" style="justify-content: center;">CONTACT INFO</h1>

              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Enter an email address" formControlName="email" />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Phone</mat-label>
                <input
                  matInput
                  mask="(000) 000-0000"
                  placeholder="Enter a phone number"
                  formControlName="phoneNumber"
                />
              </mat-form-field>
            </div>

            <div *ngIf="data.isDebugging">
              <br>
              <div>email: {{ userContactsForm.get('email').value }} {{ userContactsForm.get('email').valid }}</div>
              <div>
                phoneNumber: {{ userContactsForm.get('phoneNumber').value }}
                {{ userContactsForm.get('phoneNumber').valid }}
              </div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" [disabled]="userContactsForm.invalid" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="userSystemInfoForm">
            <div [formGroup]="userSystemInfoForm">
              <ng-template matStepLabel *ngIf="!isMobile">SYSTEM INFO</ng-template>

              <h1 *ngIf="isMobile" style="justify-content: center;">SYSTEM INFO</h1>

              <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" [attr.disabled]="true" />
              </mat-form-field>

              <div class="button-row" *ngIf="!isNewUser">
                <button
                  *ngIf="!isChangingPassword"
                  mat-stroked-button
                  color="warn"
                  aria-label="Change password"
                  matTooltip="Change password"
                  (click)="changePassword()"
                >
                  Change Password
                </button>
                <button
                  *ngIf="isChangingPassword"
                  mat-stroked-button
                  color="warn"
                  aria-label="Cancel password change"
                  matTooltip="Cancel password change"
                  (click)="cancelChangePassword()"
                >
                  Cancel Password Change
                </button>
              </div>

              <div *ngIf="isChangingPassword">
                <mat-form-field
                  appearance="fill"
                  aria-label="Enter password"
                  matTooltip="Enter password which contains at least 8 characters, including at least one upper and one lower case letter and one special character."
                >
                  <mat-label>New Password</mat-label>
                  <input matInput id="newPassword" type="password" formControlName="newPassword" />
                  <mat-hint align="end"
                    >Enter password which contains at least 8 characters, including at least one upper and one lower
                    case letter and one special character.</mat-hint
                  >
                  <mat-error>{{ newPasswordError }}</mat-error>
                </mat-form-field>

                <mat-form-field
                  appearance="fill"
                  aria-label="Confirm password"
                  matTooltip="Confirm password, it must match the new password"
                >
                  <mat-label>Confirm Password</mat-label>
                  <input matInput id="confirmPassword" type="password" formControlName="confirmPassword" />
                  <mat-error>{{ confirmPasswordError }}</mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="currentUser._id === selectedUser._id; then noRoleChange; else allowRoleChange"></div>

              <ng-template #allowRoleChange>
                <mat-form-field appearance="fill">
                  <mat-label>User Type</mat-label>
                  <mat-select formControlName="role" [attr.disabled]="currentUser._id === selectedUser._id">
                    <mat-option>Choose User Type</mat-option>
                    <mat-option *ngFor="let userRole of userRoles" [value]="userRole">{{ userRole }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>

              <ng-template #noRoleChange>
                <h3>{{ existingRole }}</h3>
              </ng-template>
            </div>

            <div *ngIf="data.isDebugging">
              <br>
              <div>
                password: {{ userSystemInfoForm.get('password').value }} {{ userSystemInfoForm.get('password').valid }}
              </div>
              <div>
                newPassword: {{ userSystemInfoForm.get('newPassword').value }}
                {{ userSystemInfoForm.get('newPassword').valid }}
              </div>
              <div>
                confirmPassword: {{ userSystemInfoForm.get('confirmPassword').value }}
                {{ userSystemInfoForm.get('confirmPassword').valid }}
              </div>
              <div>role: {{ userSystemInfoForm.get('role').value }} {{ userSystemInfoForm.get('role').valid }}</div>
              <div>form: {{ form.valid }}</div>
              <div>{{ invalidControls }}</div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" [disabled]="userSystemInfoForm.invalid" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel *ngIf="!isMobile">Done</ng-template>

            <h1 *ngIf="isMobile" style="justify-content: center;">Done</h1>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Save and close"
                matToolip="Save and close"
                #saveButton
                (click)="save(isNewUser, currentUser, selectedUser)"
                [disabled]="form.invalid"
              >
                {{ saveButtonText }}
              </button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </form>
    </div>
  </mat-dialog-content>
</div>
