import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';
import moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2';

import pdfMake from '../../../../assets/js/pdfmake.js';
import pdfFonts from '../../../../assets/js/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import {
  Mod,
  Model3d,
  Project,
  Report,
  ReportOverview,
  ReportSection,
  Scan,
  Ship,
  User,
  Vehicle,
  Video,
} from '../../models';
import {
  CollisionTypeSummary,
  EncroachmentViolation,
  FileObjectTypesEnum,
  DbCollectionsEnum,
  HardCollision,
  KeyframeCollisionStatusEnum,
  ReportSectionIdsEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  TrackedVehicleMovement,
  WheeledVehicleMovement,
} from '../../enums';
import { createHttpObservable, createTag, getCustomHeaders, getDisplayName, getYesOrNo } from '../../utils';

import { ErrorService } from '../error/error.service';
import { FileService } from '../file/file.service';
import { ImageDocService } from '../imageDoc/image-doc.service';
import { NoteService } from '../note/note.service';
import { LogService } from '../log/log.service';
import { SettingsService } from '../settings/settings.service';
import { UserService } from '../user/user.service';
import { VideoService } from '../video/video.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private currentReportSubject = new BehaviorSubject<Report>(null);
  private currentReportIFrameSrcSubject = new BehaviorSubject<string>(null);
  private currentProjectReportsSubject = new BehaviorSubject<Report[]>(null);
  private reportSectionsSubject = new BehaviorSubject<ReportSection[]>(null);
  private reportErrorSubject = new BehaviorSubject<string>(null);
  private selectedUserReportsSubject = new BehaviorSubject<ReportOverview[]>([]);
  private usersSubject = new BehaviorSubject<User[]>(null);
  // see https://github.com/bpampuch/pdfmake/blob/7b5675d5b9d5d7b815bd721e00504b16560a6382/src/standardPageSizes.js
  // pdfmake margin syntax: [left, top, right, bottom]
  private pageWidth = 612;
  private pageLength = 792;
  private pageLeftMargin = 40;
  private pageTopMargin = 60;
  private pageRightMargin = 40;
  private pageBottomMargin = 60;
  private pageSize;
  private imageWidth = this.pageWidth - 2 * this.pageLeftMargin - 2 * this.pageRightMargin;
  currentReport$: Observable<Report> = this.currentReportSubject.asObservable();
  currentReportIFrameSrc$: Observable<string> = this.currentReportIFrameSrcSubject.asObservable();
  currentProjectReports$: Observable<Report[]> = this.currentProjectReportsSubject.asObservable();
  reportError$: Observable<string> = this.reportErrorSubject.asObservable();
  reportSections$: Observable<ReportSection[]> = this.reportSectionsSubject.asObservable();
  selectedUserReports$: Observable<ReportOverview[]> = this.selectedUserReportsSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();

  //enums
  reportAlignOptions = {
    CENTER: 'center',
    LEFT: 'left',
    JUSTIFY: 'justify',
    RIGHT: 'right',
  };

  constructor(
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private noteService: NoteService,
    private settingsService: SettingsService,
    private userService: UserService,
    private videoService: VideoService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.users$ = this.userService.users$;
  }

  /*******  functions for pdfmake *********************** */
  async createNewReport(
    payload,
    project: Project,
    ship: Ship,
    shipModel3d: Model3d,
    shipScan: Scan,
    shipMod: Mod,
    vehicle: Vehicle,
    vehicleModel3d: Model3d,
    vehicleScan: Scan,
    vehicleMod: Mod,
    author: User
  ): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const reports = _this.currentProjectReportsSubject.getValue();
      const newReports = reports.slice(0);
      const url = `${environment.baseAPIUrl}report/create?userId=${author?._id}`;
      let returnValue: Report;

      _this.httpClient
        .post(url, payload, {
          headers: getCustomHeaders(true),
          responseType: 'json',
        })
        .subscribe({
          next: (newReport: Report) => {
            returnValue = newReport;
            _this.currentReportSubject.next(newReport);
            newReports.push(newReport);
            _this.currentProjectReportsSubject.next(newReports);
            resolve(returnValue);
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.reportErrorSubject.next(errMessage);
            reject(error);
          },
          complete: () => {
            _this.settingsService.setIsLoading(false);
          }
        });
    });
  }

  async deleteReport(reportId: string, projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && projectId && reportId) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Report[] = _this.currentProjectReportsSubject.getValue();

        const url = `${environment.baseAPIUrl}report/${reportId}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (deletedReport: Report) => {
              console.log(`successfully deleted reportId ${reportId}`);

              _this.refreshReportsByProject(projectId, currentUser)
                .then((reports: Report[]) => {
                  returnValue = reports;
                })
                .catch((refreshError) => {
                  _this.reportErrorSubject.next(refreshError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.reportErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `projectId, reportId and user are required to delete a report`;
        _this.errorService.handleError(errMessage);
        _this.reportErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async finalizeReport(
    report: Report,
    project: Project,
    ship: Ship,
    shipMod: Mod,
    shipModel3d: Model3d,
    shipScan: Scan,
    vehicle: Vehicle,
    vehicleMod: Mod,
    vehicleModel3d: Model3d,
    vehicleScan: Scan,
    currentUser: User
  ): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const reports = this.currentProjectReportsSubject.getValue();
      let returnValue: string;

      if (currentUser && reports && report) {
        _this.currentReportSubject.next(report);
        const promises = [];

        promises.push(_this.getReportImages(report, project, ship, vehicle, currentUser));
        promises.push(_this.noteService.getCurrentProjectNotes());
        promises.push(_this.noteService.getCurrentShipNotes());
        promises.push(_this.noteService.getCurrentVehicleNotes());

        Promise.allSettled(promises).then((results) => {
          const imageResults = results[0];
          const projectNoteResults = results[1];
          const shipNoteResults = results[2];
          const vehicleNoteResults = results[3];

          const reportData = {
            author: currentUser,
            project: project,
            projectImages: imageResults.status == 'fulfilled' ? imageResults.value.projectImages : [],
            projectNotes: projectNoteResults.status == 'fulfilled' ? projectNoteResults.value : [],
            report: report,
            runDate: new Date(),
            ship: ship,
            shipImages: imageResults.status == 'fulfilled' ? imageResults.value.shipImages : [],
            shipMod: shipMod,
            shipModel3d: shipModel3d,
            shipNotes: shipNoteResults.status == 'fulfilled' ? shipNoteResults.value : [],
            shipScan: shipScan,
            finalize: true,
            vehicle: vehicle,
            vehicleImages: imageResults.status == 'fulfilled' ? imageResults.value.vehicleImages : [],
            vehicleNotes: vehicleNoteResults.status == 'fulfilled' ? vehicleNoteResults.value : [],
            vehicleMod: vehicleMod,
            vehicleModel3d: vehicleModel3d,
            vehicleScan: vehicleScan,
          };

          _this
            .generateReportPdf(reportData, currentUser)
            .then((pdfUrl: string) => {
              returnValue = pdfUrl;
              _this.currentReportIFrameSrcSubject.next(pdfUrl);

              this.refreshReportsByProject(project._id, currentUser)
                .then((reports: Report[]) => {
                  _this.reportErrorSubject.next(null);
                })
                .catch((reportsError) => {
                  _this.reportErrorSubject.next(reportsError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(pdfUrl);
                });
            })
            .catch(function (pdfError) {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error generating pdf for reportId ${report._id}: ${pdfError.message}`
              );
              _this.reportErrorSubject.next(errMessage);
              _this.currentReportIFrameSrcSubject.next(null);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = this.errorService.handleError(`Unable to locate report to finalize`);
        _this.reportErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  // see https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript/41318684
  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  // see https://www.c-sharpcorner.com/article/client-side-pdf-generation-in-angular-with-pdfmake/
  async generateReportPdf(reportData: any, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      try {
        const reportDate = moment();
        const reportStartDate = moment(reportData.report.createdAt);
        const reportFinalizedDate =
          reportData.report && reportData.report.finalized && reportData.report.finalized.finalizedDate
            ? moment(reportData.report.finalized.finalizedDate)
            : reportData.finalize
              ? moment()
              : null;
        const isWheeledVehicle =
          reportData.vehicle && reportData.vehicle.movementTypeId === WheeledVehicleMovement._id ? true : false;
        const isTrackedVehicle =
          reportData.vehicle && reportData.vehicle.movementTypeId === TrackedVehicleMovement._id ? true : false;
        const equipmentTypeName = reportData.vehicle
          ? getDisplayName('equipmentType', reportData.vehicle.equipmentTypeId)
          : '';
        const movementTypeName = reportData.vehicle
          ? getDisplayName('movementType', reportData.vehicle.movementTypeId)
          : '';
        const steeringTypeName = reportData.vehicle
          ? getDisplayName('steeringType', reportData.vehicle.steeringTypeId)
          : '';
        const reportSections = _this.getReportSections();

        const images = {
          reportImage:
            reportData.projectImages.length > 0
              ? reportData.projectImages.find((image) => image.isMainImage === true)
              : '',
          projectImages:
            reportData.projectImages.length > 0
              ? reportData.projectImages.filter((image) => image.isSnapshot === false)
              : [],
          projectSnapshots:
            reportData.projectImages.length > 0
              ? reportData.projectImages.filter((image) => image.isSnapshot === true)
              : [],
          shipImage:
            reportData.shipImages.length > 0 ? reportData.shipImages.find((image) => image.isMainImage === true) : '',
          shipImages:
            reportData.shipImages.length > 0 ? reportData.shipImages.filter((image) => image.isSnapshot === false) : [],
          shipSnapshots:
            reportData.shipImages.length > 0 ? reportData.shipImages.filter((image) => image.isSnapshot === true) : '',
          vehicleImage:
            reportData.vehicleImages.length > 0
              ? reportData.vehicleImages.find((image) => image.isMainImage === true)
              : '',
          vehicleImages:
            reportData.vehicleImages.length > 0
              ? reportData.vehicleImages.filter((image) => image.isSnapshot === false)
              : [],
          vehicleSnapshots:
            reportData.vehicleImages.length > 0
              ? reportData.vehicleImages.filter((image) => image.isSnapshot === true)
              : '',
        };

        const info = {
          title: reportData.report.name,
          author: reportData.author.fullName,
          info: reportData.report.description || '',
        };

        let propagatedErrorExplanation = `Propagated Error is the maximum plus or minus distance error in the project.  `;
        propagatedErrorExplanation += `It accounts for the difference between the physical and the virtual.  It is calculated for each project `;
        propagatedErrorExplanation += `and is based on the ship point cloud registration error and decimation level, and the tessellation value input for the vehicle CAD model.`;

        // margin: [left, top, right, bottom]
        const styles = {
          bigger: {
            fontSize: 15,
            italics: true,
          },
          bold: {
            bold: true,
          },
          defaultStyle: {
            columnGap: 20,
          },
          footer: {
            alignment: _this.reportAlignOptions.CENTER,
          },
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 10, 0, 10],
          },
          normal: {
            fontSize: 12,
            alignment: _this.reportAlignOptions.JUSTIFY,
          },
          quote: {
            alignment: _this.reportAlignOptions.CENTER,
            italics: true,
            margin: [0, 10, 0, 10],
          },
          pageHeader: {
            alignment: _this.reportAlignOptions.JUSTIFY,
            margins: [0, 25, 0, 25],
          },
          small: {
            fontSize: 8,
          },
          subheader: {
            fontSize: 15,
            bold: true,
            margin: [0, 25, 0, 0],
          },
          subheaderLevel2: {
            fontSize: 12,
            bold: false,
            margin: [0, 10, 0, 0],
          },
          snapshotNoteHeader: {
            fontSize: 12,
            bold: false,
            decoration: 'underline',
            margin: [0, 10, 0, 10],
          },
          tableHeaderNew: {
            bold: true,
            fontSize: 13,
            color: 'black',
          },
          tableTitleColumn: {
            bold: true,
            fontSize: 12,
            alignment: 'left',
          },
          tableValueColumn: {
            bold: false,
            fontSize: 12,
            alignment: 'left',
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            margin: [0, 10, 0, 10],
          },
          tableStandard: {
            alignment: _this.reportAlignOptions.JUSTIFY,
            margin: [0, 5, 0, 15],
          },
        };

        const header = function (currentPage, pageCount, pageSize) {
          _this.pageSize = pageSize;
          const headerDD = [
            {
              style: 'pageHeader',
              columns: [
                {
                  alignment: _this.reportAlignOptions.LEFT,
                  text: `${reportDate.format('MM/DD/YYYY')}`,
                },
                {
                  alignment: _this.reportAlignOptions.CENTER,
                  text: `${reportData.author.organizationName}`,
                  style: 'bold',
                },
                {
                  alignment: _this.reportAlignOptions.RIGHT,
                  text: 'SDAT REPORT',
                },
              ],
              margin: [_this.pageLeftMargin, 30, _this.pageRightMargin, 0],
            },
            {
              canvas: [
                {
                  relativePosition: { x: 0, y: 0 },
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: pageSize.width,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
            },
          ];

          return headerDD;
        };

        const footer = function (currentPage, pageCount) {
          const footerDD = [
            {
              canvas: [
                {
                  relativePosition: { x: 0, y: 0 },
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: _this.pageSize.width,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
            },
            {
              style: 'footer',
              text: `Page ${currentPage.toString()} of ${pageCount}`,
              margin: [_this.pageLeftMargin, 0, _this.pageRightMargin, _this.pageBottomMargin],
            },
          ];

          return footerDD;
        };

        const generatePdfSections = {
          analysisSection: function () {
            let content = [];
            let haveChildContent = false;

            try {
              content.push(
                {
                  text: 'ANALYSIS',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                }
              );

              const imageSection = generatePdfSections.analyisImageSection(); //the main project image
              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.ANALYSIS,
                reportData.projectNotes,
                reportData.report.options.analysis.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.projectImages,
                reportData.projectNotes,
                ReportSectionIdsEnum.ANALYSIS,
                reportData.report.options.analysis.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.ANALYSIS,
                reportData.report.options.analysis.videos
              );

              if (imageSection.length > 0) {
                content.push(imageSection);
                haveChildContent = true;
              }

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
                haveChildContent = true;
              }

              if (notesSection.length > 0) {
                content.push(notesSection);
                haveChildContent = true;
              }

              if (videosSection.length > 0) {
                content.push(videosSection);
                haveChildContent = true;
              }

              if (!haveChildContent) {
                content.push({
                  text: 'Either no analysis data was selected or none was found in the project',
                  style: 'quote',
                });
              }
            } catch (ex) {
              const errMessage = `Error generating analysisSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          analyisImageSection: function () {
            let content = [];

            try {
              const analysisImagesContent = [];

              if (
                images.reportImage &&
                images.reportImage.base64DataUrl &&
                reportData.report.options.analysis.screenshots
              ) {
                analysisImagesContent.push(
                  {
                    text: 'PROJECT IMAGE',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  },
                  {
                    alignment: _this.reportAlignOptions.CENTER,
                    image: `${images.reportImage.base64DataUrl}`,
                    width: _this.imageWidth,
                  }
                );
              }

              if (analysisImagesContent.length > 0) {
                content.push(analysisImagesContent);
              }
            } catch (ex) {
              const errMessage = `Error generating analysisImageSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          collisionSection: function () {
            let content = [];
            let haveChildContent = false;

            try {
              content.push(
                {
                  text: 'COLLISION',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                }
              );

              if (
                reportData.report.options.collision.count &&
                reportData.project.latestPathTraveledDetails &&
                reportData.project.latestPathTraveledDetails.collisionSummary
              ) {
                content.push(
                  {
                    text: 'COLLISION SUMMARY',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );

                const collisionTable = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                if (
                  reportData.project.latestPathTraveledDetails.collisionSummary.collisionsByType &&
                  reportData.project.latestPathTraveledDetails.collisionSummary.collisionsByType.length > 0
                ) {
                  reportData.project.latestPathTraveledDetails.collisionSummary.collisionsByType.map(
                    (collisionTypeSummary: CollisionTypeSummary) => {
                      let tableTitleColumnText = '';

                      switch (collisionTypeSummary._id) {
                        case EncroachmentViolation._id:
                          tableTitleColumnText = `${EncroachmentViolation.totalDisplayName}: `;
                          break;
                        case HardCollision._id:
                          tableTitleColumnText = `${HardCollision.totalDisplayName}: `;
                          break;
                        default:
                          tableTitleColumnText = 'Unknown Collision Type: ';
                          break;
                      }

                      collisionTable.table.body.push([
                        {
                          text: tableTitleColumnText,
                          style: 'tableTitleColumn',
                        },
                        {
                          text: `${collisionTypeSummary.numberOfCollisions}`,
                          style: 'tableValueColumn',
                        },
                      ]);
                    }
                  );

                  collisionTable.table.body.push([
                    {
                      text: `Hard Collision Keyframe Identifiers`,
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.latestPathTraveledDetails.collisionSummary.hardCollisionKeyframeNames.join(
                        ', '
                      )}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                } else {
                  collisionTable.table.body.push([
                    {
                      text: 'No Collisions Detected',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: '',
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                content.push(collisionTable);
              }

              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.COLLISION,
                reportData.projectNotes,
                reportData.report.options.collision.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.projectSnapshots,
                reportData.projectNotes,
                ReportSectionIdsEnum.COLLISION,
                reportData.report.options.collision.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.COLLISION,
                reportData.report.options.collision.videos
              );

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
                haveChildContent = true;
              }

              if (notesSection.length > 0) {
                content.push(notesSection);
                haveChildContent = true;
              }

              if (videosSection.length > 0) {
                content.push(videosSection);
                haveChildContent = true;
              }

              if (!haveChildContent) {
                content.push({
                  text: 'Either no collision data was selected or none was found in the project',
                  style: 'quote',
                });
              }
            } catch (ex) {
              const errMessage = `Error generating collisionSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          minimumClearanceSection: function () {
            let content = [];
            let haveChildContent = false;

            try {
              content.push(
                {
                  text: 'MINIMUM CLEARANCE',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                }
              );

              if (
                reportData.report.options.minimumClearance.lateralLeft ||
                reportData.report.options.minimumClearance.lateralRight ||
                reportData.report.options.minimumClearance.longitudinalFront ||
                reportData.report.options.minimumClearance.longitudinalBack ||
                reportData.report.options.minimumClearance.verticalGround ||
                reportData.report.options.minimumClearance.verticalRoof
              ) {
                const minimumClearanceTableContent = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                if (reportData.report.options.minimumClearance.lateralLeft) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Lateral (Left):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.lateral.left.value} ${reportData.project.minimumClearance.lateral.left.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.report.options.minimumClearance.lateralRight) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Lateral (Right):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.lateral.right.value} ${reportData.project.minimumClearance.lateral.right.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.report.options.minimumClearance.longitudinalBack) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Longitudinal (Back):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.longitudinal.back.value} ${reportData.project.minimumClearance.longitudinal.back.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.report.options.minimumClearance.longitudinalFront) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Longitudinal (Front):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.longitudinal.front.value} ${reportData.project.minimumClearance.longitudinal.front.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.report.options.minimumClearance.verticalGround) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Vertical (Ground):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.vertical.ground.value} ${reportData.project.minimumClearance.vertical.ground.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.report.options.minimumClearance.verticalGround) {
                  haveChildContent = true;
                  minimumClearanceTableContent.table.body.push([
                    {
                      text: 'Vertical (Roof):  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.project.minimumClearance.vertical.roof.value} ${reportData.project.minimumClearance.vertical.roof.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                content.push(minimumClearanceTableContent);
              }

              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.MINIMUM_CLEARANCE,
                reportData.projectNotes,
                reportData.report.options.minimumClearance.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.projectSnapshots,
                reportData.projectNotes,
                ReportSectionIdsEnum.MINIMUM_CLEARANCE,
                reportData.report.options.minimumClearance.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.MINIMUM_CLEARANCE,
                reportData.report.options.minimumClearance.videos
              );

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
                haveChildContent = true;
              }

              if (notesSection.length > 0) {
                content.push(notesSection);
                haveChildContent = true;
              }

              if (videosSection.length > 0) {
                content.push(videosSection);
                haveChildContent = true;
              }

              if (!haveChildContent) {
                content.push({
                  text: 'Either no analysis data was selected or none was found in the project',
                  style: 'quote',
                });
              }
            } catch (ex) {
              const errMessage = `Error generating minimumClearanceSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          noteSection: function (reportSectionId, notes, reportDisplayOption) {
            let content = [];

            if (reportDisplayOption) {
              try {
                const reportSection = reportSections.find((reportSection) => reportSection._id === reportSectionId);
                const sectionNotes =
                  notes && Array.isArray(notes) && notes.length > 0
                    ? notes.filter((note) => note.reportSectionId === reportSectionId && !note.imageId && !note.videoId)
                    : [];

                if (sectionNotes.length > 0 && reportSection) {
                  const headerContent = [
                    {
                      text: `${reportSectionId == ReportSectionIdsEnum.SNAPSHOT ? 'SNAPSHOT' : reportSection.displayName
                        } NOTES`,
                      style: 'subheaderLevel2',
                    },
                    {
                      canvas: [
                        {
                          relativePosition: {
                            x: 0,
                            y: 0,
                          },
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 532,
                          y2: 0,
                          lineWidth: 1,
                        },
                      ],
                    },
                  ];

                  const dataContent = {
                    ul: [],
                  };

                  sectionNotes.map(function (note) {
                    dataContent.ul.push(note.noteText);
                  });

                  content.push(headerContent, dataContent);
                }
              } catch (ex) {
                const errMessage = `Error generating reportSectionId ${reportSectionId} notes for reportId ${reportData.report._id}: ${ex}`;
                _this.errorService.handleError(errMessage);
                content = [];
              }
            }

            return content;
          },
          pathTraveledSection: function () {
            let content = [];
            let haveChildContent = false;

            try {
              content.push(
                {
                  text: 'PATH TRAVELED',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                }
              );

              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.PATH_TRAVELED,
                reportData.projectNotes,
                reportData.report.options.pathTraveled.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.projectSnapshots,
                reportData.projectNotes,
                ReportSectionIdsEnum.PATH_TRAVELED,
                reportData.report.options.pathTraveled.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.PATH_TRAVELED,
                reportData.report.options.pathTraveled.videos
              );

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
                haveChildContent = true;
              }

              if (notesSection.length > 0) {
                content.push(notesSection);
                haveChildContent = true;
              }

              if (videosSection.length > 0) {
                content.push(videosSection);
                haveChildContent = true;
              }

              if (!haveChildContent) {
                content.push({
                  text: 'Either no path traveled data was selected or none was found in the project',
                  style: 'quote',
                });
              }
            } catch (ex) {
              const errMessage = `Error generating pathTraveledSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          projectInfoSection: function () {
            let columnHeader, columnData;

            if (reportFinalizedDate) {
              columnHeader = 'Name: \nStart Date: \nEnd Date: ';
              columnData = `${reportData.project.name}\n${reportStartDate.format(
                'MM/DD/YYYY'
              )}\n${reportFinalizedDate.format('MM/DD/YYYY')}`;
            } else {
              columnHeader = 'Name: \nStart Date: ';
              columnData = `${reportData.project.name}\n${reportStartDate.format('MM/DD/YYYY')}`;
            }

            const content = [
              {
                text: 'PROJECT INFORMATION',
                style: 'subheader',
              },
              {
                canvas: [
                  {
                    relativePosition: { x: 0, y: 0 },
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: _this.pageWidth - _this.pageLeftMargin - _this.pageLeftMargin,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
              },
              [
                {
                  alignment: _this.reportAlignOptions.JUSTIFY,
                  margin: [0, 5, 0, 0],
                  columns: [
                    {
                      width: '25%',
                      text: columnHeader,
                      style: 'bold',
                    },
                    {
                      text: columnData,
                      style: 'normal',
                    },
                  ],
                },
              ],
            ];

            return content;
          },
          projectSnapshotSection: function () {
            let content = [];
            let haveChildContent = false;

            try {
              content.push(
                {
                  text: 'SNAPSHOTS',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                }
              );

              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.SNAPSHOT,
                reportData.projectNotes,
                reportData.report.options.snapshot.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.projectSnapshots,
                reportData.projectNotes,
                ReportSectionIdsEnum.SNAPSHOT,
                reportData.report.options.snapshot.snapshots
              );

              if (notesSection.length > 0) {
                content.push(notesSection);
                haveChildContent = true;
              }

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
                haveChildContent = true;
              }

              if (!haveChildContent) {
                content.push({
                  text: 'Either no snapshot data was selected or none was found in the project',
                  style: 'quote',
                });
              }
            } catch (ex) {
              const errMessage = `Error generating projectSnapshotSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          propagatedErrorSection: function () {
            let content = [];

            try {
              if (reportData.report.options.project.propagatedError) {
                const errorContent = [
                  {
                    text: `PROPAGATED ERROR`,
                    style: 'subheader',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: { x: 0, y: 0 },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  },
                  {
                    text: propagatedErrorExplanation,
                    alignment: 'justify',
                    style: 'normal',
                  },
                  {
                    alignment: _this.reportAlignOptions.JUSTIFY,
                    margin: [0, 5, 0, 0],
                    columns: [
                      {
                        width: '25%',
                        text: `Decimation Level: \nRegistration Error: \nPropagated Error:`,
                        style: 'bold',
                      },
                      {
                        text: `${reportData.shipScan.decimationLevel.value}${reportData.shipScan.decimationLevel.units}\n${reportData.shipScan.registrationError.value}${reportData.shipScan.registrationError.units}\n${reportData.shipScan.cumulativeError.value}${reportData.shipScan.cumulativeError.units}`,
                        style: 'normal',
                      },
                    ],
                  },
                ];

                content.push(errorContent);
              }
            } catch (ex) {
              const errMessage = `Error generating propagatedErrorSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          shipSection: function () {
            let content = [];

            try {
              let shipUploader, shipCreationDate, shipInfo;

              if (reportData.shipModel3d) {
                shipUploader = _this.userService.getUserName(reportData.shipModel3d.creatorId);
                shipCreationDate = moment(reportData.shipModel3d.modelDate);
                shipInfo = `Uploader: ${shipUploader}\n`;

                if (reportData.report.options.ship.model3d.dataSource) {
                  shipInfo += `Data Source: ${reportData.shipModel3d.dataSourceName}`;
                }

                if (reportData.report.options.ship.model3d.modelDate) {
                  if (reportData.report.options.ship.model3d.dataSource) {
                    shipInfo += ` | Date Created: ${shipCreationDate.format('MM/DD/YYYY')}`;
                  } else {
                    shipInfo += `Date Created: ${shipCreationDate.format('MM/DD/YYYY')}`;
                  }
                }

                if (reportData.report.options.ship.model3d.fidelity) {
                  shipInfo += `\nFidelity: ${reportData.shipModel3d.fidelityType}`;
                }

                if (reportData.report.options.ship.model3d.fileType) {
                  shipInfo += `\nFile Type: ${reportData.shipModel3d.fileType}`;
                }
              } else if (reportData.shipScan) {
                shipUploader = _this.userService.getUserName(reportData.shipScan.creatorId);
                shipCreationDate = moment(reportData.shipScan.scanDate);
                shipInfo = `Uploader: ${shipUploader}\n`;

                if (reportData.report.options.ship.scan.dataSource) {
                  shipInfo += `Data Source: ${reportData.shipScan.dataSourceName}`;
                }

                if (reportData.report.options.ship.scan.scanDate) {
                  if (reportData.report.options.ship.scan.dataSource) {
                    shipInfo += ` | Date Created: ${shipCreationDate.format('MM/DD/YYYY')}`;
                  } else {
                    shipInfo += `Date Created: ${shipCreationDate.format('MM/DD/YYYY')}`;
                  }
                }

                if (reportData.report.options.ship.scan.scanType) {
                  shipInfo += `\nScan Type: ${reportData.shipScan.scanType}`;
                }

                if (reportData.report.options.ship.scan.fileType) {
                  shipInfo += `\nFile Type: ${reportData.shipScan.fileType}`;
                }
              }

              const shipHeader = [
                {
                  text: 'SHIP',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                },
                {
                  alignment: _this.reportAlignOptions.JUSTIFY,
                  margin: [0, 5, 0, 0],
                  columns: [
                    {
                      width: '25%',
                      text: `Name: \nDesignation: \n`,
                      style: 'bold',
                    },
                    {
                      text: `${reportData.ship.name}\n${reportData.ship.designationWithHullNumber}`,
                      style: 'normal',
                    },
                  ],
                },
              ];

              const shipContent = [];

              if (reportData.shipScan) {
                shipContent.push({
                  text: 'SCAN',
                  style: 'subheaderLevel2',
                });
              } else if (reportData.shipModel3d) {
                shipContent.push({
                  text: '3D Model',
                  style: 'subheaderLevel2',
                });
              }

              shipContent.push({
                canvas: [
                  {
                    relativePosition: {
                      x: 0,
                      y: 0,
                    },
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 532,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
              });

              if (images.shipImage && images.shipImage.base64DataUrl && reportData.report.options.ship.image) {
                shipContent.push({
                  alignment: _this.reportAlignOptions.CENTER,
                  image: `${images.shipImage.base64DataUrl}`,
                  width: _this.imageWidth,
                });
              }

              shipContent.push({
                alignment: _this.reportAlignOptions.CENTER,
                text: `${shipInfo}`,
                style: 'quote',
              });

              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.SHIP,
                reportData.shipNotes,
                reportData.report.options.ship.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.shipSnapshots,
                reportData.shipNotes,
                ReportSectionIdsEnum.SHIP,
                reportData.report.options.ship.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.SHIP,
                reportData.report.options.ship.videos
              );

              if (snapshotsSection.length > 0) {
                shipContent.push(snapshotsSection);
              }

              if (notesSection.length > 0) {
                shipContent.push(notesSection);
              }

              if (videosSection.length > 0) {
                shipContent.push(videosSection);
              }

              content.push(shipHeader, shipContent);
            } catch (ex) {
              const errMessage = `Error generating shipSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          snapshotSection: function (snapshots, notes, reportSectionId, reportDisplayOption) {
            let content = [];

            if (reportDisplayOption) {
              try {
                const snapshotContent = [];
                let snapshotsToDisplay = [];
                const matchingReportSection = reportSections.find(
                  (reportSection) => reportSection._id === reportSectionId
                );
                const title =
                  matchingReportSection && matchingReportSection.displayName === 'SNAPSHOTS'
                    ? 'SNAPSHOTS'
                    : `${matchingReportSection.displayName} SNAPSHOTS`;

                if (snapshots && snapshots.length > 0) {
                  snapshotsToDisplay = snapshots.filter((snapshot) => snapshot.reportSectionId === reportSectionId);
                }

                if (snapshotsToDisplay.length > 0) {
                  snapshotContent.push(
                    {
                      text: title,
                      style: 'subheaderLevel2',
                    },
                    {
                      canvas: [
                        {
                          relativePosition: {
                            x: 0,
                            y: 0,
                          },
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 532,
                          y2: 0,
                          lineWidth: 1,
                        },
                      ],
                    }
                  );

                  snapshotsToDisplay.forEach((snapshot) => {
                    if (snapshot.base64DataUrl) {
                      snapshotContent.push({
                        alignment: _this.reportAlignOptions.CENTER,
                        image: `${snapshot.base64DataUrl}`,
                        width: _this.imageWidth,
                      });

                      const snapshotNoteContent = generatePdfSections.snapshotNoteSection(
                        snapshot.reportSectionId,
                        notes,
                        snapshot
                      );

                      if (snapshotNoteContent.length > 0) {
                        snapshotContent.push(snapshotNoteContent);
                      }
                    }
                  });
                }

                if (snapshotContent.length > 0) {
                  content.push(snapshotContent);
                }
              } catch (ex) {
                const errMessage = `Error generating snapshots for reportSectionId ${reportSectionId} for reportId ${reportData.report._id}: ${ex}`;
                _this.errorService.handleError(errMessage);
                content = [];
              }
            }

            return content;
          },
          snapshotNoteSection: function (reportSectionId, notes, image) {
            let content = [];

            try {
              const reportSection = reportSections.find((reportSection) => reportSection._id === reportSectionId);
              const sectionNotes =
                notes && Array.isArray(notes) && image
                  ? notes.filter((note) => note.reportSectionId === reportSectionId && note.imageId === image._id)
                  : [];

              if (sectionNotes.length > 0 && reportSection) {
                const headerContent = [
                  {
                    text: `Notes for Snapshot:  ${image.name}`,
                    style: 'snapshotNoteHeader',
                  },
                ];

                const dataContent = {
                  ul: [],
                };

                sectionNotes.map(function (note) {
                  dataContent.ul.push(note.noteText);
                });

                content.push(headerContent, dataContent);
              }
            } catch (ex) {
              const errMessage = `Error generating reportSectionId ${reportSectionId} snapshot notes for imageid ${image ? image._id : ''
                } for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          userInfoSection: function () {
            const formattedPhoneNumber = _this.formatPhoneNumber(reportData.author.phoneNumber);

            const content = [
              {
                text: 'USER INFORMATION',
                style: 'subheader',
              },
              {
                canvas: [
                  {
                    relativePosition: { x: 0, y: 0 },
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                    y2: 0,
                    lineWidth: 1,
                  },
                ],
              },
              [
                {
                  alignment: _this.reportAlignOptions.JUSTIFY,
                  margin: [0, 5, 0, 0],
                  columns: [
                    {
                      width: '25%',
                      text: 'Full Name: \nTitle / Position: \nEmail: \nTelephone: ',
                      style: 'bold',
                    },
                    {
                      text: `${reportData.author.fullName}\n${reportData.author.title}\n${reportData.author.email}\n${formattedPhoneNumber}`,
                      style: 'normal',
                    },
                  ],
                },
              ],
            ];

            return content;
          },
          vehicleEngineSection: function () {
            let content = [];

            try {
              if (reportData.report.options.vehicle.engine) {
                content.push(
                  {
                    text: 'ENGINE',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );

                const vehicleEngineTableContent = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                if (reportData.vehicle.isMotorized) {
                  vehicleEngineTableContent.table.body.push([
                    {
                      text: 'Engine:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.engine}`,
                      style: 'tableValueColumn',
                    },
                  ]);

                  vehicleEngineTableContent.table.body.push([
                    {
                      text: 'Maximum Horsepower:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.maximumHorsepower.value}${reportData.vehicle.maximumHorsepower.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);

                  vehicleEngineTableContent.table.body.push([
                    {
                      text: 'Torque:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.maximumTorque.value}${reportData.vehicle.maximumTorque.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                content.push(vehicleEngineTableContent);
              }
            } catch (ex) {
              const errMessage = `Error generating vehicleEngineSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          vehicleBrakingSection: function () {
            let content = [];

            try {
              if (reportData.report.options.vehicle.brakes) {
                content.push(
                  {
                    text: 'BRAKING',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );

                const vehicleBrakingTableContent = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                if (reportData.vehicle.hasBrakes) {
                  vehicleBrakingTableContent.table.body.push([
                    {
                      text: 'Braking',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.braking}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                content.push(vehicleBrakingTableContent);
              }
            } catch (ex) {
              const errMessage = `Error generating vehicleBrakingSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          vehicleGeneralInfoSection: function () {
            let content = [];

            try {
              if (reportData.report.options.vehicle.generalInfo) {
                content.push(
                  {
                    text: 'GENERAL INFORMATION',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );

                const vehicleGeneralTable = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                vehicleGeneralTable.table.body.push([
                  {
                    text: 'Model Number:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${reportData.vehicle.modelName}`,
                    style: 'tableValueColumn',
                  },
                ]);

                if (equipmentTypeName) {
                  vehicleGeneralTable.table.body.push([
                    {
                      text: 'Equipment Type:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${equipmentTypeName}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                vehicleGeneralTable.table.body.push([
                  {
                    text: 'Purpose:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${reportData.vehicle.purpose}`,
                    style: 'tableValueColumn',
                  },
                ]);

                if (movementTypeName) {
                  vehicleGeneralTable.table.body.push([
                    {
                      text: 'Movement Type:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${movementTypeName}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                vehicleGeneralTable.table.body.push([
                  {
                    text: 'Is Motorized:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${getYesOrNo(reportData.vehicle.isMotorized)}`,
                    style: 'tableValueColumn',
                  },
                ]);

                vehicleGeneralTable.table.body.push([
                  {
                    text: 'Has Brakes:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${getYesOrNo(reportData.vehicle.hasBrakes)}`,
                    style: 'tableValueColumn',
                  },
                ]);

                content.push(vehicleGeneralTable);
              }
            } catch (ex) {
              const errMessage = `Error generating vehicleGeneralSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          vehicleManeuverabilitySection: function () {
            let content = [];

            try {
              if (reportData.report.options.vehicle.maneuverability) {
                content.push(
                  {
                    text: 'MANEUVERABILITY',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );

                const vehicleManeuverabilityTableContent = {
                  style: 'tableStandard',
                  table: {
                    body: [],
                  },
                  layout: 'noBorders',
                };

                vehicleManeuverabilityTableContent.table.body.push([
                  {
                    text: 'Approach Angle:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${reportData.vehicle.angleOfApproach}${String.fromCharCode(176)}`,
                    style: 'tableValueColumn',
                  },
                ]);

                vehicleManeuverabilityTableContent.table.body.push([
                  {
                    text: 'Departure Angle:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${reportData.vehicle.angleOfDeparture}${String.fromCharCode(176)}`,
                    style: 'tableValueColumn',
                  },
                ]);

                vehicleManeuverabilityTableContent.table.body.push([
                  {
                    text: 'Ramp Over Angle:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${reportData.vehicle.rampBreakoverAngle}${String.fromCharCode(176)}`,
                    style: 'tableValueColumn',
                  },
                ]);

                if (reportData.vehicle.groundClearance) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Ground Clearance:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.groundClearance.value} ${reportData.vehicle.groundClearance.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.centerOfGravity) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Horizontal Center of Gravity:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.centerOfGravity.horizontal.value} ${reportData.vehicle.centerOfGravity.horizontal.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);

                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Vertical Center of Gravity:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.centerOfGravity.vertical.value} ${reportData.vehicle.centerOfGravity.vertical.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.curbWeight) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Curb Weight:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.curbWeight.value} ${reportData.vehicle.curbWeight.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.overallDimensions) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Overall Dimensions:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      table: {
                        body: [
                          ['Length', 'Width', 'Height'],
                          [
                            `${reportData.vehicle.overallDimensions.vehicleLength.value} ${reportData.vehicle.overallDimensions.vehicleLength.units}`,
                            `${reportData.vehicle.overallDimensions.vehicleWidth.value} ${reportData.vehicle.overallDimensions.vehicleWidth.units}`,
                            `${reportData.vehicle.overallDimensions.vehicleHeight.value} ${reportData.vehicle.overallDimensions.vehicleHeight.units}`,
                          ],
                        ],
                      },
                    },
                  ]);
                }

                if (isWheeledVehicle && reportData.vehicle.maximumSteeringAngle) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Maximum Steering Angle:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.maximumSteeringAngle}${String.fromCharCode(176)}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.minimumTurningRadius) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Minimum Turning Radius:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.minimumTurningRadius.value} ${reportData.vehicle.minimumTurningRadius.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                vehicleManeuverabilityTableContent.table.body.push([
                  {
                    text: 'Steering Type:  ',
                    style: 'tableTitleColumn',
                  },
                  {
                    text: `${steeringTypeName}`,
                    style: 'tableValueColumn',
                  },
                ]);

                if (isWheeledVehicle) {
                  if (reportData.vehicle.tireRadius) {
                    vehicleManeuverabilityTableContent.table.body.push([
                      {
                        text: 'Tire Radius:  ',
                        style: 'tableTitleColumn',
                      },
                      {
                        text: `${reportData.vehicle.tireRadius}`,
                        style: 'tableValueColumn',
                      },
                    ]);
                  }

                  if (reportData.vehicle.numberOfWheels) {
                    vehicleManeuverabilityTableContent.table.body.push([
                      {
                        text: 'Number of Wheels:  ',
                        style: 'tableTitleColumn',
                      },
                      {
                        text: `${reportData.vehicle.numberOfWheels}`,
                        style: 'tableValueColumn',
                      },
                    ]);
                  }
                }

                if (reportData.vehicle.wheelTraack) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Wheel Track:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.wheelTrack.value}${reportData.vehicle.wheelTrack.units}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.numberOfAxles) {
                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Number of Axles:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      text: `${reportData.vehicle.numberOfAxles}`,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                if (reportData.vehicle.wheelbase) {
                  const wheelbaseTable = {
                    body: [['From Axle', 'To Axle', `Distance (${reportData.vehicle.wheelbase.units})`]],
                  };

                  reportData.vehicle.wheelbase.measurements.map(function (measurement) {
                    wheelbaseTable.body.push([
                      `${measurement.fromAxlePosition}`,
                      `${measurement.toAxlePosition}`,
                      `${measurement.value}`,
                    ]);
                  });

                  vehicleManeuverabilityTableContent.table.body.push([
                    {
                      text: 'Wheelbase:  ',
                      style: 'tableTitleColumn',
                    },
                    {
                      table: wheelbaseTable,
                      style: 'tableValueColumn',
                    },
                  ]);
                }

                content.push(vehicleManeuverabilityTableContent);
              }
            } catch (ex) {
              const errMessage = `Error generating vehicleManeuverabilitySection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          vehicleSection: function () {
            let content = [];

            try {
              let vehicleUploader, vehicleCreationDate, vehicleInfo;

              if (reportData.vehicleModel3d) {
                vehicleUploader = _this.userService.getUserName(reportData.vehicleModel3d.creatorId);
                vehicleCreationDate = moment(reportData.vehicleModel3d.modelDate);
                vehicleInfo = `Uploader: ${vehicleUploader}\n`;

                if (reportData.report.options.vehicle.model3d.dataSource) {
                  vehicleInfo += `Data Source: ${reportData.vehicleModel3d.dataSourceName}`;
                }

                if (reportData.report.options.vehicle.model3d.modelDate) {
                  if (reportData.report.options.vehicle.model3d.dataSource) {
                    vehicleInfo += ` | Date Created: ${vehicleCreationDate.format('MM/DD/YYYY')}`;
                  } else {
                    vehicleInfo += `Date Created: ${vehicleCreationDate.format('MM/DD/YYYY')}`;
                  }
                }

                if (reportData.report.options.vehicle.model3d.fidelity) {
                  vehicleInfo += `\nFidelity: ${reportData.vehicleModel3d.fidelityType}`;
                }

                if (reportData.report.options.vehicle.model3d.fileType) {
                  vehicleInfo += `\nFile Type: ${reportData.vehicleModel3d.fileType}`;
                }
              } else if (reportData.vehicleScan) {
                vehicleUploader = _this.userService.getUserName(reportData.vehicleScan.creatorId);
                vehicleCreationDate = moment(reportData.vehicleScan.scanDate);
                vehicleInfo = `Uploader: ${vehicleUploader}\n`;

                if (reportData.report.options.vehicle.scan.dataSource) {
                  vehicleInfo += `Data Source: ${reportData.vehicleScan.dataSourceName}`;
                }

                if (reportData.report.options.vehicle.scanDate) {
                  if (reportData.report.options.vehicle.scan.dataSource) {
                    vehicleInfo += ` | Date Created: ${vehicleCreationDate.format('MM/DD/YYYY')}`;
                  } else {
                    vehicleInfo += `Date Created: ${vehicleCreationDate.format('MM/DD/YYYY')}`;
                  }
                }

                if (reportData.report.options.vehicle.scan.scanType) {
                  vehicleInfo += `\nScan Type: ${reportData.vehicleScan.scanType}`;
                }

                if (reportData.report.options.vehicle.scan.decimationLevel) {
                  vehicleInfo += `\nDecimation Level: ${reportData.vehicleScan.decimationLevel.value}${reportData.vehicleScan.decimationLevel.units}`;
                }

                if (reportData.report.options.vehicle.scan.registrationError) {
                  if (reportData.report.options.vehicle.scan.decimationLevel) {
                    vehicleInfo += ` | Registration Error: ${reportData.vehicleScan.registrationError.value}${reportData.vehicleScan.registrationError.units}`;
                  } else {
                    vehicleInfo += `\nRegistration Error: ${reportData.vehicleScan.registrationError.value}${reportData.vehicleScan.registrationError.units}`;
                  }
                }

                if (reportData.report.options.vehicle.scan.cumulativeError) {
                  vehicleInfo += ` | Propagated Error: ${reportData.vehicleScan.cumulativeError.value}${reportData.vehicleScan.cumulativeError.units}`;
                }

                if (reportData.report.options.vehicle.scan.fileType) {
                  vehicleInfo += `\nFile Type: ${reportData.vehicleScan.fileType}`;
                }
              }

              const vehicleHeader = [
                {
                  text: 'VEHICLE',
                  style: 'subheader',
                },
                {
                  canvas: [
                    {
                      relativePosition: { x: 0, y: 0 },
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: _this.pageWidth - _this.pageLeftMargin - _this.pageRightMargin,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                },
                {
                  alignment: _this.reportAlignOptions.JUSTIFY,
                  margin: [0, 5, 0, 0],
                  columns: [
                    {
                      width: '25%',
                      text: `Name: \nDesignation: \n`,
                      style: 'bold',
                    },
                    {
                      text: `${reportData.vehicle.name}\n${reportData.vehicle.designation}`,
                      style: 'normal',
                    },
                  ],
                },
              ];

              const vehicleContent = [];

              if (reportData.vehicleScan) {
                vehicleContent.push(
                  {
                    text: 'SCAN',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );
              } else if (reportData.vehicleModel3d) {
                vehicleContent.push(
                  {
                    text: '3D Model',
                    style: 'subheaderLevel2',
                  },
                  {
                    canvas: [
                      {
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 532,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                  }
                );
              }

              if (images.vehicleImage && images.vehicleImage.base64DataUrl && reportData.report.options.vehicle.image) {
                vehicleContent.push({
                  alignment: _this.reportAlignOptions.CENTER,
                  image: `${images.vehicleImage.base64DataUrl}`,
                  width: _this.imageWidth,
                });
              }

              vehicleContent.push({
                alignment: _this.reportAlignOptions.CENTER,
                text: `${vehicleInfo}`,
                style: 'quote',
              });

              content.push(vehicleHeader, vehicleContent);

              const generalSection = generatePdfSections.vehicleGeneralInfoSection();
              const engineSection = generatePdfSections.vehicleEngineSection();
              const brakingSection = generatePdfSections.vehicleBrakingSection();
              const maneuverabilitySection = generatePdfSections.vehicleManeuverabilitySection();
              const notesSection = generatePdfSections.noteSection(
                ReportSectionIdsEnum.VEHICLE,
                reportData.vehicleNotes,
                reportData.report.options.vehicle.notes
              );
              const snapshotsSection = generatePdfSections.snapshotSection(
                images.vehicleSnapshots,
                reportData.vehicleNotes,
                ReportSectionIdsEnum.VEHICLE,
                reportData.report.options.vehicle.snapshots
              );
              const videosSection = generatePdfSections.videoSection(
                reportData.projectVideos,
                reportData.projectNotes,
                ReportSectionIdsEnum.VEHICLE,
                reportData.report.options.vehicle.videos
              );

              if (generalSection.length > 0) {
                content.push(generalSection);
              }

              if (engineSection.length > 0) {
                content.push(engineSection);
              }

              if (brakingSection.length > 0) {
                content.push(brakingSection);
              }

              if (maneuverabilitySection.length > 0) {
                content.push(maneuverabilitySection);
              }

              if (snapshotsSection.length > 0) {
                content.push(snapshotsSection);
              }

              if (notesSection.length > 0) {
                content.push(notesSection);
              }

              if (videosSection.length > 0) {
                content.push(videosSection);
              }
            } catch (ex) {
              const errMessage = `Error generating vehicleSection for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
          //clean this up, probably use table from examples http://pdfmake.org/playground.html
          videoSection: function (videos, notes, reportSectionId, reportDisplayOption) {
            let content = [];

            if (reportDisplayOption) {
              try {
                const videoContent = [];
                let videosToDisplay = [];
                const matchingReportSection = reportSections.find(
                  (reportSection) => reportSection._id === reportSectionId
                );
                const title = matchingReportSection
                  ? `${matchingReportSection.displayName} VIDEOS WITH NOTES`
                  : 'VIDEOS WITH NOTES';
                const videoList = {
                  ul: [],
                };

                if (videos && videos.length > 0) {
                  videosToDisplay = videos.filter((video) => video.reportSectionId === reportSectionId);
                }

                if (videosToDisplay.length > 0) {
                  videoContent.push(
                    {
                      text: title,
                      style: 'subheaderLevel2',
                    },
                    {
                      canvas: [
                        {
                          relativePosition: {
                            x: 0,
                            y: 0,
                          },
                          type: 'line',
                          x1: 0,
                          y1: 0,
                          x2: 532,
                          y2: 0,
                          lineWidth: 1,
                        },
                      ],
                    }
                  );

                  videosToDisplay.forEach((video) => {
                    const videoNoteContent = generatePdfSections.videoNoteSection(video.reportSectionId, notes, video);

                    if (videoNoteContent.length > 0) {
                      videoList.ul.push([video.name, videoNoteContent]);
                    } else {
                      videoList.ul.push(video.name);
                    }
                  });
                } //loop thru videos

                videoContent.push(videoList);

                if (videoContent.length > 0) {
                  content.push(videoContent);
                }
              } catch (ex) {
                const errMessage = `Error generating videos for reportSectionId ${reportSectionId} for reportId ${reportData.report._id}: ${ex}`;
                _this.errorService.handleError(errMessage);
                content = [];
              }
            }

            return content;
          },
          videoNoteSection: function (reportSectionId, notes, video) {
            let content = [];

            try {
              const reportSection = reportSections.find((reportSection) => reportSection._id === reportSectionId);
              const sectionNotes =
                notes && Array.isArray(notes) && video
                  ? notes.filter((note) => note.reportSectionId === reportSectionId && note.videoId === video._id)
                  : [];

              if (sectionNotes.length > 0 && reportSection) {
                const dataContent = {
                  type: 'circle',
                  ul: [],
                };

                sectionNotes.map(function (note) {
                  dataContent.ul.push(note.noteText);
                });

                content.push(dataContent);
              }
            } catch (ex) {
              const errMessage = `Error generating reportSectionId ${reportSectionId} snapshot notes for videoId ${video ? video._id : ''
                } for reportId ${reportData.report._id}: ${ex}`;
              _this.errorService.handleError(errMessage);
              content = [];
            }

            return content;
          },
        };

        const content = [];
        const projectInfoContent = generatePdfSections.projectInfoSection();
        const userInfoSection = generatePdfSections.userInfoSection();
        const propagatedErrorSection = generatePdfSections.propagatedErrorSection();
        const shipSection = generatePdfSections.shipSection();
        const vehicleSection = generatePdfSections.vehicleSection();
        const analysisSection = generatePdfSections.analysisSection();
        const minimumClearanceSection = generatePdfSections.minimumClearanceSection();
        const pathTraveledSection = generatePdfSections.pathTraveledSection();
        const collisionSection = generatePdfSections.collisionSection();
        const projectSnapshotSection = generatePdfSections.projectSnapshotSection();

        if (projectInfoContent.length > 0) {
          content.push(projectInfoContent);
        }

        if (userInfoSection.length > 0) {
          content.push(userInfoSection);
        }

        if (propagatedErrorSection.length > 0) {
          content.push(propagatedErrorSection);
        }

        if (shipSection.length > 0) {
          content.push(shipSection);
        }

        if (vehicleSection.length > 0) {
          content.push(vehicleSection);
        }

        if (analysisSection.length > 0) {
          content.push(analysisSection);
        }

        if (minimumClearanceSection.length > 0) {
          content.push(minimumClearanceSection);
        }

        if (pathTraveledSection.length > 0) {
          content.push(pathTraveledSection);
        }

        if (collisionSection.length > 0) {
          content.push(collisionSection);
        }

        if (projectSnapshotSection.length > 0) {
          content.push(projectSnapshotSection);
        }

        //avoid orphan children (see https://pdfmake.github.io/docs/0.1/document-definition-object/page/)
        const dd = {
          footer: footer,
          header: header,
          info: info,
          content: content,
          pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
          },
          pageMargins: [_this.pageLeftMargin, _this.pageTopMargin, _this.pageRightMargin, _this.pageBottomMargin],
          pageOrientation: 'portrait',
          pageSize: 'LETTER',
          styles: styles,
          watermark: {
            text: 'DRAFT REPORT',
            color: 'gray',
            opacity: 0.3,
            bold: true,
            italics: false,
          },
        };

        let isDraft =
          reportData.report && reportData.report.finalized && reportData.report.finalized.finalizedDate ? false : true;

        if (isDraft && reportData.finalize) {
          isDraft = false;
        }

        if (!isDraft) {
          //removing the watermark messes up the document formatting for some reason, just remove the text - jane 7/15/2021
          dd.watermark.text = '';
        }

        try {
          const pdfDocGenerator = pdfMake.createPdf(dd);

          if (reportData.finalize) {
            const ts = new Date().valueOf();

            pdfDocGenerator.getBlob((blob) => {
              const fileToUpload = new File([blob], `${reportData.report.name}-${ts}.pdf`, {
                lastModified: new Date().valueOf(),
                type: blob.type,
              });

              const tags = [];
              tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.REPORT));

              _this.fileService
                .uploadFile(
                  fileToUpload,
                  FileObjectTypesEnum.PROJECT,
                  reportData.project._id,
                  tags,
                  TagFileTypesEnum.REPORT,
                  currentUser
                )
                .then(function (uploadResults) {
                  _this.logService.logInfo(uploadResults);
                  _this
                    .saveReportFinalizedUrls(
                      reportData.report._id,
                      uploadResults.locationUrl,
                      uploadResults.thumbnailUrl,
                      currentUser
                    )
                    .then(function (finalizedResults) {
                      console.log(`successfully finalized reportId ${reportData.report._id}`);
                      _this.reportErrorSubject.next('');

                      if (uploadResults.processing) {
                        Swal.fire(
                          'File Upload in Process',
                          'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                          'info'
                        );
                      }
                    })
                    .catch(function (finalizedError) {
                      _this.errorService.handleError(finalizedError);
                    });
                })
                .catch(function (uploadError) {
                  _this.errorService.handleError(uploadError);
                });
            });
          }

          pdfDocGenerator.getDataUrl((dataUrl) => {
            resolve(dataUrl);
          });
        } catch (pdfError) {
          const errMessage = `Error generating pdf ${pdfError}`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } catch (reportErr) {
        const errMessage = `Error generating report PDF for ${reportData.report._id}: ${reportErr}`;
        _this.errorService.handleError(errMessage);
        reject(errMessage);
      }
    });
  }

  //get base64 image data for pdfmake
  async getReportImages(report: Report, project: Project, ship: Ship, vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const promises = [];

      const returnValue = {
        projectImages: [],
        shipImages: [],
        vehicleImages: [],
      };

      promises.push(
        _this.imageDocService.refreshImagesByParent(DbCollectionsEnum.PROJECTS, project ? project._id : null, true, currentUser)
      );
      promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, true, currentUser));
      promises.push(
        _this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, true, currentUser)
      );

      Promise.allSettled(promises).then((results) => {
        const projectResults = results[0];
        const shipResults = results[1];
        const vehicleResults = results[2];

        if (projectResults.status === 'fulfilled') {
          returnValue.projectImages = projectResults.value;
        }

        if (shipResults.status === 'fulfilled') {
          returnValue.shipImages = shipResults.value;
        }

        if (vehicleResults.status === 'fulfilled') {
          returnValue.vehicleImages = vehicleResults.value;
        }

        _this.settingsService.setIsLoading(false);
        resolve(returnValue);
      });
    });
  }

  async getReportById(
    reportId: string,
    project: Project,
    ship: Ship,
    shipMod: Mod,
    shipModel3d: Model3d,
    shipScan: Scan,
    vehicle: Vehicle,
    vehicleMod: Mod,
    vehicleModel3d: Model3d,
    vehicleScan: Scan,
    currentUser: User
  ): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      this.settingsService.setIsLoading(true);
      const reports = _this.currentProjectReportsSubject.getValue() || [];
      let returnValue: string;
      let reportImages;

      if (currentUser && reportId) {
        const report = reports.find((report) => report._id === reportId);
        _this.currentReportSubject.next(report);

        if (report) {
          const promises = [];

          promises.push(_this.getReportImages(report, project, ship, vehicle, currentUser));
          promises.push(_this.noteService.getCurrentProjectNotes());
          promises.push(_this.noteService.getCurrentShipNotes());
          promises.push(_this.noteService.getCurrentVehicleNotes());
          promises.push(_this.videoService.getCurrentProjectVideos());

          Promise.allSettled(promises).then((results) => {
            const imageResults = results[0];
            const projectNoteResults = results[1];
            const shipNoteResults = results[2];
            const vehicleNoteResults = results[3];
            const projectVideoResults = results[4];

            const reportData = {
              author: currentUser,
              project: project,
              projectImages: imageResults.status == 'fulfilled' ? imageResults.value.projectImages : [],
              projectNotes: projectNoteResults.status == 'fulfilled' ? projectNoteResults.value : [],
              projectVideos: projectVideoResults.status == 'fulfilled' ? projectVideoResults.value : [],
              report: report,
              runDate: new Date(),
              ship: ship,
              shipImages: imageResults.status == 'fulfilled' ? imageResults.value.shipImages : [],
              shipMod: shipMod,
              shipModel3d: shipModel3d,
              shipNotes: shipNoteResults.status == 'fulfilled' ? shipNoteResults.value : [],
              shipScan: shipScan,
              finalize: false,
              vehicle: vehicle,
              vehicleImages: imageResults.status == 'fulfilled' ? imageResults.value.vehicleImages : [],
              vehicleNotes: vehicleNoteResults.status == 'fulfilled' ? vehicleNoteResults.value : [],
              vehicleMod: vehicleMod,
              vehicleModel3d: vehicleModel3d,
              vehicleScan: vehicleScan,
            };

            _this
              .generateReportPdf(reportData, currentUser)
              .then((pdfUrl: string) => {
                returnValue = pdfUrl;
                _this.currentReportIFrameSrcSubject.next(pdfUrl);
                resolve(pdfUrl);
              })
              .catch((pdfError) => {
                const errMessage = _this.errorService.handleError(
                  `Error generating pdf for reportId ${reportId}: ${pdfError.message}`
                );
                _this.reportErrorSubject.next(errMessage);
                _this.currentReportIFrameSrcSubject.next(null);
                reject(errMessage);
              })
              .finally(() => {
                _this.settingsService.setIsLoading(false);
              });
          });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`Unable to find reportId ${reportId}`);
          _this.reportErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentReportIFrameSrcSubject.next('');
        resolve('');
      }
    });
  }

  getReportSectionsUserCanAddNoteTo(parentCollection: string) {
    const reportSections = this.reportSectionsSubject.getValue();
    return reportSections.filter((reportSection) => {
      let returnValue = true;
      const idx = reportSection.parentCollections.indexOf(parentCollection);

      if (!reportSection.userCanAddNotes || idx === -1) {
        returnValue = false;
      }

      return returnValue;
    });
  }

  getReportiFrameSource(): any {
    return this.currentReportIFrameSrcSubject.getValue();
  }

  getCurrentReport() {
    return this.currentReportSubject.getValue();
  }

  getReportSections() {
    return this.reportSectionsSubject.getValue();
  }

  getReportSectionsFromAPI(currentUser: User): Observable<ReportSection[]> {
    const reportSectionsHttp$ = createHttpObservable(`${environment.baseAPIUrl}reportSection?userId=${currentUser?._id}`, {}, true);

    reportSectionsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting report sections: ${err}`);
          return of([]);
        })
      )
      .subscribe((reportSections: ReportSection[]) => this.reportSectionsSubject.next(reportSections));

    return reportSectionsHttp$;
  }

  async refreshReportsByProject(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Report[];

      if (currentUser && projectId) {
        const url = `${environment.baseAPIUrl}report/${DbCollectionsEnum.PROJECTS}/${projectId}?userId=${currentUser?._id}`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (reports: Report[]) => {
              returnValue = reports;
              _this.currentProjectReportsSubject.next(reports);
              _this.reportErrorSubject.next('');
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.reportErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentProjectReportsSubject.next(returnValue);
        _this.reportErrorSubject.next('');
        resolve(returnValue);
      }
    });
  }

  async refreshReportsByUser(currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: ReportOverview[];

      if (currentUser) {
        const url = `${environment.baseAPIUrl}report/user/${currentUser._id}/overview`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (reports: ReportOverview[]) => {
              returnValue = reports;
              _this.selectedUserReportsSubject.next(reports);
              _this.reportErrorSubject.next('');
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.reportErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.selectedUserReportsSubject.next(returnValue);
        _this.reportErrorSubject.next('');
        resolve(returnValue);
      }
    });
  }

  async saveReport(
    reportId: string,
    changes,
    project: Project,
    ship: Ship,
    shipMod: Mod,
    shipModel3d: Model3d,
    shipScan: Scan,
    vehicle: Vehicle,
    vehicleMod: Mod,
    vehicleModel3d: Model3d,
    vehicleScan: Scan,
    currentUser: User
  ): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const reports = _this.currentProjectReportsSubject.getValue();
      const reportIndex = reports.findIndex((report) => report._id === reportId);
      const newReports = reports.slice(0);
      const url = `${environment.baseAPIUrl}report/${reportId}?userId=${currentUser?._id}`;
      let returnValue: string;

      _this.httpClient
        .put(url, changes, {
          headers: getCustomHeaders(true),
          responseType: 'json',
        })
        .subscribe({
          next: (updatedReport: Report) => {
            _this.currentReportSubject.next(updatedReport);
            newReports[reportIndex] = updatedReport;
            _this.currentProjectReportsSubject.next(newReports);

            _this
              .getReportById(
                updatedReport._id,
                project,
                ship,
                shipMod,
                shipModel3d,
                shipScan,
                vehicle,
                vehicleMod,
                vehicleModel3d,
                vehicleScan,
                currentUser
              )
              .then((pdfUrl: string) => {
                returnValue = pdfUrl;
                _this.currentReportIFrameSrcSubject.next(pdfUrl);
                resolve(pdfUrl);
              })
              .catch((pdfError) => {
                _this.reportErrorSubject.next(pdfError.message);
                _this.currentReportIFrameSrcSubject.next(null);
                reject(pdfError.message);
              })
              .finally(function () {
                _this.settingsService.setIsLoading(false);
              });
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.reportErrorSubject.next(errMessage);
            _this.settingsService.setIsLoading(false);
            reject(error);
          },
          complete: () => {
            _this.settingsService.setIsLoading(false);
          }
        });
    });
  }

  async saveReportFinalizedUrls(reportId: string, locationUrl: string, thumbnailUrl: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Report;

      if (reportId && locationUrl && currentUser) {
        const reports = _this.currentProjectReportsSubject.getValue();
        const reportIndex = reports.findIndex((report) => report._id === reportId);
        const newReports = reports.slice(0);

        const url = `${environment.baseAPIUrl}report/${reportId}?userId=${currentUser?._id}`;

        const changes = {
          finalized: {
            finalizedDate: new Date(),
            thumbnailUrl: thumbnailUrl,
            url: locationUrl,
            userId: currentUser._id,
          },
          editorId: currentUser._id,
        };

        newReports[reportIndex] = {
          ...reports[reportIndex],
          ...changes,
        };

        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedReport: Report) => {
              returnValue = updatedReport;
              _this.currentReportSubject.next(updatedReport);
              newReports[reportIndex] = updatedReport;
              _this.currentProjectReportsSubject.next(newReports);
              resolve(returnValue)
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.reportErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `reportId, locationUrl and user are required to save the finalized report url`
        );
        _this.reportErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
