import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Scan, Ship, UnrealServer, User, Vehicle } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  ProjectService,
  ScanService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, ModStatesEnum, UnrealScenesEnum, UnrealUIStatesEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { ScanDialogComponent } from '../scan-dialog/scan-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-scan-table',
    templateUrl: './scan-table.component.html',
    styleUrls: ['./scan-table.component.css'],
    standalone: false
})
export class ScanTableComponent implements OnInit {
  @Input()
  currentUser: User;

  @Input()
  isProject: boolean;

  @Input()
  panoramicScans: Scan[];

  @Input()
  pointCloudScans: Scan[];

  @Input()
  scans: Scan[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @Input()
  parentShip: Ship;

  @Input()
  parentVehicle: Vehicle;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ScanDialogComponent) scanDialog;

  currentUser$: Observable<User>;

  displayedScanColumns: string[] = [
    'name',
    'scanDate',
    'classification',
    'scanType',
    'dataSourceName',
    'nameOfPersonScanning',
    'decimationLevel',
    'registrationError',
    'description',
    'actions',
  ];
  updatedScanId$: Observable<string>;
  isOpeningUnrealViewer: boolean;
  isReadOnly: boolean;
  private scanErrorSubject = new BehaviorSubject<string>(null);
  scanError$: Observable<string> = this.scanErrorSubject.asObservable();
  decimationLevelForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  dataSource;

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const isAdmin = this.currentUser && this.currentUser.role === UserRolesEnum.ADMIN;
    this.isReadOnly = isAdmin ? false : true;
    this.dataSource = new MatTableDataSource(this.scans);

    this.scanService.updatedScanId$.pipe(untilDestroyed(this)).subscribe({
      next: (event) => {
        if (this.parentShip) {
          //only show lines for the point cloud scans, give second viewer icon for the panoramic scan if there is one - jane 5/31/2024
          //this.dataSource.data = this.scanService.getCurrentShipScans();
          this.dataSource.data = this.scanService.getCurrentShipPointCloudScans();
        } else {
          this.dataSource.data = this.scanService.getCurrentVehicleScans();
        }

        if (this.table) {
          this.table.renderRows();
        }
      },
      error: (error) => console.error(`Error on updatedScanId subscription: ${error}`),
      complete: () => {
        console.log(`completed updatedScanId subscription`);
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get freeRoamTooltip(): string {
    if (this.parentShip) {
      return 'Open Ship Viewer';
    } else if (this.parentVehicle) {
      return 'Open Vehicle Viewer';
    } else {
      return 'Open Viewer';
    }
  }

  get freeRoamTooltipLite(): string {
    if (this.parentShip) {
      return 'Open Ship Viewer Lite';
    } else if (this.parentVehicle) {
      return 'Open Vehicle Viewer Lite';
    } else {
      return 'Open Viewer Lite';
    }
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get showViewerLite(): boolean {
    return environment.unreal.showViewerLite === true;
  }
  
  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  get unrealViewerNameLite(): string {
    return environment.unreal.viewerNameLite;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  canDelete(scan: Scan): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === scan.creatorId) {
      let projectCount = (scan.usedInProjectsAsShipScan ? scan.usedInProjectsAsShipScan.length : 0)  
      + (scan.usedInProjectsAsVehicleScan ? scan.usedInProjectsAsVehicleScan.length : 0);
      if (projectCount === 0) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  deleteScan(scan: Scan): void {
    const _this = this;
    this.scanService
      .getScanById(scan._id, scan.parent.collection, scan.parent._id, _this.currentUser)
      .then((s: Scan) => {
        Swal.fire({
          title: `Delete Scan:  ${scan.name}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.scanService
              .deleteScan(scan._id, scan.parent.collection, scan.parent._id, _this.currentUser)
              .then((scans: Scan[]) => {
                //console.log(`successfully deleted scanId ${scan._id}`);
              })
              .catch((error) => {
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error Deleting Scan',
                    `There was an error deleting the scan: ${error.message}.  Please email ${environment.techSupportEmail}`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((scanError) => {
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `Error getting the scan information required to delete.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      });
  }

  editScan(scan: Scan) {
    this.settingsService.setIsLoading(true);
    const promises = [];

    this.scanService
      .getScanById(scan._id, scan.parent.collection, scan.parent._id, this.currentUser)
      .then((scanWithChildData: Scan) => {
        if (this.parentShip) {
          promises.push(this.shipService.setShipScan(this.parentShip, scan, this.currentUser));
          promises.push(
            this.modService.getModById(scan ? scan.modId : null, DbCollectionsEnum.SHIPS, this.parentShip._id, this.currentUser)
          );

          Promise.allSettled(promises).then((results) => {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              currentUser: this.currentUser,
              isNewScan: false,
              parentShip: this.parentShip,
              parentVehicle: this.parentVehicle,
              scan: scan,
            };

            this.settingsService.setIsLoading(false);
            const dialogRef = this.dialog.open(ScanDialogComponent, dialogConfig);
          });
        } else if (this.parentVehicle) {
          promises.push(this.vehicleService.setVehicleScan(this.parentVehicle, scan, this.currentUser));
          promises.push(
            this.modService.getModById(scan ? scan.modId : null, DbCollectionsEnum.VEHICLES, this.parentVehicle._id, this.currentUser)
          );

          Promise.allSettled(promises).then((results) => {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              currentUser: this.currentUser,
              isNewScan: false,
              parentShip: this.parentShip,
              parentVehicle: this.parentVehicle,
              scan: scan,
            };

            this.settingsService.setIsLoading(false);
            const dialogRef = this.dialog.open(ScanDialogComponent, dialogConfig);
          });
        } else {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `A scan must be tied to a ship or vehicle.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        }
      })
      .catch((scanError) => {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `There was an error loading the scan data.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  getHasValidPanoMod(scan: Scan): boolean {
    let returnValue = false;

    if (scan?.panoramic?.scanId) {
      const panoScan = this.panoramicScans.find((s) => s._id === scan.panoramic.scanId);

      if (panoScan && panoScan?.modDetails?.modState === ModStatesEnum.VALID) {
        returnValue = true;
      }
    }
  
    return returnValue;
  }

  getHasValidMod(scan: Scan): boolean {
    let returnValue = false;

    if (scan?.modDetails?.modState === ModStatesEnum.VALID) {
      returnValue = true;
    }
  
    return returnValue;
  }

  async openViewer(scan: Scan) {
    this.settingsService.setIsLoading(true);
    let navigationUrl;

    if (this.parentShip || this.parentVehicle) {
      try {
        const modToUse = await this.modService.getModById(scan.modId, scan.parent.collection, scan.parent._id, this.currentUser);

        if (this.parentShip) {
          if (this.isProject) {
            navigationUrl = `/ships/${this.parentShip._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}`;
          } else {
            navigationUrl = `/ships/${this.parentShip._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.FREE_ROAM}`;
          }

          if (modToUse) {
            navigationUrl += `&pcName=${modToUse.name}`;
          }
        } else if (this.parentVehicle) {
          navigationUrl = `/vehicles/${this.parentVehicle._id}/viewer?sceneName=${UnrealScenesEnum.CALIBRATION}`;

          if (modToUse) {
            navigationUrl += `&vehicleName=${modToUse.name}`;
          }
        }

        this.router
          .navigateByUrl(navigationUrl)
          .then(() => {
            this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
          })
          .catch((unrealError) => {
            this.settingsService.setIsLoading(false);
            const errMessage = this.errorService.handleError(
              `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
            );
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Opening ${environment.unreal.viewerName}`,
                `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          })
          .finally(() => {
            this.settingsService.setIsLoading(false);
            this.settingsService.setLoadingId(null);
          });
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error loading the ${environment.unreal.viewerName}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      this.settingsService.setIsLoading(false);
      const errMessage = this.errorService.handleError(
        `No parent ship or vehicle was found in the scanTable component so the ${environment.unreal.viewerName} could not be opened.`
      );
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Ship or Vehicle is Required`,
          `A ship or vehicle is required to open ${environment.unreal.viewerName}.  Please email  ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  async openViewerLite(scan: Scan) {
    this.settingsService.setIsLoading(true);
    let navigationUrl;

    if (this.parentShip || this.parentVehicle) {
      try {
        const panoScan = this.panoramicScans.find((s) => s._id === scan.panoramic.scanId);
        if (panoScan) {
          const modToUse = await this.modService.getModById(panoScan.modId, panoScan.parent.collection, panoScan.parent._id, this.currentUser);

          if (this.parentShip) {
            if (this.isProject) {
              navigationUrl = `/ships/${this.parentShip._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}&panoOnly=true`;
            } else {
              navigationUrl = `/ships/${this.parentShip._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.FREE_ROAM}&panoOnly=true`;
            }
  
            if (modToUse) {
              navigationUrl += `&pcName=${modToUse.name}`;
            }
          } else if (this.parentVehicle) {
            navigationUrl = `/vehicles/${this.parentVehicle._id}/viewer?sceneName=${UnrealScenesEnum.CALIBRATION}`;
  
            if (modToUse) {
              navigationUrl += `&vehicleName=${modToUse.name}`;
            }
          }
  
          this.router
            .navigateByUrl(navigationUrl)
            .then(() => {
              this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
            })
            .catch((unrealError) => {
              this.settingsService.setIsLoading(false);
              const errMessage = this.errorService.handleError(
                `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
              );
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error Opening ${environment.unreal.viewerName}`,
                  `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            })
            .finally(() => {
              this.settingsService.setIsLoading(false);
              this.settingsService.setLoadingId(null);
            });
        } else {
          this.settingsService.setIsLoading(false);
          const errMessage = this.errorService.handleError(
            `No panoramic scan was found so the ${environment.unreal.viewerNameLite} could not be opened.`
          );
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Panoramic Scan is Required`,
              `A panoramic scan is required to open ${environment.unreal.viewerNameLite}.  Please email  ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error loading the ${environment.unreal.viewerNameLite}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      this.settingsService.setIsLoading(false);
      const errMessage = this.errorService.handleError(
        `No panoramic scan was found in the scanTable component so the ${environment.unreal.viewerNameLite} could not be opened.`
      );
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Ship or Vehicle is Required`,
          `A ship or vehicle is required to open ${environment.unreal.viewerNameLite}.  Please email  ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
