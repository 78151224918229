import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppOverlayModule } from './shared/services/overlay/overlay.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxMaskModule } from 'ngx-mask';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { SafePipe } from './shared/classes/safe-pipe/safe-pipe';
import { ErrorService } from './shared/services/error/error.service';
import { LogService } from './shared/services/log/log.service';

import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { DocumentCardComponent } from './components/document/document-card/document-card.component';
import { DocumentTableComponent } from './components/document/document-table/document-table.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { ImageDocCardComponent } from './components/image-doc/image-doc-card/image-doc-card.component';
import { ImageDocTableComponent } from './components/image-doc/image-doc-table/image-doc-table.component';
import { LoginComponent } from './components/login/login.component';
import { ModelAnalysisProjectDialogComponent } from './components/project/model-analysis-project-dialog/model-analysis-project-dialog.component';
import { Model3dComponent } from './components/model3d/model3d.component';
import { Model3dDialogComponent } from './components/model3d/model3d-dialog/model3d-dialog.component';
import { Model3dTableComponent } from './components/model3d/model3d-table/model3d-table.component';
import { ModsComponent } from './components/mods/mods.component';
import { ModDialogComponent } from './components/mods/mod-dialog/mod-dialog.component';
import { ModsDashboardComponent } from './components/mods/mods-dashboard/mods-dashboard.component';
import { ModTableComponent } from './components/mods/mod-table/mod-table.component';
import { MultipleUsersDialogComponent } from './components/user/multiple-users-dialog/multiple-users-dialog.component';
import { NoteTableComponent } from './components/note-table/note-table.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectCardComponent } from './components/project/project-card/project-card.component';
import { ProjectsDashboardComponent } from './components/project/projects-dashboard/projects-dashboard.component';
import { ProjectDialogComponent } from './components/project/project-dialog/project-dialog.component';
import { ProjectOverviewTableComponent } from './components/project/project-overview-table/project-overview-table.component';
import { ProjectTableComponent } from './components/project/project-table/project-table.component';
import { ReportComponent } from './components/report/report.component';
import { ReportItemDialogComponent } from './components/report/report-item-dialog/report-item-dialog.component';
import { ReportOverviewTableComponent } from './components/report/report-overview-table/report-overview-table.component';
import { ScanDialogComponent } from './components/scan/scan-dialog/scan-dialog.component';
import { ScanTableComponent } from './components/scan/scan-table/scan-table.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsAppIntegrationsComponent } from './components/settings/settings-app-integrations/settings-app-integrations.component';
import { SettingsMaintenanceComponent } from './components/settings/settings-maintenance/settings-maintenance.component';
import { SettingsProjectComponent } from './components/settings/settings-project/settings-project.component';
import { SettingsSecurityComponent } from './components/settings/settings-security/settings-security.component';
import { SettingsSupportComponent } from './components/settings/settings-support/settings-support.component';
import { ShipComponent } from './components/ship/ship.component';
import { ShipCardComponent } from './components/ship/ship-card/ship-card.component';
import { ShipDialogComponent } from './components/ship/ship-dialog/ship-dialog.component';
import { ShipListComponent } from './components/ship/ship-list/ship-list.component';
import { ShipsDashboardComponent } from './components/ship/ships-dashboard/ships-dashboard.component';
import { ShipTableComponent } from './components/ship/ship-table/ship-table.component';
import { SnapshotCardComponent } from './components/snapshot-card/snapshot-card.component';
import { UnrealViewerComponent } from './components/unreal-viewer/unreal-viewer.component';
import { UserComponent } from './components/user/user.component';
import { UserCardComponent } from './components/user/user-card/user-card.component';
import { UsersDashboardComponent } from './components/user/users-dashboard/users-dashboard.component';
import { UserDialogComponent } from './components/user/user-dialog/user-dialog.component';
import { UserSubscriptionsTableComponent } from './components/user/user-subscriptions-table/user-subscriptions-table.component';
import { UserTableComponent } from './components/user/user-table/user-table.component';
import { VehicleCardComponent } from './components/vehicle/vehicle-card/vehicle-card.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { VehicleClearanceComponent } from './components/vehicle/vehicle-clearance/vehicle-clearance.component';
import { VehicleDialogComponent } from './components/vehicle/vehicle-dialog/vehicle-dialog.component';
import { VehicleListComponent } from './components/vehicle/vehicle-list/vehicle-list.component';
import { VehicleMetadataComponent } from './components/vehicle/vehicle-metadata/vehicle-metadata.component';
import { VehiclesDashboardComponent } from './components/vehicle/vehicles-dashboard/vehicles-dashboard.component';
import { VehicleTableComponent } from './components/vehicle/vehicle-table/vehicle-table.component';
import { VideoCardComponent } from './components/video/video-card/video-card.component';

import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number/two-digit-decimal-number.directive';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private errorLogService: ErrorService) {
    super();
  }

  handleError(error) {
    this.errorLogService.handleError(error);
  }
}

@NgModule({ declarations: [
        AppComponent,
        AdminDashboardComponent,
        DocumentCardComponent,
        DocumentTableComponent,
        ForgotPasswordComponent,
        HomeComponent,
        ImageDocCardComponent,
        ImageDocTableComponent,
        LoginComponent,
        ModelAnalysisProjectDialogComponent,
        Model3dComponent,
        Model3dDialogComponent,
        Model3dTableComponent,
        ModsComponent,
        ModDialogComponent,
        ModsDashboardComponent,
        ModTableComponent,
        MultipleUsersDialogComponent,
        NoteTableComponent,
        ProgressSpinnerComponent,
        ProjectComponent,
        ProjectCardComponent,
        ProjectDialogComponent,
        ProjectsDashboardComponent,
        ProjectOverviewTableComponent,
        ProjectTableComponent,
        ReportComponent,
        ReportItemDialogComponent,
        ReportOverviewTableComponent,
        SafePipe,
        ScanDialogComponent,
        ScanTableComponent,
        SettingsComponent,
        SettingsAppIntegrationsComponent,
        SettingsMaintenanceComponent,
        SettingsProjectComponent,
        SettingsSecurityComponent,
        SettingsSupportComponent,
        ShipComponent,
        ShipCardComponent,
        ShipDialogComponent,
        ShipListComponent,
        ShipTableComponent,
        ShipsDashboardComponent,
        SnapshotCardComponent,
        UnrealViewerComponent,
        UserComponent,
        UserCardComponent,
        UsersDashboardComponent,
        UserDialogComponent,
        UserSubscriptionsTableComponent,
        UserTableComponent,
        VehicleCardComponent,
        VehicleClearanceComponent,
        VehicleComponent,
        VehicleClearanceComponent,
        VehicleDialogComponent,
        VehicleListComponent,
        VehicleMetadataComponent,
        VehicleTableComponent,
        VehiclesDashboardComponent,
        VideoCardComponent,
        BackButtonDirective,
        TwoDigitDecimalNumberDirective,
    ],
    bootstrap: [AppComponent], imports: [AppOverlayModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        LayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatTooltipModule,
        NgIdleKeepaliveModule.forRoot(),
        NgxMaskModule.forRoot(),
        //SweetAlert2Module.forRoot(),
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule], providers: [
        LogService,
        {
            provide: OverlayContainer,
            useClass: FullscreenOverlayContainer,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
