import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { fromEvent, noop, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  DataSource,
  ImageDoc,
  Manufacturer,
  Model3d,
  Scan,
  Scanner,
  Ship,
  ShipClass,
  ShipDesignation,
  User,
} from '@shared/models';
import {
  DataSourceService,
  ErrorService,
  FileService,
  ManufacturerService,
  Model3dService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipService,
  ShipClassService,
  ShipDesignationService,
  UserService,
} from '@shared/services';
import { FileObjectTypesEnum, DbCollectionsEnum, TagFileTypesEnum, TagTypesEnum, UserRolesEnum } from '@shared/enums';
import { createTag, getUpdates, removeUndefinedProps } from '@shared/utils';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-ship-dialog',
    templateUrl: './ship-dialog.component.html',
    styleUrls: ['./ship-dialog.component.css'],
    standalone: false
})
export class ShipDialogComponent implements AfterViewInit {
  errorMsg: boolean;
  errorText = 'Please select a file';
  imageFileObj: File;
  imageURL: string; //for previewing file
  model3dFileObj: File;
  scanFileObj: File;
  form: UntypedFormGroup;
  decimationLevelForm: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  model3dForm: UntypedFormGroup;
  model3dParentForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  scanParentForm: UntypedFormGroup;
  shipInfoForm: UntypedFormGroup;
  currentUser: User;
  model3d: Model3d;
  ship: Ship;
  scan: Scan;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  isDebugging$: Observable<boolean>;
  manufacturers$: Observable<Manufacturer[]>;
  scanners$: Observable<Scanner[]>;
  shipClasses$: Observable<ShipClass[]>;
  shipClassDesignations$: Observable<ShipDesignation[]>;
  allowShipModels3d = true;
  allowShipScans = true;
  hasModel3dFileName = false;
  hasScanFileName = false;
  isNewShip = false;

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ShipDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      allowShipModels3d: boolean;
      allowShipScans: boolean;
      currentUser: User;
      isNewShip: boolean;
      model3d: Model3d;
      ship: Ship;
      scan: Scan;
    },
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private fileService: FileService,
    private manufacturerService: ManufacturerService,
    private model3dService: Model3dService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private userService: UserService
  ) {
    this.currentUser = data.currentUser;
    this.isNewShip = data.isNewShip;
    this.ship = data.ship;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.scanners$ = this.scannerService.manufacturerScanners$;
    this.shipClasses$ = this.shipClassService.shipClasses$;
    this.shipClassDesignations$ = this.shipDesignationService.shipClassDesignations$;

    this.shipInfoForm = fb.group({
      creatorId: [this.ship.creatorId, Validators.required],
      designationWithHullNumber: [this.ship.designationWithHullNumber, Validators.required],
      documentation: [this.ship.documentation],
      editorId: [this.currentUser._id, Validators.required],
      hullNumber: [this.ship.hullNumber, Validators.required],
      _id: [this.ship._id, Validators.required],
      name: [this.ship.name, Validators.required],
      shipClassId: [this.ship.shipClassId, Validators.required],
      shipDesignationId: [this.ship.shipDesignationId, Validators.required],
      shipId: [this.ship._id, Validators.required],
    });

    this.fileForm = fb.group({
      imageFileName: new UntypedFormControl(''),
      imageUrl: new UntypedFormControl(this.ship.imageUrl || environment.defaultImageUrl),
      imageBase64DataUrl: new UntypedFormControl(''),
      imageThumbnailUrl: new UntypedFormControl(this.ship.imageThumbnailUrl || environment.defaultThumbnailImageUrl),
      avatar: [null],
    });

    this.form = fb.group({
      fileForm: this.fileForm,
      shipInfoForm: this.shipInfoForm,
    });
  }

  ngAfterViewInit(): void { }

  get formTitle(): String {
    if (this.isNewShip) {
      return 'Create New Ship';
    } else {
      return `Edit Ship: ${this.ship.name}`;
    }
  }

  get imageFileName(): string {
    return this.fileForm.get('imageFileName').value || '';
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }
  
  get saveButtonText(): String {
    if (this.isNewShip) {
      return 'Create';
    } else {
      return 'Save';
    }
  }

  addShipClass(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Ship Class',
      html: `<input type="text" id="newShipClass" class="swal2-input" placeholder="Class" required>
      <textarea id="newShipClassDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
      confirmButtonText: 'Add Ship Class',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const shipClassName = Swal.getPopup().querySelector('#newShipClass').value;
        const description = Swal.getPopup().querySelector('#newShipClassDescription').value;

        if (!shipClassName) {
          Swal.showValidationMessage('Please enter ship class');
        }
        const shipClass: ShipClass = {
          class: shipClassName,
          creatorId: _this.currentUser._id,
        };

        if (description) {
          shipClass.description = description;
        }

        return shipClass;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.shipClassService
          .createNewShipClass(result.value, _this.currentUser)
          .then((newShipClass: ShipClass) => {
            //do nothing, will auto refresh classes
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(`Error creating new ship class: ${error.message}`);
            _this.errorMsg = true;
            _this.errorText = errMessage;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error`,
                `There was an error creating the new ship class.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    });
  }

  addShipDesignation(): void {
    const _this = this;
    const shipClassId = this.shipInfoForm.controls.shipClassId.value;
    const shipClass = this.shipClassService.getShipClassById(shipClassId, _this.currentUser);

    if (shipClass) {
      Swal.fire({
        title: 'Add Ship Designation',
        html: `<input type="text" id="newShipDesignation" class="swal2-input" placeholder="Designation" required>
        <textarea id="newShipDesignationDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
        confirmButtonText: 'Add Ship Designation',
        showCancelButton: true,
        focusConfirm: false,
        width: '50%',
        preConfirm: () => {
          const designation = Swal.getPopup().querySelector('#newShipDesignation').value;
          const description = Swal.getPopup().querySelector('#newShipDesignationDescription').value;

          if (!designation) {
            Swal.showValidationMessage('Please enter ship designation');
          }

          const des: ShipDesignation = {
            designation: designation,
            shipClasses: [shipClassId],
            creatorId: _this.currentUser._id,
          };

          if (description) {
            des.description = description;
          }

          return des;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.shipDesignationService
            .createNewShipDesignation(result.value, _this.currentUser)
            .then((newDesignation: ShipDesignation) => {
              //do nothing, designations with auto refresh
            })
            .catch((designationError) => {
              const errMessage = _this.errorService.handleError(
                `Error creating new ship designation: ${designationError.message}`
              );
              _this.errorMsg = true;
              _this.errorText = errMessage;
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `There was an error creating the new ship designation.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            });
        }
      });
    } else {
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error`,
          `There was an error loading the ship class information.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getShowShipSaveButton(role: string) {
    const isAdmin = role === UserRolesEnum.ADMIN;

    if (isAdmin && this.form.dirty && this.form.valid) {
      return true;
    } else {
      return false;
    }
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    this.imageFileObj = FILE;

    if (!this.imageFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select an image file';
    } else {
      this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.IMAGE));

      this.fileService
        .uploadFile(this.imageFileObj, FileObjectTypesEnum.SHIP, this.ship._id, tags, TagFileTypesEnum.IMAGE, this.currentUser)
        .then((results) => {
          //only user interaction sets the form as dirty, force it see https://github.com/angular/angular/issues/9768
          this.fileForm.markAsDirty();

          //add image preview, see https://www.positronx.io/angular-8-show-image-preview-with-reactive-forms-tutorial/
          this.fileForm.patchValue({
            avatar: FILE,
          });
          this.fileForm.get('avatar').updateValueAndValidity();

          const reader = new FileReader();
          reader.onload = () => {
            try {
              this.imageURL = reader.result as string;
            } catch (ex) {
              this.errorService.handleError(`Error previewing image ${ex}`);
            }
          };
          reader.readAsDataURL(FILE);

          const imageDoc: ImageDoc = {
            _id: results._id,
            creatorId: this.currentUser._id,
            displayThumbnailUrl: results.thumbnailDisplayUrl,
            displayUrl: results.locationDisplayUrl,
            isMainImage: true,
            isSnapshot: false,
            name: results.nameWithoutExtension,
            parent: {
              _id: this.ship._id,
              collection: DbCollectionsEnum.SHIPS,
            },
            thumbnailUrl: results.thumbnailUrl || environment.defaultThumbnailImageUrl,
            url: results.locationUrl,
          };

          this.shipService
            .addImage(imageDoc, this.ship, this.currentUser)
            .then((shipResults) => {
              this.settingsService.setIsLoading(false);
              this.errorMsg = false;
              this.errorText = '';

              this.fileForm.controls.imageUrl.setValue(results.locationUrl);
              this.fileForm.controls.imageUrl.markAsDirty();
              this.fileForm.controls.imageBase64DataUrl.setValue(results.locationBase64DataUrl);
              this.fileForm.controls.imageBase64DataUrl.markAsDirty();
              this.fileForm.controls.imageFileName.setValue(this.imageFileObj.name);
              this.fileForm.controls.imageFileName.markAsDirty();
              this.fileForm.controls.imageThumbnailUrl.setValue(results.thumbnailUrl);
              this.fileForm.controls.imageThumbnailUrl.markAsDirty();

              if (results.processing) {
                Swal.fire(
                  'File Upload in Process',
                  'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                  'info'
                );
              }
            })
            .catch((shipError) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = `Error adding image ${imageDoc._id} to ship ${_this.ship._id}: ${shipError}`;
              _this.errorMsg = true;
              _this.errorText = shipError.message;
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Adding Image',
                  `There was an error adding the ship image: ${shipError.message}. Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            })
            .finally(() => {
              _this.fileForm.updateValueAndValidity();
            });
        })
        .catch((uploadError) => {
          _this.settingsService.setIsLoading(false);
          _this.errorMsg = true;
          _this.errorText = uploadError.errMessage;
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error Uploading Image',
              `There was an error uploading the image: ${uploadError.message}.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        });
    }
  }

  onHullNumberChange() {
    const hullNumber = this.shipInfoForm.controls.hullNumber.value;

    this.shipDesignationService.shipClassDesignations$.subscribe((designations) => {
      const matching = designations.find((d) => d._id === this.shipInfoForm.controls.shipDesignationId.value);
      this.shipInfoForm.controls.designationWithHullNumber.setValue(
        matching ? `${matching.designation}-${hullNumber}` : ''
      );
    });
  }

  onShipClassChange(value) {
    this.shipDesignationService.refreshShipDesignationsByShipClass(value);
  }

  onShipDesignationChange(value) {
    this.shipDesignationService.shipClassDesignations$.subscribe((designations) => {
      const matching = designations.find((d) => d._id === value);
      this.shipInfoForm.controls.designationWithHullNumber.setValue(
        matching ? `${matching.designation}-${this.shipInfoForm.controls.hullNumber.value}` : ''
      );
    });
  }

  onManufacturerChange(value, currentUser: User) {
    this.scannerService.refreshScannersByManufacturer(value, currentUser);
  }

  save(currentUser): void {
    this.settingsService.setIsLoading(true);

    if (this.isNewShip) {
      let shipInfo = {
        ...this.fileForm.value,
        ...this.shipInfoForm.value,
      };

      this.shipService
        .createNewShip(shipInfo, currentUser)
        .then((newShip: Ship) => {
            this.errorMsg = false;
                this.errorText = '';
                this.settingsService.setIsLoading(false);
                this.close();
                this.router.navigate([`/ships/${newShip._id}`]);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          this.close();
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Creating Ship`,
              `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
              'error'
            );
          }
        });
    } else {
      let changes: any = {
        editorId: currentUser._id
      };

      const fileChanges = getUpdates(this.fileForm, changes);
      const shipInfoChanges = getUpdates(this.shipInfoForm, changes);

      changes = removeUndefinedProps(changes);
      const changedKeys = Object.keys(changes);

      //started with editorId as the key, only process changes if more than that key - jane 5/10/2024
      if (changedKeys.length > 1) {
        this.shipService
        .saveShip(this.shipInfoForm.controls._id.value, changes, currentUser)
        .then((updatedShip: Ship) => {
          this.close();
          const navigationUrl = `/ships/${this.shipInfoForm.controls._id.value}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          this.close();
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Updating Ship`,
              `There was an error updating the ship.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
      } else {
        this.settingsService.setIsLoading(false);
        this.close();
      }
    }
  }
}
