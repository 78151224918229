<div class="block-container-with-toolbar" *ngIf="currentUser$ | async as currentUser">
  <ng-container
    *ngIf="{
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Projects As">
        <mat-button-toggle
          mat-icon-button
          aria-label="View projects as cards"
          matTooltip="View projects as cards"
          value="cards"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          mat-icon-button
          aria-label="View projects as list"
          matTooltip="View projects as list"
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;&nbsp;&nbsp;
      <button class="image" 
      id="btnCreateProject"
      aria-label="Create new project"
      matTooltip="Create new project"
      (click)="createProject(currentUser)"
      ><span class="btnCreateProject"></span>CREATE NEW PROJECT</button>
    </mat-toolbar>
    <div>
      <h2 id="recentHeader">RECENT PROJECTS</h2>
      <mat-divider></mat-divider>
      <div *ngIf="recentProjects$ | async as recentProjects">
        <div class="grid" *ngIf="settings.viewFormat === 'cards'">
          <div *ngFor="let recentProject of recentProjects">
            <app-project-card [project]="recentProject"> </app-project-card>
          </div>
        </div>
        <div *ngIf="settings.viewFormat === 'list'">
          <app-project-table
            [projects]="recentProjects"
            [showPaginator]="false"
            [showFilter]="false"
          ></app-project-table>
        </div>
      </div>
      <br><br><br>
      <div *ngIf="userProjects$ | async as projects">
        <h2 id="libraryHeader">PROJECT LIBRARY</h2>
        <mat-divider></mat-divider>
        <div class="grid" *ngIf="settings.viewFormat === 'cards'">
          <div *ngFor="let project of projects">
            <app-project-card [project]="project"> </app-project-card>
          </div>
        </div>
        <div *ngIf="settings.viewFormat === 'list'">
          <app-project-table [projects]="projects" [showPaginator]="true" [showFilter]="true"></app-project-table>
        </div>
      </div>
    </div>
  </ng-container>
</div>
