import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, Report, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ProjectService,
  ReportService,
  SettingsService,
  UserService,
} from '@shared/services';
import { UserRolesEnum, ViewFormatsEnum } from '@shared/enums';

import { environment } from '@environment';

import { MultipleUsersDialogComponent } from '../multiple-users-dialog/multiple-users-dialog.component';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-users-dashboard',
    templateUrl: './users-dashboard.component.html',
    styleUrls: ['./users-dashboard.component.css'],
    standalone: false
})
export class UsersDashboardComponent implements OnInit {
  currentUser$: Observable<User>;
  isDebugging$: Observable<boolean>;
  selectedUsers$: Observable<User[]>;
  userProjects$: Observable<Project[]>;
  userReports$: Observable<Report[]>;
  users$: Observable<User[]>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private userService: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.users$ = this.userService.users$;
    this.selectedUsers$ = this.userService.selectedUsers$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());

    //reset users to edit
    this.userService.clearSelectedUsers();

    this.refresh();
  }

  ngOnDestroy(): void {
    this.userService.clearSelectedUsers();
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get isSelectedUsers(): boolean {
    const selectedUsers = this.userService.getSelectedUsers();
    return selectedUsers.length > 0 ? true : false;
  }

  createUser(currentUser: User): any {
    this.settingsService.setIsLoading(true);

    const user: User = {
      _id: new ObjectID().toString(),
      creatorId: currentUser._id,
      firstName: '',
      lastName: '',
      editorId: currentUser._id,
      email: '',
      imageUrl: '',
      password: environment.defaultPassword,
      phoneNumber: '',
      preferences: {
        viewFormat: ViewFormatsEnum.CARDS,
      },
      role: UserRolesEnum.USER,
      status: {
        online: false,
      },
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewUser: true,
      selectedUser: user,
    };

    this.settingsService.setIsLoading(false);
    const dialogRef = this.dialog
      .open(UserDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
      });
  }

  editSelectedUsers() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.userService.getCurrentUser(),
      selectedUsers: this.userService.getSelectedUsers(),
    };

    const dialogRef = this.dialog
      .open(MultipleUsersDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        this.refresh();
      });
  }

  getMultipleEditIsDisabled(): string {
    const selectedUsers = this.userService.getSelectedUsers();
    return selectedUsers.length > 0 ? '' : 'disabled';
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }

  //see https://stackoverflow.com/questions/46746598/angular-material-how-to-refresh-a-data-source-mat-table
  refresh() {
    this.userService.getUsers().subscribe((users) => {
      this.changeDetectorRefs.detectChanges();
    });
  }
}
