import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
		
		 
  ViewChild,
					
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { fromEvent, noop, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Mod, Model3d, Scan, User } from '@shared/models';
import {
  ErrorService,
  FileService,
  ModService,
  Model3dService,
  ScanService,
  SettingsService,
  UserService,
} from '@shared/services';
import { DbCollectionsEnum, FileObjectTypesEnum, ModStatesEnum, TagFileTypesEnum, TagTypesEnum } from '@shared/enums';
import { createTag } from '@shared/utils';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-mod-dialog',
    templateUrl: './mod-dialog.component.html',
    styleUrls: ['./mod-dialog.component.css'],
    standalone: false
})
export class ModDialogComponent implements AfterViewInit {
  errorMsg: boolean;
  errorText = 'Please select a file';
  panoFileObj: File;
  processedFileObj: File;
  siteFileObj: File;
  form: UntypedFormGroup;
  processedFileForm: UntypedFormGroup;
  panoFileForm: UntypedFormGroup;
  siteFileForm: UntypedFormGroup;
  isVehicle: boolean;
  mod: Mod;
  model3d: Model3d;
  panoMod: Mod;
  panoScan: Scan;
  scan: Scan;
  siteFileUrl: string;
  currentUser$: Observable<User>;
  hasPanoFileName = false;
  hasProcessedFileName = false;
  hasSiteFileName = false;
  currentUser: User;

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ModDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      isVehicle: boolean;
      mod: Mod;
      model3d: Model3d;
      panoMod: Mod;
      panoScan: Scan;
      scan: Scan;
    },
    private errorService: ErrorService,
    private fileService: FileService,
    private modService: ModService,
    private model3dService: Model3dService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private userService: UserService
  ) {
    this.currentUser = data.currentUser;
    this.isVehicle = data.isVehicle;
    this.mod = data.mod;
    this.model3d = data.model3d;
    this.panoMod = data.panoMod;
    this.panoScan = data.panoScan;
    this.scan = data.scan;
    this.currentUser$ = this.userService.currentUser$;

    this.panoFileForm = fb.group({
      url: new UntypedFormControl(this.panoMod?.url || ''),
    });

    this.processedFileForm = fb.group({
      url: new UntypedFormControl(this.mod.url, Validators.required),
    });

    this.siteFileForm = fb.group({
      siteFileUrl: new UntypedFormControl(this.scan?.siteFileUrl || ''),
    });

    if (this.scan && this.scan.parent?.collection === DbCollectionsEnum.SHIPS) {
      if (this.scan.panoramic?.name) {
        this.panoFileForm.controls.url.setValidators([Validators.required]);
      }
      this.siteFileForm.controls.siteFileUrl.setValidators([Validators.required]);
    }

    this.form = fb.group({
      panoFileForm: this.panoFileForm,
      processedFileForm: this.processedFileForm,
      siteFileForm: this.siteFileForm
    });
  }

  ngAfterViewInit(): void { }

  get formTitle(): String {
    if (this.model3d) {
      return `Upload Processed Mod Zip File for the 3D Model: ${this.model3d.name}`;
    } else if (this.scan) {
      return `Upload Processed Point Cloud and Pano Mod Zip Files and Related Site File for the Scan: ${this.scan.name}`;
    } else {
      return '';
    }
  }

  get hasPanoMod(): Boolean {
    return this.panoMod != null;
  }
  
  get needSiteFile(): Boolean {
    let returnValue = true;
    
    if (this.isVehicle) {
      returnValue = false;
    }

    return returnValue;
  }

  get panoFileName(): String {
    let returnValue = '';

    if (this.panoMod) {
      returnValue = `${this.panoMod.name}.zip`;
    }

    return returnValue;
  }

  get pointCloudFileName(): String {
    let returnValue = '';

    if (this.mod) {
      returnValue = `${this.mod.name}.zip`;
    }

    return returnValue;
  }

  get siteFileName(): String {
    return this.scan ? this.scan.siteFileName : '';
  }

  cancel(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  async panoFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    _this.panoFileObj = FILE;

    if (!_this.panoFileObj) {
      _this.errorMsg = true;
      _this.errorText = 'Please select a zip file';
    } else {
      const selectedFileName = _this.panoFileObj.name;
      if (_this.panoMod && !selectedFileName.startsWith(_this.panoMod.name)) {
        _this.errorMsg = true;
        _this.errorText = `Incorrect file name.  Please select a zip file with the name ${_this.panoMod.name}`;
      } else {
        _this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.MOD));

      _this.fileService
        .uploadFile(_this.panoFileObj, FileObjectTypesEnum.MOD, _this.panoMod._id, tags, TagFileTypesEnum.MOD, _this.currentUser)
        .then((results) => {
          _this.panoFileForm.controls.url.setValue(results.locationUrl);
          _this.settingsService.setIsLoading(false);
        })
        .catch((uploadError) => {
          _this.settingsService.setIsLoading(false);
          _this.errorMsg = true;
          _this.errorText = uploadError.errMessage;
          _this.close();
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error Uploading Processed Panoramic Mod File',
              `There was an error uploading the processed mod zip file.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        });
      }
    }
  }

  async processedFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    _this.processedFileObj = FILE;

    if (!_this.processedFileObj) {
      _this.errorMsg = true;
      _this.errorText = 'Please select a zip file';
    } else {
      const selectedFileName = _this.processedFileObj.name;
      if (_this.mod && !selectedFileName.startsWith(_this.mod.name)) {
        _this.errorMsg = true;
        _this.errorText = `Incorrect file name.  Please select a zip file with the name ${_this.mod.name}`;
      } else {
        _this.settingsService.setIsLoading(true);
        const tags = [];
        tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.MOD));
  
        _this.fileService
          .uploadFile(_this.processedFileObj, FileObjectTypesEnum.MOD, _this.mod._id, tags, TagFileTypesEnum.MOD, _this.currentUser)
          .then((results) => {
            _this.processedFileForm.controls.url.setValue(results.locationUrl);
            _this.settingsService.setIsLoading(false);
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorMsg = true;
            _this.errorText = uploadError.errMessage;
            _this.close();
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Processed Mod File',
                `There was an error uploading the processed mod zip file.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    }
  }

  save(currentUser): void {
    const _this = this;
    _this.settingsService.setIsLoading(true);
    const promises = [];

    const model3dChanges = {
      editorId: currentUser._id
    }

    const model3dModChanges = {
      editorId: currentUser._id,
      modState: _this.isVehicle ? ModStatesEnum.ADJUSTMENT_REQUIRED : ModStatesEnum.VALID,
      url: _this.processedFileForm.controls.url.value
    }

    const pointCloudModChanges = {
      editorId: currentUser._id,
      modState: _this.isVehicle ? ModStatesEnum.ADJUSTMENT_REQUIRED : ModStatesEnum.VALID,
      url: _this.processedFileForm.controls.url.value
    };

    const pointCloudScanChanges = {
      editorId: currentUser._id,
      siteFileUrl: _this.siteFileForm.controls.siteFileUrl.value,
    }

    //save 3d model / scan and then mod so that the mod state gets picked up correctly
    if (_this.model3d) {
      promises.push(_this.model3dService.saveModel3d(_this.model3d._id, _this.model3d.parent.collection, _this.model3d.parent._id, model3dChanges, currentUser));
      promises.push(_this.modService.saveMod(_this.mod._id, _this.model3d.parent.collection, _this.mod.parent._id, model3dModChanges, currentUser));
    } else if (_this.scan) {
      promises.push(_this.scanService.saveScan(_this.scan._id, _this.scan.parent.collection, _this.scan.parent._id, pointCloudScanChanges, currentUser ));
      promises.push(_this.modService.saveMod(_this.mod._id, _this.mod.parent.collection, _this.mod.parent._id, pointCloudModChanges, currentUser));
    }

    if (!_this.isVehicle && _this.panoMod && _this.panoScan && _this.panoFileForm.controls.url.value) {
      const panoModChanges = {
        editorId: currentUser._id,
        modState: ModStatesEnum.VALID,
        url: _this.panoFileForm.controls.url.value
      };

      const panoScanChanges = {
        editorId: currentUser._id,
        siteFileUrl: _this.siteFileForm.controls.siteFileUrl.value,
      }

      promises.push(_this.scanService.saveScan(_this.panoScan._id, _this.panoScan.parent.collection, _this.panoScan.parent._id, panoScanChanges, currentUser));
      promises.push(_this.modService.saveMod(_this.panoMod._id, _this.panoMod.parent.collection, _this.panoMod.parent._id, panoModChanges, currentUser));
    }
    
    Promise.allSettled(promises)
    .then((results) => {
      const errors = [];
      let isProcessing = false;

      results.map((r) => {
        if (r.status === 'rejected') {
          errors.push(r.reason);
        } else {
          if (r.value.isProcessing) {
            isProcessing = true;
          }
        }

        _this.settingsService.setIsLoading(false);

        if (errors.length > 0) {
          _this.errorMsg = true;
          _this.errorText = errors.join(',');
          _this.close();

          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire( {
              title: 'Error Uploading File(s)',
              html: `There were one or more error uploading your files:<br><br>${errors.join(',')}.<br><br>Please email ${environment.techSupportEmail}.`,
              icon: 'error'
            });
          }
        } else {
          _this.errorMsg = false;
          _this.errorText = '';

          if (isProcessing) {
            Swal.fire(
              'File Upload in Process',
              'Due to the large file size, your file(s) are being uploaded in the background.  You will be emailed a success or failure message upon completion.',
              'info'
            );
          } else {
            Swal.fire(
              'Success',
              `The asset file has been uploaded and ${environment.techSupportEmail} has been asked to push it to the ${environment.unreal.viewerName} server.  You will be notified when that process is complete.`,
              'success'
            );
          }

          //refresh mods
          _this.modService.getMods(currentUser);
          _this.close();
        }
      });
    });
  }

  async siteFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    _this.siteFileObj = FILE;

    if (!_this.siteFileObj) {
      _this.errorMsg = true;
      _this.errorText = 'Please select a JSON file';
    } else {
      const selectedFileName = _this.siteFileObj.name;
      if (_this.scan && selectedFileName !== _this.scan.siteFileName) {
        _this.errorMsg = true;
        _this.errorText = `Incorrect file name.  Please select a JSON file with the name ${_this.scan.siteFileName}`;
      } else {
        _this.settingsService.setIsLoading(true);
        const tags = [];
        tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.SITE));
  
        _this.fileService
          .uploadFile(_this.siteFileObj, FileObjectTypesEnum.SITE, _this.scan._id, tags, TagFileTypesEnum.SITE, _this.currentUser)
          .then((results) => {
            _this.siteFileForm.controls.siteFileUrl.setValue(results.locationUrl);
            _this.settingsService.setIsLoading(false);
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorMsg = true;
            _this.errorText = uploadError.errMessage;
            _this.close();
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Processed Site File',
                `There was an error uploading the site JSON file.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    }
  }
}
