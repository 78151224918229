import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { ActivitySubscription, Project, Ship, UsedInProject, User, Vehicle } from '@shared/models';
import {
  ErrorService,
  LogService,
  ProjectService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService
} from '@shared/services';
import { UserRolesEnum, UserSubscriptionTypesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-user-subscriptions-table',
    templateUrl: './user-subscriptions-table.component.html',
    styleUrls: ['./user-subscriptions-table.component.css'],
    standalone: false
})
export class UserSubscriptionsTableComponent implements OnInit {
  @Input()
  selectedUser: User;

  @Input()
  userSubscriptions: ActivitySubscription[];

  @Input()
  showFilter: boolean;

  @Input()
  showPaginator: boolean;

  @Output() public modifiedUserSubscription: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  selectedUser$: Observable<User>;
  userActivitySubscriptions$: Observable<ActivitySubscription[]>;
  userProjectSubscriptions$: Observable<UsedInProject[]>;
  userShipSubscriptions$: Observable<UsedInProject[]>;
  userVehicleSubscriptions$: Observable<UsedInProject[]>;
  displayedSubscriptionsColumns: string[] = ['subscriptionType', 'name', 'actions'];
  private userSubscriptionsErrorSubject = new BehaviorSubject<string>(null);
  userModifiedActivitySubscriptionIds$: Observable<string[]>;
  userSubscriptionsError$: Observable<string> = this.userSubscriptionsErrorSubject.asObservable();
  dataSource;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.selectedUser$ = this.userService.selectedUser$;
    this.userActivitySubscriptions$ = this.userService.selectedUserActivitySubscriptions$;
    this.userModifiedActivitySubscriptionIds$ = this.userService.selectedUserModifiedActivitySubscriptionIds$;
    this.dataSource = new MatTableDataSource(this.userSubscriptions);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.userActivitySubscriptions$.subscribe((subs) => {
      if (Array.isArray(subs)) {
        this.dataSource = new MatTableDataSource(subs);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  async ngOnDestroy(): Promise<any> {
    try {
      await this.checkForChanges();
    } catch (ex) {
      //do nothing, handled in method
    }
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get madeChanges(): boolean {
    return this.userService.getSelectedUserModifiedActivitySubscriptionIds().length > 0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async checkForChanges(): Promise<any> {
    return new Promise((resolve, reject) => {
      const _this = this;
      const currentUser = _this.userService.getCurrentUser();
      const selectedUser = _this.userService.getSelectedUser();

      if (_this.userService.getSelectedUserModifiedActivitySubscriptionIds().length > 0 && currentUser && selectedUser) {

        Swal.fire({
          title: 'Save Subscription Changes?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            _this.settingsService.setIsLoading(true);
            _this.userService.updateSelectedUserSubscriptionsToMatchActivitySubscriptions(currentUser._id, selectedUser._id, _this.userService.getSelectedUserActivityNotifications())
              .then((updatedUser) => {
                //do nothing, user updated in service
              })
              .catch((error) => {
                _this.settingsService.setIsLoading(false);

                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    `Error Updating User Subscriptions`,
                    `There was an error updating the subscriptions.  Please email ${environment.techSupportEmail}.`,
                    'error'
                  );
                }
              })
              .finally(() => {
                _this.settingsService.setIsLoading(false);
                resolve('checked for changes');
              });
          }
        });

      } else {
        resolve('no changes made');
      }
    });
  }

  getRowClass(id: string): string {
    let returnValue = '';
    const modifiedSubscriptionIds = this.userService.getSelectedUserModifiedActivitySubscriptionIds();

    if (modifiedSubscriptionIds.length > 0) {
      const idx = modifiedSubscriptionIds.indexOf(id);

      if (idx > -1) {
        returnValue = 'modified-row';
      }
    }

    return returnValue;
  }

  getSubscriptionTypeDisplayName(subscriptionType: string): string {
    let returnValue = '';

    switch (subscriptionType) {
      case UserSubscriptionTypesEnum.PROJECTS:
        returnValue = 'Project';
        break;
      case UserSubscriptionTypesEnum.SHIPS:
        returnValue = 'Ship'
        break;
      case UserSubscriptionTypesEnum.VEHICLES:
        returnValue = 'Vehicle';
        break;
    }

    return returnValue;
  }

  public onModifiedUserSubscription(id: string) {
    this.modifiedUserSubscription.emit(id);
  }

  toggleSubscription(selected: boolean, subscriptionToToggle: ActivitySubscription): void {
    try {
      this.userService.toggleUserSubscription(selected, subscriptionToToggle);
    } catch (ex) {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error`,
          `Error toggling the user subscription.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
      }
    }
  }

  async viewSubscribedObject(subscribedObject: ActivitySubscription): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const _this = this;
      const promises = [];

      try {
        await _this.checkForChanges();
        _this.settingsService.setIsLoading(true);
        promises.push(_this.userService.clearSelectedUsers());
        //this will clear any selected project, ship or vehicle just in case it's not cleared
        promises.push(_this.projectService.getProjectById(null, this.selectedUser));

        await Promise.allSettled(promises)
          .then((results) => {
            switch (subscribedObject.subscriptionType) {
              case UserSubscriptionTypesEnum.PROJECTS:
                _this.projectService
                  .getProjectById(subscribedObject._id, this.selectedUser)
                  .then((p: Project) => {
                    const navigationUrl = `/projects/${subscribedObject._id}`;
                    this.router.navigateByUrl(navigationUrl);
                  })
                  .catch((error) => {
                    this.settingsService.setIsLoading(false);
                    if (this.settingsService.getShowPopupErrorMessages()) {
                      Swal.fire(
                        `Error`,
                        `Error getting project information.  Please email ${environment.techSupportEmail}.`,
                        'error'
                      );
                    }
                  })
                  .finally(() => {
                    this.settingsService.setIsLoading(false);
                  });
                break;
              case UserSubscriptionTypesEnum.SHIPS:
                _this.shipService
                  .getShipById(subscribedObject._id, this.selectedUser)
                  .then((s: Ship) => {
                    const navigationUrl = `/ships/${subscribedObject._id}`;
                    this.router.navigateByUrl(navigationUrl);
                  })
                  .catch((error) => {
                    this.settingsService.setIsLoading(false);
                    if (this.settingsService.getShowPopupErrorMessages()) {
                      Swal.fire(
                        `Error`,
                        `Error getting ship information.  Please email ${environment.techSupportEmail}.`,
                        'error'
                      );
                    }
                  })
                  .finally(() => {
                    this.settingsService.setIsLoading(false);
                  });
                break;
              case UserSubscriptionTypesEnum.VEHICLES:
                _this.vehicleService
                  .getVehicleById(subscribedObject._id, this.selectedUser)
                  .then((v: Vehicle) => {
                    const navigationUrl = `/vehicles/${subscribedObject._id}`;
                    this.router.navigateByUrl(navigationUrl);
                  })
                  .catch((error) => {
                    this.settingsService.setIsLoading(false);
                    if (this.settingsService.getShowPopupErrorMessages()) {
                      Swal.fire(
                        `Error`,
                        `Error getting vehicle information.  Please email ${environment.techSupportEmail}.`,
                        'error'
                      );
                    }
                  })
                  .finally(() => {
                    this.settingsService.setIsLoading(false);
                  });
                break;
            }
          });
      } catch (ex) {
        _this.settingsService.setIsLoading(false);

        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `Error display the subscribed object.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    })
  }
}
