import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';

import { Measurement, Project, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  private currentMeasurementSubject = new BehaviorSubject<Measurement>(null);
  private currentProjectMeasurementsSubject = new BehaviorSubject<Measurement[]>(null);
  private measurementErrorSubject = new BehaviorSubject<string>(null);
  currentProjectMeasurements$: Observable<Measurement[]> = this.currentProjectMeasurementsSubject.asObservable();
  measurementError$: Observable<string> = this.measurementErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewMeasurement(payload: Measurement, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload && project) {
        try {
          const measurements = _this.currentProjectMeasurementsSubject.getValue();
          const newMeasurements = measurements ? measurements.slice(0) : [];
          const url = `${environment.baseAPIUrl}measurement/create?userId=${currentUser?._id}`;
          let returnValue: Measurement;
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newMeasurement: Measurement) => {
                returnValue = newMeasurement;
                newMeasurements.push(newMeasurement);
                _this.currentProjectMeasurementsSubject.next(newMeasurements);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.measurementErrorSubject.next(error.error);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } catch (ex) {
          const errMessage = `Error creating new measurement for projectId ${project._id} parentId: ${ex.message}`;
          _this.measurementErrorSubject.next(errMessage);
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, project and user are required to create a measurement`;
        _this.errorService.handleError(errMessage);
        _this.measurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteMeasurement(measurementId: string, projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Measurement[];

      if (currentUser && measurementId && projectId) {
        try {
          const url = `${environment.baseAPIUrl}measurement/${measurementId}?userId=${currentUser._id}`;

          _this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (deletedMeasurement: Measurement) => {
                console.log(`successfully deleted measurementId ${measurementId}`);
                _this.refreshMeasurementsByProject(projectId, currentUser)
                  .then((measurements) => {
                    returnValue = measurements;
                    resolve(returnValue);
                  })
                  .catch((measurementsError) => {
                    const errMessage = `Error refreshing measurements for projectId ${projectId}: ${measurementsError}`;
                    _this.errorService.handleError(errMessage);
                    _this.measurementErrorSubject.next(errMessage);
                    resolve(returnValue);
                  });
              }, 
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.measurementErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = `Error removing measurementId ${measurementId} from projectId ${projectId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.measurementErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `measurementId and projectId are required to delete a collision`;
        _this.errorService.handleError(errMessage);
        _this.measurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentProjectMeasurements() {
    return this.currentProjectMeasurementsSubject.getValue();
  }

  async refreshMeasurementsByProject(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Measurement[];

      if (currentUser && projectId) {
        const url = `${environment.baseAPIUrl}measurement/${DbCollectionsEnum.PROJECTS}/${projectId}?userId=${currentUser?._id}`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (measurements: Measurement[]) => {
              returnValue = measurements;
              _this.currentProjectMeasurementsSubject.next(measurements);
              _this.measurementErrorSubject.next('');
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage= _this.errorService.handleError(error);
              _this.measurementErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentProjectMeasurementsSubject.next(returnValue);
        _this.measurementErrorSubject.next('');
        resolve(returnValue);
      }
    });
  }

  async saveMeasurement(measurementId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && measurementId) {
        const measurements = _this.currentProjectMeasurementsSubject.getValue();
        const measurementIndex = measurements.findIndex((measurement) => measurement._id === measurementId);
        const newMeasurements = measurements.slice(0);
        const url = `${environment.baseAPIUrl}measurement/${measurementId}?userId=${currentUser?._id}`;
        let returnValue: Measurement;
  
        newMeasurements[measurementIndex] = {
          ...measurements[measurementIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedMeasurement: Measurement) => {
              returnValue = updatedMeasurement;
              _this.currentMeasurementSubject.next(updatedMeasurement);
              _this.currentProjectMeasurementsSubject.next(newMeasurements);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage= _this.errorService.handleError(error);
              _this.measurementErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `changes, measurementId and user are required to update a measurement`;
        _this.errorService.handleError(errMessage);
        _this.measurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
