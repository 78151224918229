import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Model3d, Ship, User, Vehicle, UnrealServer } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  Model3dService,
  ProjectService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, ModStatesEnum, UnrealScenesEnum, UnrealUIStatesEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { Model3dDialogComponent } from '../model3d-dialog/model3d-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-model3d-table',
    templateUrl: './model3d-table.component.html',
    styleUrls: ['./model3d-table.component.css'],
    standalone: false
})
export class Model3dTableComponent implements OnInit {
  @Input()
  models3d: Model3d[];

  @Input()
  parentShip: Ship;

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @Input()
  parentVehicle: Vehicle;

  @Input()
  currentUser: User;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(Model3dDialogComponent) model3dDialog;

  currentUser$: Observable<User>;

  displayedModel3dColumns: string[] = [
    'name',
    'modelDate',
    'classification',
    'fidelityType',
    'dataSourceName',
    'nameOfPersonModeling',
    'description',
    'actions',
  ];
  updatedModel3dId$: Observable<string>;
  isOpeningUnrealViewer: boolean;
  isReadOnly: boolean;
  private model3dErrorSubject = new BehaviorSubject<string>(null);
  model3dError$: Observable<string> = this.model3dErrorSubject.asObservable();
  model3dForm: UntypedFormGroup;
  dataSource;

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private model3dService: Model3dService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const isAdmin = this.currentUser && this.currentUser.role === UserRolesEnum.ADMIN;
    this.isReadOnly = isAdmin ? false : true;
    this.dataSource = new MatTableDataSource(this.models3d);

    this.model3dService.updatedModel3dId$.pipe(untilDestroyed(this)).subscribe({
      next: (event) => {
        if (this.parentShip) {
          this.dataSource.data = this.model3dService.getCurrentShipModels3d();
        } else {
          this.dataSource.data = this.model3dService.getCurrentVehicleModels3d();
        }

        if (this.table) {
          this.table.renderRows();
        }
      },
      error: (error) => console.error(`Error on updatedModel3dId subscription: ${error}`),
      complete: () => {
        console.log(`completed updatedModel3dId subscription`);
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get freeRoamTooltip(): string {
    if (this.parentShip) {
      return 'Open Ship Viewer';
    } else if (this.parentVehicle) {
      return 'Open Vehicle Viewer';
    } else {
      return 'Open Viewer';
    }
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  canDelete(model3d: Model3d): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === model3d.creatorId) {
      let projectCount = (model3d.usedInProjectsAsShipModel3d ? model3d.usedInProjectsAsShipModel3d.length: 0)  
      + (model3d.usedInProjectsAsVehicleModel3d ? model3d.usedInProjectsAsVehicleModel3d.length : 0);
      if (projectCount === 0) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  deleteModel3d(model3d: Model3d): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    _this.model3dService
      .getModel3dById(model3d._id, model3d.parent.collection, model3d.parent._id, currentUser)
      .then((m: Model3d) => {
        Swal.fire({
          title: `Delete 3D Model:  ${model3d.name}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            _this.model3dService
              .deleteModel3d(model3d._id, model3d.parent.collection, model3d.parent._id, currentUser)
              .then((models3d: Model3d[]) => {
                //console.log(`sucessefully deleted model3dId ${model3d._id}`);
              })
              .catch((error) => {
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error Deleting 3D Model',
                    `There was an error deleting the 3D model.  Please email ${environment.techSupportEmail}`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((modelError) => {
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `Error getting the 3D model information required to delete.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      });
  }

  edit3dModel(model3d: Model3d) {
    const _this = this;
    const currentUser = _this.userService.getCurrentUser();
    this.settingsService.setIsLoading(true);
    const promises = [];

    this.model3dService
      .getModel3dById(model3d._id, model3d.parent.collection, model3d.parent._id, currentUser)
      .then((m: Model3d) => {
        if (this.parentShip) {
          promises.push(this.shipService.setShipModel3d(this.parentShip, model3d, currentUser));
          promises.push(
            this.modService.getModById(model3d ? model3d.modId : null, DbCollectionsEnum.SHIPS, this.parentShip._id,  _this.currentUser)
          );

          Promise.allSettled(promises).then((results) => {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              currentUser: this.currentUser,
              isNewModel: false,
              model3d: model3d,
              parentShip: this.parentShip,
            };

            this.settingsService.setIsLoading(false);
            const dialogRef = this.dialog.open(Model3dDialogComponent, dialogConfig);
          });
        } else if (this.parentVehicle) {
          promises.push(this.vehicleService.setVehicleModel3d(this.parentVehicle, model3d, this.currentUser));
          promises.push(
            this.modService.getModById(
              model3d ? model3d.modId : null,
              DbCollectionsEnum.VEHICLES,
              this.parentVehicle._id,
              this.currentUser
            )
          );

          Promise.allSettled(promises).then((results) => {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              currentUser: this.currentUser,
              isNewModel: false,
              model3d: model3d,
              parentVehicle: this.parentVehicle,
              table: this.table,
            };

            this.settingsService.setIsLoading(false);
            const dialogRef = this.dialog.open(Model3dDialogComponent, dialogConfig);
          });
        } else {
          _this.settingsService.setIsLoading(false);
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `A ship or vehicle is required to edit a 3D model.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      })
      .catch((model3dError) => {
        _this.settingsService.setIsLoading(false);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `A ship or vehicle is required to edit a 3D model.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  getHasValidMod(model3d: Model3d): boolean {
    let returnValue = false;

    if (model3d?.modDetails?.modState !== ModStatesEnum.IN_PROCESSING) {
      returnValue = true;
    }
  
    return returnValue;
  }

  async openViewer(model3d: Model3d) {
    const _this = this;
    this.settingsService.setIsLoading(true);
    let navigationUrl;

    if (this.parentShip || this.parentVehicle) {
      try {
        const modToUse = await this.modService.getModById(
          model3d.modId,
          model3d.parent.collection,
          model3d.parent._id,
          this.currentUser
        );

        if (this.parentShip) {
          navigationUrl = `/ships/${this.parentShip._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.FREE_ROAM}`;

          if (modToUse) {
            navigationUrl += `&pcName=${modToUse.name}`;
          }
        } else if (this.parentVehicle) {
          navigationUrl = `/vehicles/${this.parentVehicle._id}/viewer?sceneName=${UnrealScenesEnum.CALIBRATION}&uiState=${UnrealUIStatesEnum.DYNAMIC_ASSET_CALIBRATION}`;

          if (modToUse) {
            navigationUrl += `&vehicleName=${modToUse.name}`;
          }
        }

        this.router
          .navigateByUrl(navigationUrl)
          .then(() => {
            this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
          })
          .catch((unrealError) => {
            _this.settingsService.setIsLoading(false);
            const errMessage = this.errorService.handleError(
              `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
            );
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Opening ${environment.unreal.viewerName}`,
                `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
            _this.settingsService.setLoadingId(null);
          });
      } catch (ex) {
        _this.settingsService.setIsLoading(false);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error loading the ${environment.unreal.viewerName}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    }
  }
}
