import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { User } from '@shared/models';
import { ErrorService, SettingsService, UserService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-multiple-users-dialog',
    templateUrl: './multiple-users-dialog.component.html',
    styleUrls: ['./multiple-users-dialog.component.css'],
    standalone: false
})
export class MultipleUsersDialogComponent implements AfterViewInit {
  currentUser: User;
  currentUser$: Observable<User>;
  errorMsg: boolean;
  isDebugging$: Observable<boolean>;
  selectedUsers: User[];
  users$: Observable<User[]>;

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<MultipleUsersDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      selectedUsers: User[];
    },
    private errorService: ErrorService,
    private settingsService: SettingsService,
    private userService: UserService
  ) {
    this.currentUser = data.currentUser;
    this.selectedUsers = data.selectedUsers;

    this.currentUser$ = this.userService.currentUser$;
    this.users$ = this.userService.users$;
    this.isDebugging$ = this.settingsService.isDebugging$;
  }

  ngAfterViewInit(): void { }

  get isAdmin(): boolean {
    return this.currentUser && this.currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get selectedUsersNames(): string {
    let names = [];

    this.selectedUsers.map((user) => {
      names.push(user.fullName);
    });

    return names.join(',');
  }

  get userRoles(): string[] {
    const returnValue = [UserRolesEnum.ADMIN, UserRolesEnum.USER];
    return returnValue;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  deleteSelectedUsers(): void {
    const _this = this;
    Swal.fire({
      title: `Delete Selected Users:  ${this.selectedUsersNames}?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        const promises = [];

        this.selectedUsers.map((user) => {
          promises.push(this.userService.deleteUser(user._id));
        });

        Promise.allSettled(promises).then((results) => {
          let hasErrors = false;

          results.forEach((result) => {
            if (result.status !== 'fulfilled') {
              hasErrors = true;
            }
          });

          if (hasErrors) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `On or more errors occurred when attempting to deleted the selected users. Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          } else {
            this.dialogRef.close();
            this.userService.clearSelectedUsers();
            this.userService.getUsers();
            this.router.navigate(['/users']);
          }
        });
      }
    });
  }

  onUserRoleChange(value) {
    const _this = this;
    Swal.fire({
      title: `Change User Type to ${value}?`,
      text: `Change the user type to ${value} for these users? ${this.selectedUsersNames}?`,
      showCancelButton: true,
      confirmButtonText: 'Apply Changes',
    }).then((result) => {
      if (result.isConfirmed) {
        const promises = [];

        const changes = {
          editorId: this.currentUser._id,
          role: value,
        };

        this.selectedUsers.map((user) => {
          promises.push(this.userService.saveUser(user._id, changes));
        });

        Promise.allSettled(promises).then((results) => {
          let hasErrors = false;

          results.forEach((result) => {
            if (result.status !== 'fulfilled') {
              hasErrors = true;
            }
          });

          if (hasErrors) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `On or more errors occurred when attempting to change the type the selected users. Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          } else {
            this.dialogRef.close();
            this.userService.clearSelectedUsers();
            this.userService.getUsers();
            this.router.navigate(['/users']);
          }
        });
      }
    });
  }
}
