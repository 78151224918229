import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Model3d, Scan, Ship, User } from '@shared/models';
import { ErrorService, LogService, SettingsService, ShipService, UserService } from '@shared/services';
import { DbCollectionsEnum, ScanDisplayTypesEnum, ScanTypesEnum, UserRolesEnum } from '@shared/enums';

import { ShipDialogComponent } from '../ship-dialog/ship-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-ships-dashboard',
    templateUrl: './ships-dashboard.component.html',
    styleUrls: ['./ships-dashboard.component.css'],
    standalone: false
})
export class ShipsDashboardComponent implements OnInit {
  currentUser$: Observable<User>;
  recentShips$: Observable<Ship[]>;
  ships$: Observable<Ship[]>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  modSourceCollection = DbCollectionsEnum.SCANS;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.ships$ = this.shipService.ships$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  async uploadNewShip(currentUser: User): Promise<any> {
    const allowShipModels3d = this.shipService.getAllowShipModels3d();
    const allowShipScans = this.shipService.getAllowShipScans();
    let modSourceCollection: DbCollectionsEnum;

    const ship: Ship = {
      _id: new ObjectID().toString(),
      creatorId: currentUser._id,
      designationWithHullNumber: null,
      editorId: currentUser._id,
      hasValidMod: false,
      hullNumber: null,
      images: [],
      name: null,
      modSources: {
        models3d: [],
        scans: [],
      },
      notes: [],
      shipClassId: null,
      shipDesignationId: null,
      siteFileName: '',
      userIdsToSendChangeNotificationsTo: [currentUser._id],
      videos: [],
    };

    if (allowShipModels3d && allowShipScans) {
      const inputOptions = {
        models3d: '3D Model',
        scans: 'Scan',
      };

      const { value: modSourceCollection } = await Swal.fire({
        title: 'Select Source Type',
        input: 'radio',
        inputOptions: inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return 'Please select a source type';
          }
        },
      });
    } else {
      if (allowShipModels3d) {
        modSourceCollection = DbCollectionsEnum.MODELS3D;
      } else if (allowShipScans) {
        modSourceCollection = DbCollectionsEnum.SCANS;
      }
    } //only one option allowed

    if (modSourceCollection) {
      console.log(`selected ${modSourceCollection}`);
      this.modSourceCollection = modSourceCollection;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const modSourceId = new ObjectID().toString();

      dialogConfig.data = {
        allowShipModels3d: allowShipModels3d,
        allowShipScans: allowShipScans,
        currentUser: currentUser,
        isNewShip: true,
        ship: ship,
      };

      switch (this.modSourceCollection) {
        case DbCollectionsEnum.MODELS3D:
          ship.modSources.models3d.push(modSourceId);

          const model3d: Model3d = {
            _id: modSourceId,
            classification: 'Unclass',
            creatorId: currentUser._id,
            editorId: currentUser._id,
            fidelityType: 'Engineering',
            name: '',
            nameOfPersonModeling: '',
            parent: {
              _id: ship._id,
              collection: DbCollectionsEnum.SHIPS,
            },
          };

          dialogConfig.data.model3d = model3d;

          break;
        case DbCollectionsEnum.SCANS:
          ship.modSources.scans.push(modSourceId);
          const scan: Scan = {
            _id: modSourceId,
            classification: 'Unclass',
            creatorId: currentUser._id,
            editorId: currentUser._id,
            name: '',
            parent: {
              _id: ship._id,
              collection: DbCollectionsEnum.SHIPS,
            },
            scanDisplayType: ScanDisplayTypesEnum.POINT_CLOUD,
            scanType: ScanTypesEnum.RAW,
            siteFileName: ''
          };

          dialogConfig.data.scan = scan;

          break;
      }

      const dialogRef = this.dialog.open(ShipDialogComponent, dialogConfig);
    }
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }
}
