import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Model3d, Scan, User, Vehicle, WheelbaseMeasurement } from '@shared/models';
import { ErrorService, LogService, SettingsService, UserService, VehicleService } from '@shared/services';
import { DbCollectionsEnum, ScanDisplayTypesEnum, ScanTypesEnum, UnknownSteeringType, UserRolesEnum, WheeledVehicleMovement } from '@shared/enums';

import { VehicleDialogComponent } from '../vehicle-dialog/vehicle-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-vehicles-dashboard',
    templateUrl: './vehicles-dashboard.component.html',
    styleUrls: ['./vehicles-dashboard.component.css'],
    standalone: false
})
export class VehiclesDashboardComponent implements OnInit {
  modSourceCollection = DbCollectionsEnum.MODELS3D;

  currentUser$: Observable<User>;
  vehicles$: Observable<Vehicle[]>;
  recentVehicles$: Observable<Vehicle[]>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.vehicles$ = this.vehicleService.vehicles$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  async uploadNewVehicle(currentUser: User): Promise<any> {
    const allowVehicleModels3d = this.vehicleService.getAllowVehicleModels3d();
    const allowVehicleScans = this.vehicleService.getAllowVehicleScans();
    let modSourceCollection: DbCollectionsEnum;

    const wheelbaseMeasurement: WheelbaseMeasurement = {
      fromAxlePosition: 1,
      toAxlePosition: 2,
      value: null,
    };

    const vehicle: Vehicle = {
      _id: new ObjectID().toString(),
      angleOfApproach: null,
      angleOfDeparture: null,
      braking: null,
      centerOfGravity: {
        horizontal: {
          value: null,
          units: 'cm',
        },
        vertical: {
          value: null,
          units: 'm',
        },
      },
      creatorId: currentUser._id,
      curbWeight: {
        value: null,
        units: 'kg',
      },
      description: null,
      editorId: currentUser._id,
      engine: null,
      equipmentTypeId: null,
      grossVehicleWeight: {
        value: null,
        units: 'kg',
      },
      groundClearance: {
        value: null,
        units: 'cm',
      },
      hasBrakes: true,
      hasValidMod: false,
      images: [],
      maximumHorsepower: {
        value: null,
        units: 'KW',
      },
      isMotorized: true,
      maximumSteeringAngle: null,
      maximumTorque: {
        value: null,
        units: 'N*m',
      },
      minimumTurningRadius: {
        value: null,
        units: 'm',
      },
      modelName: '',
      modSources: {
        models3d: [],
        scans: [],
      },
      movementTypeId: WheeledVehicleMovement._id,
      name: '',
      notes: [],
      numberOfAxles: 2,
      numberOfWheels: 4,
      purpose: '',
      rampBreakoverAngle: null,
      overallDimensions: {
        vehicleHeight: {
          value: null,
          units: 'm',
        },
        vehicleLength: {
          value: null,
          units: 'm',
        },
        vehicleWidth: {
          value: null,
          units: 'm',
        },
      },
      steeringTypeId: UnknownSteeringType._id,
      tireRadius: null,
      userIdsToSendChangeNotificationsTo: [currentUser._id],
      vehicleDesignationId: null,
      vehicleModelId: null,
      vehiclePurposeId: null,
      videos: [],
      wheelbase: {
        measurements: [wheelbaseMeasurement],
        units: 'm',
      },
      wheelTrack: {
        value: null,
        units: 'm',
      },
    };

    if (allowVehicleModels3d && allowVehicleScans) {
      const inputOptions = {
        models3d: '3D Model',
        scans: 'Scan',
      };

      const { value: modSourceCollection } = await Swal.fire({
        title: 'Select Source Type',
        input: 'radio',
        inputOptions: inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return 'Pelase select a source type';
          }
        },
      });
    } else {
      if (allowVehicleModels3d) {
        modSourceCollection = DbCollectionsEnum.MODELS3D;
      } else if (allowVehicleScans) {
        modSourceCollection = DbCollectionsEnum.SCANS;
      }
    } //only one option allowed

    if (modSourceCollection) {
      console.log(`selected ${modSourceCollection}`);
      this.modSourceCollection = modSourceCollection;

      const modSourceId = new ObjectID().toString();

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '600px';

      dialogConfig.data = {
        allowVehicleModels3d: allowVehicleModels3d,
        allowVehicleScans: allowVehicleScans,
        currentUser: currentUser,
        isNewVehicle: true,
        vehicle: vehicle,
      };

      switch (this.modSourceCollection) {
        case DbCollectionsEnum.MODELS3D:
          vehicle.modSources.models3d.push(modSourceId);

          const model3d: Model3d = {
            _id: modSourceId,
            classification: 'Unclass',
            creatorId: currentUser._id,
            editorId: currentUser._id,
            fidelityType: 'Engineering',
            name: '',
            nameOfPersonModeling: '',
            parent: {
              _id: vehicle._id,
              collection: DbCollectionsEnum.VEHICLES,
            },
          };

          dialogConfig.data.model3d = model3d;

          break;
        case DbCollectionsEnum.SCANS:
          vehicle.modSources.scans.push(modSourceId);
          const scan: Scan = {
            _id: modSourceId,
            classification: 'Unclass',
            creatorId: currentUser._id,
            editorId: currentUser._id,
            name: null,
            parent: {
              _id: vehicle._id,
              collection: DbCollectionsEnum.VEHICLES,
            },
            scanDisplayType: ScanDisplayTypesEnum.POINT_CLOUD,
            scanType: ScanTypesEnum.RAW,
            siteFileName: ''
          };

          dialogConfig.data.scan = scan;

          break;
      }

      const dialogRef = this.dialog.open(VehicleDialogComponent, dialogConfig);
    }
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }
}
