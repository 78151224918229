import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  AreaMeasurementService,
  DataSourceService,
  DocumentService,
  ErrorService,
  FileService,
  GeotagService,
  ImageDocService,
  LogService,
  ManufacturerService,
  MeasurementService,
  ModService,
  Model3dService,
  NavigationService,
  NoteService,
  OverlayService,
  ProjectService,
  ReportService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipService,
  ShipClassService,
  ShipDesignationService,
  UnrealInteractionService,
  UnrealServerService,
  UserService,
  VehicleService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
  VideoService
} from '@shared/services';
import { Navlink, User } from '@shared/models';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit {
  //force logout when app is destroyed by the browser, see  https://medium.com/ngconf/run-code-when-you-leave-your-angular-app-d4ef30472d20
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    try {
      this.logout();
    } catch (ex) {
      const errMessage = this.errorService.handleError(
        `Error closing unreal viewers or logging out user in window beforeUnloadHandler: ${ex.message}`
      );
    }
  }

  @ViewChild(MatSidenav)
  sidenav: MatSidenav;

  title = 'SDAT';

  // see https://blog.bitsrc.io/how-to-implement-idle-timeout-in-angular-af61eefdb13b
  private idleState = 'Not started.';
  private timedOut = false;
  private lastPing?: Date = null;
  private showSidenavSubject = new BehaviorSubject<boolean>(false);
  private toolbarIconNameSubject = new BehaviorSubject<string>('close');
  private mediaWatcher: Subscription;

  public opened = true;
  public isMobile = false;

  currentUser$: Observable<User>;
  showSidenav$: Observable<Boolean> = this.showSidenavSubject.asObservable();
  toolbarIconName$: Observable<string> = this.toolbarIconNameSubject.asObservable();

  // see https://nirajsonawane.github.io/2018/10/27/Angular-Material-Tabs-with-Router/
  homeLink: Navlink = {
    label: 'Home',
    link: '/home',
    index: 0,
  };

  projectsLink: Navlink = {
    label: 'Projects',
    link: './projects',
    index: 1,
  };

  shipsLink: Navlink = {
    label: 'Ships',
    link: './ships',
    index: 2,
  };

  vehiclesLink: Navlink = {
    label: 'Vehicles',
    link: './vehicles',
    index: 3,
  };

  systemAccessLink: Navlink = {
    label: 'System Access',
    link: './systemAccess',
    index: 4,
  };

  navLinks: Navlink[];
  activeLinkIndex = -1;
  showTabs = false;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    //private media: MediaObserver,
    private observer: BreakpointObserver,
    private areaMeasurementService: AreaMeasurementService,
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private geotagService: GeotagService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private measurementService: MeasurementService,
    private modService: ModService,
    private model3dService: Model3dService,
    private navigationService: NavigationService,
    private noteService: NoteService,
    private overlayService: OverlayService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private unrealInteractionService: UnrealInteractionService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private vehiclePurposeService: VehiclePurposeService,
    private videoService: VideoService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {

    this.settingsService.setIsDebugging(environment.isDebugging === true);
    //this.userService.init();

    this.matIconRegistry.addSvgIcon('new', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/New.svg'));

    this.matIconRegistry.addSvgIcon(
      'projects',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/Projects.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ships',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/Ships.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'vehicles',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/Vehicles.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'systemAccess',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/SystemAccess.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'systemAccessManageUsers',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/SystemAccess-ManageUsers.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'systemAccessSettings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/SystemAccess-Settings.svg')
    );

    //amount of time until user warned about inactivity
    idle.setIdle(environment.idleWarningSeconds);
    //additional time until they will be lcoked out
    idle.setTimeout(environment.idleTimeoutSeconds);
    //set the default interrupts, like clicks, scrolls, touches, etc.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      try {
        Swal.close();
      } catch (ex) {
        //don't throw error if Swal not open
      }

      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
      let _this = this;
      let _console = console;

      Swal.fire({
        title: 'Inactivity alert!',
        icon: 'warning',
        html: 'SDAT will close in <b></b> seconds due to inactivity.',
        timer: environment.idleTimeoutSeconds * 1000,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'Keep Working',
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          idle.onTimeoutWarning.subscribe((countdown) => {
            b.textContent = Math.floor(Swal.getTimerLeft() / 1000).toString();
            _this.idleState = `You will logged out in ${countdown} seconds`;
          });
        },
        willClose: () => {
          idle.onTimeoutWarning.unsubscribe();
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log(`Logging out due to inactivity`);
        } else if (result.isDenied) {
          console.log(`User choose to keep working`);
        } else if (result.isConfirmed) {
          console.log('User choose to logout');
          this.logout();
        }
      });
    });

    //set the ping interval
    keepalive.interval(environment.idleKeepaliveInterval);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.userService.userLoggedIn$.subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
  }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if(result.matches){
        console.log("Mobile Layout");
        this.toolbarIconNameSubject.next('menu');
      }else{
        console.log("Desktop Layout")
      }
      this.isMobile = result.matches;
    });

    this.currentUser$.subscribe((user) => {
      if (user) {
        this.navLinks = [this.homeLink, this.projectsLink, this.shipsLink, this.vehiclesLink];

        if (user.role === UserRolesEnum.ADMIN) {
          this.navLinks.push(this.systemAccessLink);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.mediaWatcher.unsubscribe();
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  clearData(): void {
    this.projectService.getProjectById(null, null);
    this.shipService.getShipById(null, null);
    this.vehicleService.getVehicleById(null, null);
  }

  editUser() {
    const currentUser = this.userService.getCurrentUser();
    const promises = [];
    this.settingsService.setIsLoading(true);

    if (currentUser) {
      //set selected user to the current user
      promises.push(this.userService.getUserById(currentUser._id, true));
      promises.push(this.documentService.refreshDocumentsByUser(currentUser));
      promises.push(this.imageDocService.refreshImagesByUser(currentUser, false));
      promises.push(this.projectService.refreshProjectsByUser(currentUser));
      promises.push(this.reportService.refreshReportsByUser(currentUser));

      Promise.allSettled(promises).then((results) => {
        if (results.length > 0 && results[0].status === 'fulfilled') {
          const navigationUrl = `/users/${currentUser._id}`;
          this.router.navigateByUrl(navigationUrl);
        } else {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `There was an error loading the user information.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        }
      });
    } else {
      promises.push(this.documentService.refreshDocumentsByUser(null));
      promises.push(this.imageDocService.refreshImagesByUser(null, false));
      promises.push(this.projectService.refreshProjectsByUser(null));
      promises.push(this.reportService.refreshReportsByUser(null));

      Promise.allSettled(promises).then((results) => {
        this.errorService.handleError(`No user could be found`);
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Displaying User',
            `Unable to load user data.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      });
    }
  }

  getHelp(): void {
    // TODO create page or link
    Swal.fire('Help', `SDAT Version ${environment.appVersion}.  Help is coming soon...`);
  }

  goToHome(): void {
    this.closeSidebar();
    this.router.navigate(['/home']);
  }

  goToProjects(): void {
    this.closeSidebar();
    this.router.navigate(['/projects']);
  }

  goToShips(): void {
    this.closeSidebar();
    this.router.navigate(['/ships']);
  }

  goToVehicles() {
    this.closeSidebar();
    this.router.navigate(['/vehicles']);
  }

  goToSystemAccess() {
    this.closeSidebar();
    this.router.navigate(['/systemAccess']);
  }

  closeSidebar(){
    if(this.isMobile){
      this.sidenav.close();
    }
  }

  logout(): void {
    try {
      this.settingsService.setIsLoading(true);
      const currentUser = this.userService.getCurrentUser();
      this.userService.logoutUser(currentUser);
      this.clearData();
      this.settingsService.setIsLoading(false);
      this.router.navigate(['/login']);
    } catch (ex) {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error', `There was an error logging you out.  Please email ${environment.techSupportEmail}`, 'error');
      }
    }
  }

  onSidenavOpenChange(): void {
    if (this.sidenav) {
      if (this.sidenav.opened) {
        this.toolbarIconNameSubject.next('close');
      } else {
        this.toolbarIconNameSubject.next('menu');
      }
    }
  }

  reset(): void {
    this.idle.watch();
    this.timedOut = false;

    try {
      Swal.close();
    } catch (ex) {
      //don't throw error if Swal not open
    }
  }
}
