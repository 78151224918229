import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { User } from '@shared/models';
import {
  DocumentService,
  ErrorService,
  ImageDocService,
  LogService,
  ProjectService,
  ReportService,
  SettingsService,
  UserService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.css'],
    standalone: false
})
export class UserCardComponent implements OnInit {
  @Input()
  user: User;

  currentUser$: Observable<User>;
  private userErrorSubject = new BehaviorSubject<string>(null);
  private userIsSelectedSubject = new BehaviorSubject<boolean>(false);
  userError$: Observable<string> = this.userErrorSubject.asObservable();
  userIsSelected$: Observable<boolean> = this.userIsSelectedSubject.asObservable();

  constructor(
    private documentService: DocumentService,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  canDelete(user: User): boolean {
    const currentUser = this.userService.getCurrentUser();
    let returnValue = false;

    if (currentUser && user && currentUser.role === UserRolesEnum.ADMIN && currentUser._id !== user._id) {
      returnValue = true;
    }

    return returnValue;
  }

  deleteUser(user: User): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    Swal.fire({
      title: `Delete User:  ${user.fullName}?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService
          .deleteUser(user._id)
          .then((users: User[]) => {
            this.router.navigate(['/users']);
          })
          .catch((error) => {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Deleting User`,
                `There was an error deleting the user.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    });
  }

  getUserDisplayClass(user: User): string {
    let returnValue = '';

    if (user) {
      this.userIsSelected$.subscribe((isSelected) => {
        if (isSelected) {
          returnValue = 'selected-user';
        }
      });
    }

    return returnValue;
  }

  toggleUserInEditGroup(selected: boolean, user: User): void {
    this.userService.toggleSelectedUser(selected, user);
    this.userIsSelectedSubject.next(selected);
  }

  viewUser(user: User): void {
    this.settingsService.setIsLoading(true);
    const currentUser = this.userService.getCurrentUser();
    const promises = [];

    if (user && currentUser) {
      promises.push(this.userService.getUserById(user._id, true));
      promises.push(this.documentService.refreshDocumentsByUser(user));
      promises.push(this.imageDocService.refreshImagesByUser(user, false));
      promises.push(this.projectService.refreshProjectsByUser(user));
      promises.push(this.reportService.refreshReportsByUser(user));

      Promise.allSettled(promises).then((results) => {
        if (results.length > 0 && results[0].status === 'fulfilled') {
          const navigationUrl = `/users/${user._id}`;
          this.router.navigateByUrl(navigationUrl);
        } else {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `There was an error loading the user information.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        }
      });
    } else {
      promises.push(this.documentService.refreshDocumentsByUser(null));
      promises.push(this.imageDocService.refreshImagesByUser(null, false));
      promises.push(this.projectService.refreshProjectsByUser(null));
      promises.push(this.reportService.refreshReportsByUser(null));

      Promise.allSettled(promises).then((results) => {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Displaying User',
            `Unable to load user data.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      });
    }
  }
}
