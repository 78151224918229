<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="{
      isDebugging: isDebugging$ | async,
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Users As">
        <mat-button-toggle 
          mat-icon-button 
          aria-label="View users as cards" 
          matTooltip="View users as cards"
          value="cards" 
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle 
          mat-icon-button 
          aria-label="View users as list" 
          matTooltip="View users as list" 
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button
        class="image"
        *ngIf="isAdmin" 
        aria-label="Add new user" 
        matTooltip="Add new user" 
        id="btnCreateUser"
        (click)="createUser(currentUser)"
      >
        <span class="btnCreateUser"></span>
        ADD NEW USER
      </button>
      <button 
        class="image" 
        *ngIf="isAdmin && isSelectedUsers" 
        aria-label="Edit Selected Users" 
        matTooltip="Edit Selected Users" 
        id="btnEditSelectedUsers"
        (click)="editSelectedUsers()"
        [ngClass]="{'toolbar-btn-visible': isAdmin && isSelectedUsers}"
      >
        <span class="btnEditSelectedUsers"></span>
        EDIT SELECTED USERS
      </button>
    </mat-toolbar>
    <div class="content">
      <div *ngIf="users$ | async as users">
        <h2 id="managementHeader">USER MANAGEMENT</h2>
        <mat-divider></mat-divider>
        <div class="grid" *ngIf="settings.viewFormat === 'cards'">
          <div *ngFor="let user of users">
            <app-user-card [user]="user"> </app-user-card>
          </div>
        </div>
        <div *ngIf="settings.viewFormat === 'list'">
          <app-user-table [users]="users" [showPaginator]="true" [showFilter]="true"></app-user-table>
        </div>
      </div>
    </div>
  </ng-container>
</div>