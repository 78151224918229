import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { concatAll, catchError, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';
import { environment } from '@environment';

import { UserSubscriptionTypesEnum } from '@shared/enums';

import {
  ActivitySubscription,
  Document,
  ImageDoc,
  ProjectOverview,
  ReportOverview,
  Report,
  ReportSection,
  Ship,
  Vehicle,
  UsedInProject,
  User,
} from '@shared/models';
import {
  DocumentService,
  ErrorService,
  FileService,
  ImageDocService,
  LogService,
  ProjectService,
  ReportService,
  SettingsService,
  UserService,
} from '@shared/services';

import { UserDialogComponent } from './user-dialog/user-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
    standalone: false
})
export class UserComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private selectedReportSubject = new BehaviorSubject<Report>(null);
  currentUser$: Observable<User>;
  errorMsg: boolean;
  imageFileObj: File;
  reportSections$: Observable<ReportSection[]>;
  selectedReport$: Observable<Report> = this.selectedReportSubject.asObservable();
  selectedReportShipImages$: Observable<ImageDoc[]>;
  selectedReportVehicleImages$: Observable<ImageDoc[]>;
  selectedUser$: Observable<User>;
  selectedShip$: Observable<Ship>;
  selectedVehicle$: Observable<Vehicle>;
  userDocuments$: Observable<Document[]>;
  userImages$: Observable<ImageDoc[]>;
  userActivitySubscriptions$: Observable<ActivitySubscription[]>;
  userModifiedActivitySubscriptionIds$: Observable<string[]>;
  userProjects$: Observable<ProjectOverview[]>;
  userReports$: Observable<ReportOverview[]>;
  userShips$: Observable<Ship[]>;
  userVehicles$: Observable<Vehicle[]>;
  safeSrc: SafeResourceUrl;
  displayedReportColumns: string[] = ['name', 'description', 'finalizedDate', 'actions'];

  @Input()
  selectedUser: User;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.selectedUser$ = this.userService.selectedUser$;
    this.userDocuments$ = this.documentService.selectedUserDocuments$;
    this.userImages$ = this.imageDocService.selectedUserImages$;
    this.userActivitySubscriptions$ = this.userService.selectedUserActivitySubscriptions$;
    this.userModifiedActivitySubscriptionIds$ = this.userService.selectedUserModifiedActivitySubscriptionIds$;
    this.userProjects$ = this.projectService.selectedUserProjectsCreated$;
    this.userReports$ = this.reportService.selectedUserReports$;
    this.settingsService.setIsLoading(false);
  }

  ngOnDestroy(): void {
    this.documentService.refreshDocumentsByUser(null);
    this.imageDocService.refreshImagesByUser(null, false);
    this.projectService.refreshProjectsByUser(null);
    this.reportService.refreshReportsByUser(null);
    this.userService.getUserById(null, true);
  }

  get showSubscriptionSaveButton(): boolean {
    let returnValue = false;

    this.userModifiedActivitySubscriptionIds$.subscribe((ids) => {
      returnValue = Array.isArray(ids) && ids.length > 0;
    });

    return returnValue;
  }

  get showUnsubscribeButton(): boolean {
    return this.userService.getSelectedUserActivityNotifications().length > 0;
  }

  editUser(selectedUser: User, currentUser: User) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewUser: false,
      selectedUser: selectedUser,
    };

    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
  }

  getNumberOfItemsInArray(items) {
    let returnValue = 0;

    if (items && Array.isArray(items)) {
      returnValue = items.length;
    }

    return returnValue;
  }

  getSubscriptionType(subType): string {
    let returnValue = '';

    switch (subType) {
      case UserSubscriptionTypesEnum.PROJECTS:
        returnValue = UserSubscriptionTypesEnum.PROJECTS;
        break;
      case UserSubscriptionTypesEnum.SHIPS:
        returnValue = UserSubscriptionTypesEnum.SHIPS;
        break;
      case UserSubscriptionTypesEnum.VEHICLES:
        returnValue = UserSubscriptionTypesEnum.VEHICLES;
        break;
    }

    return returnValue;
  }

  async saveSubscriptionChanges(): Promise<any> {
    return new Promise((resolve, reject) => {
      const _this = this;
      const currentUser = _this.userService.getCurrentUser();
      const selectedUser = _this.userService.getSelectedUser();

      _this.settingsService.setIsLoading(true);
      _this.userService.updateSelectedUserSubscriptionsToMatchActivitySubscriptions(currentUser._id, selectedUser._id, _this.userService.getSelectedUserActivityNotifications())
        .then((updatedUser) => {
          //do nothing, use is updated in service
        })
        .catch((error) => {
          _this.settingsService.setIsLoading(false);

          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Updating User Subscriptions`,
              `There was an error updating the subscriptions.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        })
        .finally(() => {
          _this.settingsService.setIsLoading(false);
          resolve('saved changes');
        });
    });
  }

  unsubscribeFromAll(selectedUser: User, currentUser: User) {
    const _this = this;

    Swal.fire({
      title: 'Unsubscribe User from All Notifications?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const emptySubscriptions = new Array<ActivitySubscription>();

        _this.userService.updateSelectedUserSubscriptionsToMatchActivitySubscriptions(currentUser._id, selectedUser._id, emptySubscriptions)
          .then((updatedUser) => {
            //do nothing, handled in service
          })
          .catch((error) => {
            _this.settingsService.setIsLoading(false);

            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Unsubscribing User`,
                `There was an error updating the subscriptions.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          })
      }
    });
  }
}
