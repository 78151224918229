import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { concatAll, catchError, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  Document,
  ImageDoc,
  Measurement,
  Mod,
  Model3d,
  Note,
  Project,
  Report,
  ReportSection,
  Scan,
  Ship,
  Vehicle,
  UnrealServer,
  User,
  Video,
} from '@shared/models';
import {
  DocumentService,
  ErrorService,
  FileService,
  ImageDocService,
  LogService,
  MeasurementService,
  ModService,
  Model3dService,
  NoteService,
  ProjectService,
  ReportService,
  ScanService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
  VideoService,
} from '@shared/services';
import {
  FileObjectTypesEnum,
  DbCollectionsEnum,
  ModStatesEnum,
  ReportSectionIdsEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  UnrealScenesEnum,
  UnrealUIStatesEnum,
  UserRolesEnum,
} from '@shared/enums';
import { createTag, getYesOrNo } from '@shared/utils';

import { environment } from '@environment';

import { ProjectDialogComponent } from './project-dialog/project-dialog.component';
import { ReportItemDialogComponent } from '../report/report-item-dialog/report-item-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css'],
    standalone: false
})
export class ProjectComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private selectedReportSubject = new BehaviorSubject<Report>(null);
  activeVideoIndex: number;
  currentVideo: Video;
  data: any;
  errorMsg: boolean;
  errorText = 'Please select a file';
  selectedReport$: Observable<Report> = this.selectedReportSubject.asObservable();
  selectedReportShipImages$: Observable<ImageDoc[]>;
  selectedReportVehicleImages$: Observable<ImageDoc[]>;
  selectedShip$: Observable<Ship>;
  selectedShipMod$: Observable<Mod>;
  selectedShipModModel3d$: Observable<Model3d>;
  selectedShipModScan$: Observable<Scan>;
  selectedShipMods$: Observable<Mod[]>;
  selectedShipModels3d$: Observable<Model3d[]>;
  selectedShipScans$: Observable<Scan[]>;
  selectedVehicle$: Observable<Vehicle>;
  selectedVehicleMod$: Observable<Mod>;
  selectedVehicleModModel3d$: Observable<Model3d>;
  selectedVehicleModScan$: Observable<Scan>;
  selectedVehicleMods$: Observable<Mod[]>;
  selectedVehicleModels3d$: Observable<Model3d[]>;
  selectedVehicleScans$: Observable<Scan[]>;
  currentUser$: Observable<User>;
  project$: Observable<Project>;
  projectDocuments$: Observable<Document[]>;
  projectImages$: Observable<ImageDoc[]>;
  projectMeasurements$: Observable<Measurement[]>;
  projectNotes$: Observable<Note[]>;
  projectReports$: Observable<Report[]>;
  projectVideos$: Observable<Video[]>;
  reportSections$: Observable<ReportSection[]>;
  ships$: Observable<Ship[]>;
  vehicles$: Observable<Vehicle[]>;
  projectDocumentation: UntypedFormArray;
  projectForm: UntypedFormGroup;
  shipForm: UntypedFormGroup;
  vehicleForm: UntypedFormGroup;
  currentUser: User;
  isReadOnly: boolean;
  isOpeningReportViewer: boolean;
  isOpeningUnrealViewer: boolean;
  safeSrc: SafeResourceUrl;
  displayedReportColumns: string[] = ['name', 'description', 'finalizedDate', 'actions'];
  @Input()
  projects: Project[];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private measurementService: MeasurementService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private videoService: VideoService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activeVideoIndex = 0;
    this.currentUser$ = this.userService.currentUser$;
    this.project$ = this.projectService.currentProject$;
    this.projectDocuments$ = this.documentService.currentProjectDocuments$;
    this.projectImages$ = this.imageDocService.currentProjectImages$;
    this.projectMeasurements$ = this.measurementService.currentProjectMeasurements$;
    this.projectNotes$ = this.noteService.currentProjectNotes$;
    this.projectReports$ = this.projectService.currentProjectReports$;
    this.projectVideos$ = this.projectService.currentProjectVideos$;
    this.reportSections$ = this.reportService.reportSections$;
    this.ships$ = this.shipService.ships$;
    this.selectedReportShipImages$ = this.imageDocService.currentShipImages$;
    this.selectedReportVehicleImages$ = this.imageDocService.currentVehicleImages$;
    this.selectedShip$ = this.shipService.currentShip$;
    this.selectedShipMod$ = this.modService.currentShipMod$;
    this.selectedShipMods$ = this.modService.currentShipMods$;
    this.selectedShipModModel3d$ = this.model3dService.currentShipModel3d$;
    this.selectedShipModScan$ = this.scanService.currentShipScan$;
    this.selectedShipModels3d$ = this.model3dService.currentShipModels3d$;
    this.selectedShipScans$ = this.scanService.shipScans$;
    this.vehicles$ = this.vehicleService.vehicles$;
    this.selectedVehicle$ = this.vehicleService.currentVehicle$;
    this.selectedVehicleMod$ = this.modService.currentVehicleMod$;
    this.selectedVehicleModModel3d$ = this.model3dService.currentVehicleModel3d$;
    this.selectedVehicleModScan$ = this.scanService.currentVehicleScan$;
    this.selectedVehicleMods$ = this.modService.currentVehicleMods$;
    this.selectedVehicleModels3d$ = this.model3dService.currentVehicleModels3d$;
    this.selectedVehicleScans$ = this.scanService.vehicleScans$;
    this.isOpeningUnrealViewer = false;

    try {
      this.settingsService.setIsLoading(true);

      zip(
        this.currentUser$,
        this.project$,
        this.projectDocuments$,
        this.projectImages$,
        this.projectMeasurements$,
        this.projectNotes$,
        this.projectReports$,
        this.projectVideos$,
        this.selectedReportShipImages$,
        this.selectedReportVehicleImages$,
        this.ships$,
        this.selectedShip$,
        this.selectedShipMods$,
        this.selectedShipMod$,
        this.selectedShipModModel3d$,
        this.selectedShipModScan$,
        this.selectedShipModels3d$,
        this.selectedShipScans$,
        this.vehicles$,
        this.selectedVehicle$,
        this.selectedVehicleModModel3d$,
        this.selectedVehicleModScan$,
        this.selectedVehicleMods$,
        this.selectedVehicleMod$,
        this.selectedVehicleModels3d$,
        this.selectedVehicleScans$,
        this.reportSections$
      )
        .pipe(untilDestroyed(this))
        .pipe(
          map(
            ([
              user,
              project,
              projectDocuments,
              projectImages,
              projectMeasurements,
              projectNotes,
              projectReports,
              projectVideos,
              shipImages,
              vehicleImages,
              ships,
              selectedShip,
              selectedShipMods,
              selectedShipMod,
              selectedShipModModel3d,
              selectedShipModScan,
              selectedShipModels3d,
              selectedShipScans,
              vehicles,
              selectedVehicle,
              selectedVehicleModModel3d,
              selectedVehicleModScan,
              selectedVehicleMods,
              selectedVehicleMod,
              selectedVehicleModels3d,
              selectedVehicleScans,
              reportSections,
            ]) => ({
              user,
              project,
              projectDocuments,
              projectImages,
              projectMeasurements,
              projectNotes,
              projectReports,
              projectVideos,
              shipImages,
              vehicleImages,
              ships,
              selectedShip,
              selectedShipMods,
              selectedShipMod,
              selectedShipModModel3d,
              selectedShipModScan,
              selectedShipModels3d,
              selectedShipScans,
              vehicles,
              selectedVehicle,
              selectedVehicleModModel3d,
              selectedVehicleModScan,
              selectedVehicleMods,
              selectedVehicleMod,
              selectedVehicleModels3d,
              selectedVehicleScans,
              reportSections,
            })
          )
        )
        .subscribe(
          ({
            user,
            project,
            projectDocuments,
            projectImages,
            projectMeasurements,
            projectNotes,
            projectReports,
            projectVideos,
            shipImages,
            vehicleImages,
            ships,
            selectedShip,
            selectedShipMods,
            selectedShipMod,
            selectedShipModModel3d,
            selectedShipModScan,
            selectedShipModels3d,
            selectedShipScans,
            vehicles,
            selectedVehicle,
            selectedVehicleModModel3d,
            selectedVehicleModScan,
            selectedVehicleMods,
            selectedVehicleMod,
            selectedVehicleModels3d,
            selectedVehicleScans,
            reportSections,
          }) => {
            this.currentUser = user;
            const isAdmin = user && user.role === UserRolesEnum.ADMIN;
            this.isReadOnly = isAdmin ? false : true;
          }
        );
    } catch (ex) {
      this.settingsService.setIsLoading(false);
      const errMessage = this.errorService.handleError(`Error loading project page: ${ex.message}`);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error', 'Error Loading Page', 'error');
      }
    } finally {
      this.settingsService.setIsLoading(false);
    }
  }

  ngOnDestroy(): void {
    if (this.isOpeningUnrealViewer || this.isOpeningReportViewer) {
    } else {
      this.projectService.getProjectById(null, null);
    }
  }

  get currentVideoUrl(): string {
    let returnValue = '';

    if (this.currentVideo && (this.currentVideo.displayUrl || this.currentVideo.url)) {
      returnValue = this.currentVideo.displayUrl || this.currentVideo.url;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get showViewerLite(): boolean {
    return environment.unreal.showViewerLite === true;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  get unrealViewerNameLite(): string {
    return environment.unreal.viewerNameLite;
  }

  canDelete(report: Report): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === report.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  getIsGettingNotifications(project: Project): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (project && user) {
      const idx = project.userIdsToSendChangeNotificationsTo.indexOf(user._id);
      if (idx != -1) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  getYesOrNo(value: boolean): string {
    return getYesOrNo(value);
  }

  getReportSectionId(reportSection: string) {
    return ReportSectionIdsEnum[reportSection];
  }

  getReportSectionTitleCase(reportSections: ReportSection[], reportSectionId: string) {
    const matching = reportSections.find((reportSection) => reportSection._id === reportSectionId);
    return matching ? matching.titleCase : '';
  }

  getReportSectionsUserCanAddNoteTo(reportSections: ReportSection[], parentCollection: string) {
    return reportSections.filter((reportSection) => {
      let returnValue = true;
      const idx = reportSection.parentCollections.indexOf(parentCollection);

      if (!reportSection.userCanAddNotes || idx === -1) {
        returnValue = false;
      }

      return returnValue;
    });
  }

  getNonSnapshotImages(projectImages: ImageDoc[]) {
    if (projectImages && projectImages.length > 0) {
      return projectImages.filter((img) => !img.isSnapshot);
    }
  }

  getEditableProjectNotes(projectNotes: Note[]) {
    if (projectNotes && projectNotes.length > 0) {
      function filterOutSnapshotAndVideoNotes(note) {
        let returnValue = true;

        if (note.imageId || note.videoId || note.reportId) {
          returnValue = false;
        }

        return returnValue;
      }

      return projectNotes.filter(filterOutSnapshotAndVideoNotes);
    }
  }

  getSnapshotImages(projectImages: ImageDoc[]) {
    if (projectImages && projectImages.length > 0) {
      return projectImages.filter((img) => img.isSnapshot);
    }
  }

  getSnapshotNotes(projectNotes: Note[], imageId: string) {
    if (projectNotes && projectNotes.length > 0) {
      return projectNotes.filter((note) => note.imageId === imageId);
    }
  }

  getVideoNotes(projectNotes: Note[], videoId: string) {
    if (projectNotes && projectNotes.length > 0) {
      return projectNotes.filter((note) => note.videoId === videoId);
    }
  }

  async addNote(project: Project) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      isNewNote: true,
      project: project,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async addSnapshotNote(project: Project, imgDoc: ImageDoc) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      imageDoc: imgDoc,
      isNewNote: true,
      project: project,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async createReport(
    project: Project,
    ship: Ship,
    shipMod: Mod,
    shipModel3d: Model3d,
    shipScan: Scan,
    vehicle: Vehicle,
    vehicleMod: Mod,
    vehicleModel3d: Model3d,
    vehicleScan: Scan
  ) {
    const _this = this;
    const author = this.currentUser;

    if (project && author) {
      const idToUse = new ObjectID().toString();
      const report: Report = {
        _id: idToUse,
        creatorId: author._id,
        finalized: {
          finalizedDate: null,
          thumbnailUrl: '',
          url: '',
          userId: null,
        },
        editorId: author._id,
        name: null,
        options: {
          analysis: {
            notes: true,
            snapshots: true,
            videos: true,
          },
          collision: {
            count: true,
            notes: true,
            snapshots: true,
            videos: true,
          },
          minimumClearance: {
            lateralLeft: true,
            lateralRight: true,
            longitudinalFront: true,
            longitudinalBack: true,
            notes: true,
            snapshots: true,
            verticalGround: true,
            verticalRoof: true,
            videos: true,
          },
          pathTraveled: {
            notes: true,
            snapshots: true,
            videos: true,
          },
          project: {
            propagatedError: true,
          },
          ship: {
            image: true,
            model3d: {
              dataSource: shipModel3d ? true : false,
              fidelityType: shipModel3d ? true : false,
              fileType: shipModel3d ? true : false,
              modelDate: shipModel3d ? true : false,
            },
            notes: true,
            scan: {
              dataSource: shipScan ? true : false,
              fileType: shipScan ? true : false,
              scanDate: shipScan ? true : false,
              scanType: shipScan ? true : false,
            },
            snapshots: true,
            videos: true,
          },
          snapshot: {
            notes: true,
            snapshots: true,
          },
          vehicle: {
            axles: true,
            brakes: true,
            engine: true,
            generalInfo: true,
            image: true,
            maneuverability: true,
            model3d: {
              dataSource: vehicleModel3d ? true : false,
              fidelityType: vehicleModel3d ? true : false,
              fileType: vehicleModel3d ? true : false,
              modelDate: vehicleModel3d ? true : false,
            },
            notes: true,
            scan: {
              dataSource: vehicleScan ? true : false,
              fileType: vehicleScan ? true : false,
              scanDate: vehicleScan ? true : false,
              scanType: vehicleScan ? true : false,
            },
            snapshots: true,
            steering: true,
            videos: true,
          },
        },
        projectId: project._id,
        url: `${environment.aws.childBuckets.PROJECTS}/${project._id}/reports/${idToUse}`,
      };

      const { value: reportName } = await Swal.fire({
        title: 'Enter the report name',
        input: 'text',
        /* inputLabel: 'Report Name', */
        inputPlaceholder: 'Enter the report name',
      });

      if (reportName) {
        try {
          report.name = reportName;
          this.settingsService.setIsLoading(true);
          const reportResults = await this.projectService.addReport(report, project, author);
          this.settingsService.setIsLoading(false);
          this.openReport(
            project,
            report,
            ship,
            shipMod,
            shipModel3d,
            shipScan,
            vehicle,
            vehicleMod,
            vehicleModel3d,
            vehicleScan
          );
        } catch (ex) {
          _this.settingsService.setIsLoading(false);
          _this.errorService.handleError(`Error saving project report ${JSON.stringify(report)}: ${ex}`);
          _this.errorMsg = true;
          _this.errorText = `${ex.message}`;
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error Saving Project Report',
              `${ex.message}.  Please email ${environment.techSupportEmail}`,
              'error'
            );
          }
        }
      }
    }
  }

  async deleteImage(img: ImageDoc, project: Project, currentUser: User): Promise<any> {
    const _this = this;
    let title = `Do you want to delete the ${img.name}`;

    if (img.isSnapshot) {
      title += `snapshot and any related notes?`;
    } else {
      title += 'image ?';
    }

    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.imageDocService
          .deleteImage(img._id, DbCollectionsEnum.PROJECTS, project._id, currentUser)
          .then(function (deleteResults) {
            console.log(`deleted imageId ${img._id}`);
          })
          .catch(function (deleteError) {
            let errorTitle = 'Error Deleting ';
            if (img.isSnapshot) {
              errorTitle += 'Snaphot and Notes';
            } else {
              errorTitle += 'Image';
            }
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(errorTitle, deleteError.message, 'error');
            }
          });
      }
    });
  }

  async deleteReport(report: Report, projectId: string): Promise<any> {
    const _this = this;
    Swal.fire({
      title: `Do you want to delete the ${report.name} report?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.reportService
          .deleteReport(report._id, projectId, _this.currentUser)
          .then(function (deleteResults) {
            console.log(`deleted reportId ${report._id}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire('Error Deleting Report', deleteError.message, 'error');
            }
          });
      }
    });
  }

  async deleteShipScan(scan: Scan, userId: string): Promise<any> {
    const _this = this;
    Swal.fire({
      title: `Do you want to delete the ${scan.name} from this project?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.projectService
          .deleteMod(scan.modId, DbCollectionsEnum.SHIPS, _this.currentUser)
          .then(function (deleteResult) {
            console.log(`deleted scan mod ${scan.modId} from ship`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire('Error Deleting Scan', deleteError.message, 'error');
            }
          });
      }
    });
  }

  async deleteVehicleModel3d(model3d: Model3d, userId: string): Promise<any> {
    const _this = this;
    Swal.fire({
      title: `Do you want to delete the ${model3d.name} from this project?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.projectService
          .deleteMod(model3d.modId, DbCollectionsEnum.VEHICLES, _this.currentUser)
          .then(function (deleteResult) {
            console.log(`deleted 3D model mod ${model3d.modId}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire('Error Deleting 3D Model', deleteError.message, 'error');
            }
          });
      }
    });
  }

  async deleteVideo(video: Video, project: Project): Promise<any> {
    const _this = this;
    const title = `Do you want to delete the ${video.name} video?`;

    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.videoService
          .deleteVideo(video._id, DbCollectionsEnum.PROJECTS, project._id, _this.currentUser)
          .then(function (deleteResults) {
            console.log(`deleted videoId ${video._id}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              let errorTitle = 'Error Deleting Video';
              Swal.fire(errorTitle, deleteError.message, 'error');
            }
          });
      }
    });
  }

  async documentFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const project = this.projectService.getCurrentProject();

    if (fileEl?.files.length > 0 && project) {
      const errors = [];
      const promises = [];
      const documentTypes = [];
      const fileTags = [];
      const docTags = [];
      const files = fileEl.files;

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        const fileType1 = FILE.type;
        let documentType = '';

        //get document type
        if (fileType1.indexOf('audio') > -1) {
          documentType = TagFileTypesEnum.AUDIO;
        } else if (fileType1.indexOf('image') > -1) {
          documentType = TagFileTypesEnum.IMAGE;
        } else if (fileType1.indexOf('video') > -1) {
          documentType = TagFileTypesEnum.VIDEO;
        } else {
          const inputOptions = {
            Picture: 'Image',
            Manual: 'Manual',
            Report: 'Report',
            Other: 'Other',
          };

          const { value: docType } = await Swal.fire({
            title: `Select Document Type for file: ${FILE.name}`,
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
              if (!value) {
                return 'Please select the document type';
              }
            },
          });

          if (docType) {
            documentType = docType;
          }
        }

        documentTypes.push(documentType || '');

        const tag = createTag(TagTypesEnum.FILE_TYPE, documentType);
        fileTags.push({
          fileName: FILE.name,
          tags: tag
        });
        docTags.push({
          fileName: FILE.name,
          tags: [tag.value, 'project', project.name]
        });

        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.PROJECT, project._id, fileTags[x].tags, documentType, _this.currentUser))
      }

      _this.settingsService.setIsLoading(true);
      await Promise.allSettled(promises)
        .then(async (results) => {
          const projectPromises = [];
          for (let y = 0; y < results.length; y++) {
            const result = results[y];

            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {

              const doc: Document = {
                _id: new ObjectID().toString(),
                name: result.value.nameWithoutExtension,
                documentType: documentTypes[y],
                encodedVideoUrl: result.value.encodedVideoUrl,
                parent: {
                  _id: project._id,
                  collection: DbCollectionsEnum.PROJECTS,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: docTags[y].tags,
              };

              projectPromises.push(_this.projectService.addDocument(doc, project, _this.currentUser));
            }

            projectPromises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.PROJECTS, project._id, _this.currentUser));
          }

          await Promise.allSettled(projectPromises)
            .then((projectResults) => {
              let isProcessing = false;
              projectResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading documents for projectId ${project._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Project Document(s)',
                  `There were one or more error processing your documents.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    }
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const project = this.projectService.getCurrentProject();

    if (fileEl?.files.length > 0 && project) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      let imgType = TagFileTypesEnum.IMAGE;
      const tags = [];
      const imgTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, imgType);
      tags.push(tag);
      imgTags.push(tag.value);
      imgTags.push('project');
      imgTags.push(project.name);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.PROJECT, project._id, tags, imgType, this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const projectPromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const imageDoc: ImageDoc = {
                _id: new ObjectID().toString(),
                displayThumbnailUrl: result.value.thumbnailDisplayUrl,
                displayUrl: result.value.locationDisplayUrl,
                isMainImage: false,
                isSnapshot: false,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: project._id,
                  collection: DbCollectionsEnum.PROJECTS,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: imgTags,
              };

              projectPromises.push(_this.projectService.addImage(imageDoc, project, _this.currentUser));
            }

            projectPromises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.PROJECTS, project._id, false, _this.currentUser))
          });

          await Promise.allSettled(projectPromises)
            .then((projectResults) => {
              let isProcessing = false;
              projectResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading images for projectId ${project._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Project Image(s)',
                  `There were one or more error processing your images.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });

    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select an image';
    }
  }

  async videoFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const project = this.projectService.getCurrentProject();

    if (fileEl?.files.length > 0 && project) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      const tags = [];
      const videoTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.VIDEO);
      tags.push(tag);
      videoTags.push(tag.value);
      videoTags.push('project');
      videoTags.push(project.name);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.PROJECT, project._id, tags, TagFileTypesEnum.VIDEO, _this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const projectPromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const video: Video = {
                _id: new ObjectID().toString(),
                encodedUrl: result.value.encodedVideoUrl,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: project._id,
                  collection: DbCollectionsEnum.PROJECTS,
                },
                url: result.value.locationUrl,
                creatorId: this.currentUser._id,
                tags: videoTags,
              };

              projectPromises.push(_this.projectService.addVideo(video, project, _this.currentUser));
            }

            projectPromises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.PROJECTS, project._id, _this.currentUser));
          });

          await Promise.allSettled(projectPromises)
            .then((projectResults) => {
              let isProcessing = false;
              projectResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading videos for projectId ${project._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Project Video(s)',
                  `There were one or more error processing your videos.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select a video';
    }
  }

  editProject(project: Project, currentUser: User) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      project: project,
      isNewProject: false,
    };

    const dialogRef = this.dialog.open(ProjectDialogComponent, dialogConfig);
  }

  getNumberOfItemsInArray(items) {
    let returnValue = 0;

    if (items && Array.isArray(items)) {
      returnValue = items.length;
    }

    return returnValue;
  }

  getShowProjectSaveButton(role: string) {
    if (this.projectForm.dirty && this.projectForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  initVideo() {
    this.data.play();
  }

  nextVideo(): void {
    const projectVideos = this.projectService.getCurrentProjectVideos();
    this.activeVideoIndex += 1;
    if (this.activeVideoIndex == projectVideos.length) {
      this.activeVideoIndex = 0;
    }
    this.currentVideo = projectVideos[this.activeVideoIndex];
  }

  async openProjectViewer(project: Project, openViewerLite: boolean) {
    this.settingsService.setIsLoading(true);
    this.settingsService.setLoadingId(project._id);
    const user = this.userService.getCurrentUser();

    if (project && user) {
      let shipMod;
      const vehicleMod = this.modService.getCurrentVehicleMod();
      let navigationUrl = `/projects/${project._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}`;
      this.isOpeningUnrealViewer = true;

      if (openViewerLite) {
        const panoScan = this.scanService.getCurrentShipPanoramicScan();
        shipMod = await this.modService.getModById(panoScan?.modId, panoScan.parent?.collection, panoScan.parent?._id, user);
        navigationUrl += `&panoOnly=true`;
      } else {
        shipMod = this.modService.getCurrentShipMod();
        navigationUrl += `&panoOnly=false`;
      }

      if (shipMod) {
        navigationUrl += `&pcName=${shipMod.name}`;
      }

      if (vehicleMod) {
        navigationUrl += `&vehicleName=${vehicleMod.name}`;
      }

      this.router
        .navigateByUrl(navigationUrl)
        .then(() => {
          this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
        })
        .catch((unrealError) => {
          this.settingsService.setIsLoading(false);
          const errMessage = this.errorService.handleError(
            `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
          );
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Opening ${environment.unreal.viewerName}`,
              `${unrealError}.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
          this.settingsService.setLoadingId(null);
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error Opening ${environment.unreal.viewerName}`,
          `Project and user are required.  Please email ${environment.techSupportEmail}`,
          'error'
        );
      }
    }
  }

  async openReport(
    project: Project,
    report: Report,
    ship: Ship,
    shipMod: Mod,
    shipModel3d: Model3d,
    shipScan: Scan,
    vehicle: Vehicle,
    vehicleMod: Mod,
    vehicleModel3d: Model3d,
    vehicleScan: Scan
  ) {
    const user = this.userService.getCurrentUser();
    this.selectedReportSubject.next(report);

    if (project && report && user) {
      this.settingsService.setIsLoading(true);
      this.isOpeningReportViewer = true;

      const navigationUrl = `/projects/${project._id}/report/${report._id}`;

      this.reportService
        .getReportById(
          report._id,
          project,
          ship,
          shipMod,
          shipModel3d,
          shipScan,
          vehicle,
          vehicleMod,
          vehicleModel3d,
          vehicleScan,
          user
        )
        .then((report) => {
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Displaying Report`,
              `There was an error displaying the report.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Report',
          `The report you selected could not be displayed.  Please email ${environment.techSupportEmail}`,
          'error'
        );
      }
    }
  }

  saveProjectChanges(): void {
    this.settingsService.setIsLoading(true);

    this.projectService
      .saveProject(this.projectForm.controls._id.value, this.projectForm.value, this.currentUser)
      .then((updatedProject: Project) => {
        this.projectForm.markAsPristine();
      })
      .catch((error) => {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `There was an error saving the project.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      })
      .finally(() => {
        this.settingsService.setIsLoading(false);
      });
  }

  startVideoPlaylist(video: Video, index: number): void {
    this.activeVideoIndex = index;
    this.currentVideo = video;
  }

  updateNotifications(getNotifications: boolean, project: Project) {
    const user = this.userService.getCurrentUser();

    if (user && project) {
      const idx = project.userIdsToSendChangeNotificationsTo.indexOf(user._id);
      let changes = {
        editorId: this.currentUser._id,
        userIdsToSendChangeNotificationsTo: project.userIdsToSendChangeNotificationsTo
      };
      let madeChanges = false;

      if (getNotifications && idx === -1) {
        madeChanges = true;
        changes.userIdsToSendChangeNotificationsTo.push(user._id);
      } else if (!getNotifications && idx > -1) {
        madeChanges = true;
        delete changes.userIdsToSendChangeNotificationsTo[idx];
      }

      if (madeChanges) {
        this.settingsService.setIsLoading(true);
        this.projectService
          .saveProject(project._id, changes, this.currentUser)
          .then((updatedProject: Project) => {
            this.settingsService.setIsLoading(false);
          })
          .catch((error) => {
            this.settingsService.setIsLoading(false);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error updating your notifications preference.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    }
  }

  //see https://www.positronx.io/angular-video-player-using-ngx-videogular-with-customized-controls-example/
  videoPlayerInit(data: any) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVideo.bind(this));
    this.data.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
}
