<div class="block-container-with-toolbar" *ngIf="currentUser$ | async as currentUser">
  <ng-container
    *ngIf="{
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Ships As">
        <mat-button-toggle
          mat-icon-button
          aria-label="View ships as cards"
          matTooltip="View ships as cards"
          value="cards"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          mat-icon-button
          aria-label="View ships as list"
          matTooltip="View ships as list"
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;&nbsp;&nbsp;
      <button
        class="image"
        *ngIf="isAdmin"
        id="btnUploadNewShip"
        aria-label="Upload new Ship"
        matTooltip="Upload new ship"
        (click)="uploadNewShip(currentUser)"
      >
        <span class="btnUploadNewShip"></span>
        CREATE NEW SHIP
      </button>
    </mat-toolbar>
    <div *ngIf="ships$ | async as ships">
      <h2 id="libraryHeader">SHIP LIBRARY</h2>
      <mat-divider></mat-divider>
      <div class="grid" *ngIf="settings.viewFormat === 'cards'">
        <div *ngFor="let ship of ships">
          <app-ship-card [ship]="ship"> </app-ship-card>
        </div>
      </div>
      <div *ngIf="settings.viewFormat === 'list'">
        <app-ship-table [ships]="ships" [showPaginator]="true" [showFilter]="true"></app-ship-table>
      </div>
    </div>
  </ng-container>
</div>
