import packageJson from '../../package.json';

export const environment = {
  appName: 'SDAT Dev',
  appVersion: packageJson.version,
  production: false,
  baseAppURL: 'https://dev-sdat.designmillinc.com',
  baseAPIUrl: 'https://dev-sdatapi.designmillinc.com/api/',
  emailErrorMessages: true,
  idleWarningSeconds: 1500,
  idleTimeoutSeconds: 300,
  idleKeepaliveInterval: 15,
  aws: {
    bucket: 'files-designmillinc',
    bucketUrl: 'https://files.designmillinc.com/',
    cloudfrontUrl: 'https://files.designmillinc.com/',
    secretAccessKey: 'a4Tp2dcbKP8zjxAA7/BZ8SyaOuN5wv6BOHrSQG97',
    accessKeyId: 'AKIAVEH6EILYC6V3PELN',
    user: 'sdatUser',
    region: 'us-east-1',
    signatureVersion: 'v4',
    useAccelerateEndpoint: true,
    serverSideEncryption: 'AES256',
    defaultAcl: 'bucket-owner-full-control',
    childBuckets: {
      MODS: 'mods',
      PROJECTS: 'projects',
      SHIPS: 'ships',
      USERS: 'users',
      VEHICLES: 'vehicles'
    }
  },
  azure: {
    bucketUrl: 'https://sdatdevfiles.file.core.windows.net/',
    modFilePath: 'mods-ue5',
    projectAndShipFilePath: 'project-dir',
    vehicleFilePath: 'json-dir'
  },
  defaultImageUrl: 'https://files.designmillinc.com/sdat-dev/SDAT.png',
  defaultPassword: 'sdat',
  defaultThumbnailImageUrl: 'https://files.designmillinc.com/sdat-dev/thumbnail_SDAT.png',
  isDebugging: false,
  fileStorageType: 'aws',
  loggly: {
    token: '9d3b3c9d-9ba7-48dc-a48d-8d37b2faadbc',
    subdomain: 'designmill.loggly.com',
    tags: ['sdat-dev'],
    logToConsole: true
  },
  panoCloudFileExtensions: ['.e57'],
  pointCloudFileExtensions: ['.e57', '.las', '.pts'],
  postmark: {
    token: process.env.POSTMARK_TOKEN,
  },
  showPopupErrorMessages: false,
  techSupportEmail: 'jane_mccleary@designmillinc.com',
  transloadit: {
    apiUrl: 'https://api2.transloadit.com/assemblies',
    authKey: 'f7b89085f62643889fa1f88b2529ee70',
    authSecret: '157ea564c641c964d6329f7d1f67c61c4ebcbe87',
    encodeVideoTemplateId: '0360b66d2e2e4e529727c8fc44cf2dc1',
    thumbnailFromImageFileTemplateId: '245e2edcb0904cb4ae5ed4c4e155023c',
    thumbnailFromPdfFileTemplateId: 'bf60db84b9cf4739902cbee661f0ed47'
  },
  unreal: {
    baseAppPath: "C:\\Unreal_1\\WindowsNoEditor",
    fileStorageType: 'azure',
    matchmakerUrl: 'https://viprdev.designmillinc.com',
    programName: "ViPr_Viewer.exe",
    projectAndShipFilePath: "\\ViPr_Viewer\\ProjectDir\\",
    shortcut: "ViPr_Viewer_PS.lnk",
    showViewerLite: false,
    siteFileDirectory: "SiteDir",
    siteFilePath: "\\ViPr_Viewer\\SiteDir\\",
    viewerName: 'ViPr Viewer',
    viewerNameLite: 'ViPr Viewer Lite',
    vehicleFilePath: "\\ViPr_Viewer\\JSONDir\\",
  }
};