import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, User } from '@shared/models';
import {
  DataSourceService,
  DocumentService,
  ErrorService,
  ImageDocService,
  LogService,
  ManufacturerService,
  MeasurementService,
  ModService,
  Model3dService,
  NoteService,
  ProjectService,
  ReportService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipService,
  ShipClassService,
  ShipDesignationService,
  UnrealInteractionService,
  UnrealServerService,
  UserService,
  VehicleService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { ModelAnalysisProjectDialogComponent } from '../project/model-analysis-project-dialog/model-analysis-project-dialog.component';
import { ProjectDialogComponent } from '../project/project-dialog/project-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})
export class HomeComponent implements OnInit {
  currentUser$: Observable<User>;

  constructor(
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private measurementService: MeasurementService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private unrealInteractionService: UnrealInteractionService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private vehiclePurposeService: VehiclePurposeService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;

    //this.dataSourceService.init();
    //this.documentService.init();
    //this.imageDocService.init();
    //this.manufacturerService.init();
    //this.measurementService.init();
    //this.modService.init();
    //this.model3dService.init();
    //this.projectService.init();
    //this.reportService.init();
    //this.scanService.init();
    //this.scannerService.init();
    //this.settingsService.init();
    //this.shipService.init();
    //this.shipClassService.init();
    //this.shipDesignationService.init();
    //this.unrealInteractionService.init();
    //this.unrealServerService.init();
    //this.vehicleService.init();
    //this.vehicleDesignationService.init();
    //this.vehicleModelService.init();
    //this.vehiclePurposeService.init();
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  createProject(currentUser: User): any {
    let isModelAnalysisProject = false;
    
    Swal.fire({
      title: 'Is this a Model Analysis Project?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        isModelAnalysisProject = true;
      }

      this.settingsService.setIsLoading(true);

      const promises = [];
      promises.push(this.projectService.getProjectById(null, currentUser));
      promises.push(this.shipService.getShipById(null, currentUser));
      promises.push(this.vehicleService.getVehicleById(null, currentUser));
      let dialogRef;
  
      Promise.allSettled(promises).then((results) => {
        const project: Project = {
          _id: new ObjectID().toString(),
          areas: [],
          creatorId: currentUser._id,
          documentation: [],
          editorId: currentUser._id,
          geotags: [],
          hasValidPanoMod: false,
          images: [],
          isModelAnalysisProject: isModelAnalysisProject,
          measurements: [],
          minimumClearance: isModelAnalysisProject ? {
            lateral: {
              left: {
                value: 0,
                units: 'cm',
              },
              right: {
                value: 0,
                units: 'cm',
              },
            },
            longitudinal: {
              back: {
                value: 0,
                units: 'cm',
              },
              front: {
                value: 0,
                units: 'cm',
              },
            },
            vertical: {
              ground: {
                value: 0,
                units: 'cm',
              },
              roof: {
                value: 0,
                units: 'cm',
              },
            },
          } : null,
          name: null,
          notes: [],
          reports: [],
          ship: {
            _id: null,
            modId: null,
          },
          userIdsToSendChangeNotificationsTo: [currentUser._id],
          vehicle: isModelAnalysisProject ? {
            _id: null,
            modId: null
          } : null,
          videos: [],
        };
  
        const dialogConfig = new MatDialogConfig();
  
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
  
        dialogConfig.data = {
          currentUser: currentUser,
          isNewProject: true,
          project: project,
        };
  
        this.settingsService.setIsLoading(false);

        if (isModelAnalysisProject) {
          dialogRef = this.dialog.open(ModelAnalysisProjectDialogComponent, dialogConfig);
        } else {
          dialogRef = this.dialog.open(ProjectDialogComponent, dialogConfig);
        }
      });
    });
  }

  goToProjects(): void {
    this.router.navigate(['/projects']);
  }

  goToShips(): void {
    this.router.navigate(['/ships']);
  }

  goToVehicles() {
    this.router.navigate(['/vehicles']);
  }

  goToSystemAccess(): void {
    this.router.navigate(['/systemAccess']);
  }
}
