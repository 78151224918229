import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { BehaviorSubject, fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { ImageDoc, Note, Project, Report, ReportSection, Ship, User, Vehicle, Video } from '@shared/models';
import {
  ErrorService,
  ImageDocService,
  LogService,
  NoteService,
  ProjectService,
  ReportService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService,
  VideoService,
} from '@shared/services';
import { DbCollectionsEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-report-item-dialog',
    templateUrl: './report-item-dialog.component.html',
    styleUrls: ['./report-item-dialog.component.css'],
    standalone: false
})
export class ReportItemDialogComponent implements OnInit {
  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  currentUser: User;
  errorMsg: boolean;
  errorText = 'Please select a file';
  formTitleToUse = '';
  imageDoc: ImageDoc;
  isIncludedOnReport = false;
  isMainImage = false;
  isNewNote = false;
  isSnapshot = false;
  note: Note;
  parentCollection: string;
  parentId: string;
  project: Project;
  report: Report;
  ship: Ship;
  vehicle: Vehicle;
  video: Video;
  form: UntypedFormGroup;
  currentUser$: Observable<User>;
  reportSections$: Observable<ReportSection[]>;
  validReportSections: ReportSection[];
  isDebugging$: Observable<boolean>;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ReportItemDialogComponent>,
    private router: Router,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private videoService: VideoService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      imageDoc: ImageDoc;
      isNewNote: boolean;
      note: Note;
      project: Project;
      ship: Ship;
      report: Report;
      vehicle: Vehicle;
      video: Video;
    }
  ) {
    this.settingsService.setIsLoading(true);
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.currentUser$ = this.userService.currentUser$;
    this.reportSections$ = this.reportService.reportSections$;
    this.currentUser = data.currentUser;
    this.imageDoc = data.imageDoc;
    this.isMainImage = data.imageDoc ? data.imageDoc.isMainImage : false;
    this.isNewNote = data.isNewNote || false;
    this.isSnapshot = data.imageDoc ? data.imageDoc.isSnapshot : false;
    this.note = data.note;
    this.project = data.project;
    this.report = data.report;
    this.ship = data.ship;
    this.vehicle = data.vehicle;
    this.video = data.video;

    zip(
      this.currentUser$,
      this.reportSections$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(
          ([
            user,
            reportSections,
          ]) => ({
            user,
            reportSections,
          })
        )
      )
      .subscribe(
        ({
          user,
          reportSections,
        }) => {
          this.form = new UntypedFormGroup({
            editorId: new UntypedFormControl(this.currentUser._id, [Validators.required]),
            isMainImage: new UntypedFormControl(this.imageDoc ? this.imageDoc.isMainImage : false),
            name: new UntypedFormControl(this.imageDoc ? this.imageDoc.name : this.video ? this.video.name : ''),
            noteText: new UntypedFormControl(this.note ? this.note.noteText : ''),
            reportSectionId: new UntypedFormControl('')
          });

          //videos and their notes are not included on the report
          if ((this.imageDoc && this.imageDoc.isSnapshot) || (this.note && !this.note.videoId)) {
            this.isIncludedOnReport = true;
          }

          if (this.imageDoc || this.video) {
            this.form.controls.name.setValidators([Validators.required]);

            if (this.imageDoc) {
              this.form.controls.isMainImage.setValidators([Validators.required]);
            }
          }
          if (this.note) {
            this.form.controls.noteText.setValidators([Validators.required]);
          }

          if (this.isNewNote) {
            //videos are not shown on reports
            if (!this.video) {
              this.isIncludedOnReport = true;
            }

            if (this.project) {
              this.parentCollection = DbCollectionsEnum.PROJECTS;
              this.parentId = this.project._id;
              this.validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(this.parentCollection);
              this.formTitleToUse = this.report ? 'Add Report Note' : 'Add Project Note';
            } else if (this.ship) {
              this.parentCollection = DbCollectionsEnum.SHIPS;
              this.parentId = this.ship._id;
              this.validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(this.parentCollection);
              this.formTitleToUse = 'Add Ship Note';
            } else if (this.vehicle) {
              this.parentCollection = DbCollectionsEnum.VEHICLES;
              this.parentId = this.vehicle._id;
              this.validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(this.parentCollection);
              this.formTitleToUse = 'Add Vehicle Note';
            } else {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `A project, ship or vehicle is required to add a new note too.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            }
          }

          if (this.imageDoc) {
            if (this.imageDoc.isSnapshot) {
              this.validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(
                this.imageDoc.parent.collection
              );
            } else {
              this.validReportSections = this.reportService.getReportSections();
            }

            this.formTitleToUse = this.isSnapshot ? 'Edit Snapshot' : 'Edit Image';
            this.parentCollection = this.imageDoc.parent.collection;
            this.parentId = this.imageDoc.parent._id;

            //New notes adding to an existing image must use the image reportSectionId.  The user can change the image reportSectionId later if desired.
            if (this.isNewNote) {
              this.form.controls.reportSectionId.setValue(this.imageDoc.reportSectionId);
              this.form.controls.reportSectionId.disable();
            }
          } else if (this.video) {
            this.formTitleToUse = 'Edit Video';
            this.parentCollection = this.video.parent.collection;
            this.parentId = this.video.parent._id;
          } else if (this.note) {
            this.parentCollection = this.note.parent.collection;
            this.parentId = this.note.parent._id;
            this.validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(this.parentCollection);
            this.formTitleToUse = this.report
              ? 'Edit Report Note'
              : this.project
                ? 'Edit Project Note'
                : this.ship
                  ? 'Edit Ship Note'
                  : 'Edit Vehicle Note';
          }

          if (this.isIncludedOnReport) {
            this.form.controls.reportSectionId.setValue(this.imageDoc
              ? this.imageDoc.reportSectionId
              : this.note
                ? this.note.reportSectionId
                : '');
            this.form.controls.reportSectionId.setValidators([Validators.required]);
          }

          if (this.isIncludedOnReport && (!this.parentCollection || !this.parentId || !this.validReportSections || this.validReportSections.length === 0)) {
            this.settingsService.setIsLoading(false);
            this.dialogRef.close();
            //console.log(`validReportSections: ${JSON.stringify(this.validReportSections)}`);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `Unable to determine valid parent and report sections for the new item.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          } else {
            this.settingsService.setIsLoading(false);
          }
        }
      )
  }

  ngOnInit(): void {
  }

  get canHaveReportSectionId(): boolean {
    return this.isIncludedOnReport;
  }

  get formTitle(): String {
    return this.formTitleToUse;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get mainImageTitle(): String {
    let returnValue = `Is Main `;

    switch (this.parentCollection) {
      case DbCollectionsEnum.PROJECTS:
        returnValue += 'Project ';
        break;
      case DbCollectionsEnum.SHIPS:
        returnValue += 'Ship ';
        break;
      case DbCollectionsEnum.VEHICLES:
        returnValue += 'Vehicle ';
        break;
    }

    returnValue += 'Image?';
    return returnValue;
  }

  get showIsMainImageField(): boolean {
    return this.imageDoc ? true : false;
  }

  get showNameField(): boolean {
    return this.note || this.isNewNote ? false : true;
  }

  get showNoteTextField(): boolean {
    return this.note || this.isNewNote ? true : false;
  }

  close(): void {
    this.dialogRef.close();
  }

  getShowSaveButton() {
    return this.form.dirty && this.form.valid;
  }

  async saveChanges(): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const changes = {
        ..._this.form.value,
        editorId: _this.currentUser._id,
      };
      let results;

      try {
        if (_this.isNewNote) {
          delete changes.isMainImage;
          delete changes.name;

          const newNote: Note = {
            creatorId: _this.currentUser._id,
            editorId: _this.currentUser._id,
            noteText: changes.noteText,
            parent: {
              _id: _this.parentId,
              collection: _this.parentCollection,
            },
            position: 0, //will be updated in the service
          };

          //videos are not included on reports
          if (_this.project && !_this.video) {
            newNote.reportId = changes.reportSectionId
          }

          if (_this.imageDoc) {
            newNote.imageId = _this.imageDoc._id;
          } else if (_this.report) {
            newNote.reportId = _this.report._id;
          } else if (_this.video) {
            newNote.videoId = _this.video._id;
          }

          switch (_this.parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              results = await _this.projectService.addNote(newNote, _this.project, _this.currentUser);
              break;
            case DbCollectionsEnum.SHIPS:
              results = await _this.shipService.addNote(newNote, _this.ship, _this.currentUser);
              break;
            case DbCollectionsEnum.VEHICLES:
              results = await _this.vehicleService.addNote(newNote, _this.vehicle, _this.currentUser);
              break;
          }
        } else if (_this.imageDoc) {
          delete changes.noteText;
          results = await _this.imageDocService.saveImage(
            _this.imageDoc._id,
            _this.imageDoc.parent.collection,
            _this.imageDoc.parent._id,
            changes,
            _this.currentUser
          );
        } else if (_this.video) {
          delete changes.noteText;
          results = await this.videoService.saveVideo(
            _this.video._id,
            _this.video.parent.collection,
            _this.video.parent._id,
            changes,
            _this.currentUser
          );
        } else if (_this.note) {
          delete changes.name;
          results = await _this.noteService.saveNote(
            _this.note._id,
            _this.note.parent.collection,
            _this.note.parent._id,
            changes,
            _this.currentUser
          );
        }

        _this.noteService
          .refreshNotesByParent(_this.parentCollection, _this.parentId, _this.currentUser)
          .then((refreshResults) => {
            if (_this.settingsService.getIsDebugging()) {
              _this.logService.logInfo(
                `Successfully saved ${_this.parentCollection} parentId ${this.parentId
                } report item changes: ${JSON.stringify(changes)}`
              );
            }
          })
          .catch((refreshError) => {
            _this.errorService.handleError(
              `Error refreshing ${_this.parentCollection} parentId ${_this.parentId} notes after successfully saving changes`
            );
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
            _this.errorMsg = false;
            _this.errorText = '';
            _this.dialogRef.close();
            resolve(results);
          });
      } catch (ex) {
        _this.settingsService.setIsLoading(false);
        _this.dialogRef.close();
        _this.errorMsg = true;
        _this.errorText = ex.message;
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Saving Report Item',
            `${ex}.  please email ${environment.techSupportEmail} with any questions.`,
            'error'
          );
        }
      }
    });
  }
}
