import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';

import { Geotag, Project, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class GeotagService {
  private currentGeotagSubject = new BehaviorSubject<Geotag>(null);
  private currentProjectGeotagsSubject = new BehaviorSubject<Geotag[]>(null);
  private geotagErrorSubject = new BehaviorSubject<string>(null);
  currentProjectGeotags$: Observable<Geotag[]> = this.currentProjectGeotagsSubject.asObservable();
  geotagError$: Observable<string> = this.geotagErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  init(): void {
  }

  async createNewGeotag(payload, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload && project) {
        try {
          const geotags = _this.currentProjectGeotagsSubject.getValue();
          const newGeotags = geotags ? geotags.slice(0) : [];
          const url = `${environment.baseAPIUrl}geotag/create?userId=${currentUser?._id}`;
          let returnValue: Geotag;
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newGeotag: Geotag) => {
                returnValue = newGeotag;
                newGeotags.push(newGeotag);
                _this.currentProjectGeotagsSubject.next(newGeotags);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.geotagErrorSubject.next(error.error);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } catch (ex) {
          const errMessage = `Error creating new geotag for projectId ${project._id} parentId: ${ex.message}`;
          _this.geotagErrorSubject.next(errMessage);
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, project and user are required to create a geotag`;
        this.errorService.handleError(errMessage);
        this.geotagErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteGeotag(geotagId: string, projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Geotag[];

      if (currentUser && geotagId && projectId) {
        try {
          const url = `${environment.baseAPIUrl}geotag/${geotagId}?userId=${currentUser._id}`;

          this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (deletedGeotag: Geotag) => {
                console.log(`successfully deleted geotagId ${geotagId}`);
                this.refreshGeotagsByProject(projectId, currentUser)
                  .then((geotags) => {
                    returnValue = geotags;
                    resolve(returnValue);
                  })
                  .catch((geotagsError) => {
                    const errMessage = `Error refreshing geotags for projectId ${projectId}: ${geotagsError}`;
                    this.errorService.handleError(errMessage);
                    this.geotagErrorSubject.next(errMessage);
                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = this.errorService.handleError(error);
                this.geotagErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = `Error removing geotagId ${geotagId} from projectId ${projectId}: ${ex.message}`;
          this.errorService.handleError(errMessage);
          this.geotagErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `geotagId and projectId are required to delete a collision`;
        this.errorService.handleError(errMessage);
        this.geotagErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentProjectGeotags() {
    return this.currentProjectGeotagsSubject.getValue();
  }

  async refreshGeotagsByProject(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Geotag[];

      if (currentUser && projectId) {
        const url = `${environment.baseAPIUrl}geotag/${DbCollectionsEnum.PROJECTS}/${projectId}?userId=${currentUser?._id}`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (geotags: Geotag[]) => {
              returnValue = geotags;
              _this.currentProjectGeotagsSubject.next(geotags);
              _this.geotagErrorSubject.next('');
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.geotagErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentProjectGeotagsSubject.next(returnValue);
        _this.geotagErrorSubject.next('');
        resolve(returnValue);
      }
    });
  }

  async saveGeotag(geotagId: string, changes, currentUser: User): Promise<any> {
    const _this = this;
    
    return new Promise((resolve, reject) => {
      if (changes && currentUser && geotagId) {
        const geotags = _this.currentProjectGeotagsSubject.getValue();
        const geotagIndex = geotags.findIndex((geotag) => geotag._id === geotagId);
        const newGeotags = geotags.slice(0);
        const url = `${environment.baseAPIUrl}geotag/${geotagId}?userId=${currentUser?._id}`;
        let returnValue: Geotag;
  
        newGeotags[geotagIndex] = {
          ...geotags[geotagIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedGeotag: Geotag) => {
              returnValue = updatedGeotag;
              _this.currentGeotagSubject.next(updatedGeotag);
              _this.currentProjectGeotagsSubject.next(newGeotags);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.geotagErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `changes, geotagId and user are required to update a geotag`;
        this.errorService.handleError(errMessage);
        this.geotagErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
