import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';

import { AreaMeasurement, Project, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';
import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class AreaMeasurementService {
  private currentAreaMeasurementSubject = new BehaviorSubject<AreaMeasurement>(null);
  private currentProjectAreaMeasurementsSubject = new BehaviorSubject<AreaMeasurement[]>(null);
  private areaMeasurementErrorSubject = new BehaviorSubject<string>(null);
  private usersSubject = new BehaviorSubject<User[]>(null);
  currentProjectAreaMeasurements$: Observable<AreaMeasurement[]> =
    this.currentProjectAreaMeasurementsSubject.asObservable();
  areaMeasurementError$: Observable<string> = this.areaMeasurementErrorSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.users$ = this.userService.users$;
  }

  async createNewAreaMeasurement(payload, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {  
      resolve('');
      /* TODO..put back 
      if (currentUser && payload && project) {
        try {
          const areaMeasurements = _this.currentProjectAreaMeasurementsSubject.getValue();
          const newAreaMeasurements = areaMeasurements ? areaMeasurements.slice(0) : [];
          const url = `${environment.baseAPIUrl}areaMeasurement/create?userId=${currentUser?._id}`;
          let returnValue: AreaMeasurement;
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe( {
              next: (newAreaMeasurement: AreaMeasurement) => {
                returnValue = newAreaMeasurement;
                newAreaMeasurements.push(newAreaMeasurement);
                _this.currentProjectAreaMeasurementsSubject.next(newAreaMeasurements);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.areaMeasurementErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } catch (ex) {
          const errMessage = `Error creating new areaMeasurement for projectId ${project._id} parentId: ${ex.message}`;
          _this.areaMeasurementErrorSubject.next(errMessage);
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, project and user are required to create an area measurement`;
        _this.errorService.handleError(errMessage);
        _this.areaMeasurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
        */
    });
  }

  async deleteAreaMeasurement(areaMeasurementId: string, projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      resolve('');

      /* TODO...put back
      let returnValue: AreaMeasurement[];

      if (areaMeasurementId && currentUser && projectId) {
        try {
          const url = `${environment.baseAPIUrl}areaMeasurement/${areaMeasurementId}?userId=${currentUser._id}`;

          _this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe( {
              next: (deletedAreaMeasurement: AreaMeasurement) => {
                console.log(`successfully deleted areaMeasurementId ${areaMeasurementId}`);
                _this.refreshAreaMeasurementsByProject(projectId, currentUser)
                  .then((areaMeasurements) => {
                    returnValue = areaMeasurements;
                    resolve(returnValue);
                  })
                  .catch((areaMeasurementsError) => {
                    const errMessage = _this.errorService.handleError(areaMeasurementsError);
                    _this.areaMeasurementErrorSubject.next(errMessage);
                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                _this.errorService.handleError(error);
                _this.areaMeasurementErrorSubject.next(error.error);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = `Error removing areaMeasurementId ${areaMeasurementId} from projectId ${projectId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.areaMeasurementErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `areaMeasurementId and projectId are required to delete an area measurement`;
        _this.errorService.handleError(errMessage);
        _this.areaMeasurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
        */
    });
  }

  getCurrentProjectAreaMeasurements() {
    return this.currentProjectAreaMeasurementsSubject.getValue();
  }

  async refreshAreaMeasurementsByProject(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      resolve('');
      /* TODO ...put back
      _this.settingsService.setIsLoading(true);
      let returnValue: AreaMeasurement[];

      if (currentUser && projectId) {
        const url = `${environment.baseAPIUrl}areaMeasurement/${DbCollectionsEnum.PROJECTS}/${projectId}?userId=${currentUser?._id}`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (areaMeasurements: AreaMeasurement[]) => {
              returnValue = areaMeasurements;
              _this.currentProjectAreaMeasurementsSubject.next(areaMeasurements);
              _this.areaMeasurementErrorSubject.next('');
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = `Error getting areaMeasurements for projectId ${projectId}: ${error.error}`;
              _this.errorService.handleError(errMessage);
              _this.areaMeasurementErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentProjectAreaMeasurementsSubject.next(returnValue);
        _this.areaMeasurementErrorSubject.next('');
        resolve(returnValue);
      }
        */
    });
  }

  async saveAreaMeasurement(areaMeasurementId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      resolve('');
      /* TODO ...put back
      if (areaMeasurementId && changes && currentUser) {
        const areaMeasurements = _this.currentProjectAreaMeasurementsSubject.getValue();
        const areaMeasurementIndex = areaMeasurements.findIndex(
          (areaMeasurement) => areaMeasurement._id === areaMeasurementId
        );
        const newAreaMeasurements = areaMeasurements.slice(0);
        const url = `${environment.baseAPIUrl}areaMeasurement/${areaMeasurementId}?userId=${currentUser?._id}`;
        let returnValue: AreaMeasurement;
  
        newAreaMeasurements[areaMeasurementIndex] = {
          ...areaMeasurements[areaMeasurementIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedAreaMeasurement: AreaMeasurement) => {
              returnValue = updatedAreaMeasurement;
              _this.currentAreaMeasurementSubject.next(updatedAreaMeasurement);
              _this.currentProjectAreaMeasurementsSubject.next(newAreaMeasurements);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.areaMeasurementErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `areaMeasurementId, changes and user are required to update an area measurement`;
        _this.errorService.handleError(errMessage);
        _this.areaMeasurementErrorSubject.next(errMessage);
        reject(errMessage);
      }
        */
    });
  }
}
