import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControlDirective,
  RequiredValidator,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  DataSource,
  Manufacturer,
  Scan,
  Scanner,
  Ship,
  ShipClass,
  ShipDesignation,
  User,
  Vehicle,
} from '@shared/models';
import {
  DataSourceService,
  ErrorService,
  FileService,
  ManufacturerService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipService,
  ShipClassService,
  ShipDesignationService,
  UserService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, FileObjectTypesEnum, ModStatesEnum, ModTypesEnum, ScanDisplayTypesEnum, ScanTypesEnum, TagFileTypesEnum, TagTypesEnum, UserRolesEnum } from '@shared/enums';
import { createTag, getUpdates } from '@shared/utils';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
    selector: 'app-scan-dialog',
    templateUrl: './scan-dialog.component.html',
    styleUrls: ['./scan-dialog.component.css'],
    standalone: false
})
export class ScanDialogComponent implements OnInit {
  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  currentUser: User;
  errorMsg: boolean;
  errorText = 'Please select a file';
  hasPanos: boolean = false;
  isNewScan: boolean;
  panoFileObj: File;
  panoFileType: string = '';
  panoScan: Scan;
  panoUrl: string = '';
  parentShip: Ship;
  parentVehicle: Vehicle;
  scan: Scan;
  scanFileObj: File;
  form: UntypedFormGroup;
  decimationLevelForm: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  parentForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  scannerForm: UntypedFormGroup;
  scanDetailsForm: UntypedFormGroup;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  isDebugging$: Observable<boolean>;
  manufacturer$: Observable<Manufacturer>;
  manufacturers$: Observable<Manufacturer[]>;
  scanner$: Observable<Scanner>;
  scanners$: Observable<Scanner[]>;
  shipClass$: Observable<ShipClass>;
  shipClasses$: Observable<ShipClass[]>;
  shipDesignation$: Observable<ShipDesignation>;
  shipDesignations$: Observable<ShipDesignation[]>;
  hasPanoFileName: boolean = false;
  hasScanFileName: boolean = false;
  canUsePointCloudFileForPanos: boolean = false;
  usePointCloudFileForPanos: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ScanDialogComponent>,
    private router: Router,
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private fileService: FileService,
    private manufacturerService: ManufacturerService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private userService: UserService,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      isNewScan: boolean;
      parentShip: Ship;
      parentVehicle: Vehicle;
      scan: Scan;
    }
  ) {
    this.currentUser = data.currentUser;
    this.isNewScan = data.isNewScan;
    this.parentShip = data.parentShip;
    this.parentVehicle = data.parentVehicle;
    this.scan = data.scan;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.manufacturer$ = this.scanService.currentShipScanManufacturer$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.scanner$ = this.scanService.currentShipScanScanner$;
    this.scanners$ = this.scannerService.manufacturerScanners$;
    this.shipClass$ = this.shipService.currentShipClass$;
    this.shipClasses$ = this.shipClassService.shipClasses$;
    this.shipDesignation$ = this.shipService.currentShipDesignation$;
    this.shipDesignations$ = this.shipDesignationService.shipClassDesignations$;

    zip(
      this.currentUser$,
      this.scanner$,
      this.manufacturer$,
      this.shipClass$,
      this.shipDesignation$,
      this.dataSources$,
      this.manufacturers$,
      this.scanners$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(([user, scanner, manufacturer, shipClass, shipDesignation, dataSources, manufacturers, scanners]) => ({
          user,
          scanner,
          manufacturer,
          shipClass,
          shipDesignation,
          dataSources,
          manufacturers,
          scanners,
        }))
      )
      .subscribe(
        ({ user, scanner, manufacturer, shipClass, shipDesignation, dataSources, manufacturers, scanners }) => {
          this.currentUser = user;
          this.hasPanos = this.scan.panoramic?.scanId !== 'undefined' ? true : false,

          this.decimationLevelForm = new UntypedFormGroup({
            value: new UntypedFormControl(
              this.scan.decimationLevel ? this.scan.decimationLevel.value : 0,
              Validators.required
            ),
            units: new UntypedFormControl(
              this.scan.decimationLevel ? this.scan.decimationLevel.units : 'mm',
              Validators.required
            ),
          });

          this.registrationErrorForm = new UntypedFormGroup({
            value: new UntypedFormControl(
              this.scan.registrationError ? this.scan.registrationError.value : 0,
              Validators.required
            ),
            units: new UntypedFormControl(
              this.scan.registrationError ? this.scan.registrationError.units : 'mm',
              Validators.required
            ),
          });

          this.fileForm = new UntypedFormGroup({
            editorId: new UntypedFormControl(this.currentUser._id, Validators.required),
            fileType: new UntypedFormControl(this.scan.fileType, Validators.required),
            scanFileName: new UntypedFormControl(this.scan.name, Validators.required),
            scanModName:  new UntypedFormControl(this.scan.name, Validators.required),
            url: new UntypedFormControl(this.scan.url, Validators.required),
            hasPanos: new UntypedFormControl(this.hasPanos, Validators.required),
            usePointCloudFileForPanos: new UntypedFormControl(this.usePointCloudFileForPanos, Validators.required),
            panoModId: new UntypedFormControl(this.scan.panoramic?.modId),
            panoFileName: new UntypedFormControl(this.scan.panoramic?.name),
            panoFileType: new UntypedFormControl(this.scan.fileType),
            panoModName: new UntypedFormControl(this.scan.panoramic?.name),
            panoScanId: new UntypedFormControl(this.scan.panoramic?.scanId),
            panoUrl: new UntypedFormControl(this.panoUrl),
            siteFileName: new UntypedFormControl(this.scan.siteFileName, Validators.required)
          });

          this.parentForm = new UntypedFormGroup({
            _id: new UntypedFormControl(this.scan.parent ? this.scan.parent._id : null, Validators.required),
            collection: new UntypedFormControl(
              this.scan.parent ? this.scan.parent.collection : null,
              Validators.required
            ),
          });

          this.scanForm = fb.group({
            _id: [this.scan._id, Validators.required],
            editorId: new UntypedFormControl(this.currentUser._id, Validators.required),
            creatorId: [this.scan.creatorId, Validators.required],
            classification: [this.scan.classification || 'Unclass', Validators.required],
            dataSourceId: [this.scan.dataSourceId, Validators.required],
            description: [this.scan.description],
            modId: [this.scan.modId],
            name: [this.scan.name, Validators.required],
            nameOfPersonScanning: [this.scan.nameOfPersonScanning, Validators.required],
            parent: this.parentForm,
            scanDate: [this.scan.scanDate, Validators.required],
            scanUploader: [this.scan.scanUploader, Validators.required],
          });

          this.scannerForm = fb.group({
            manufacturerId: [scanner ? scanner.manufacturerId : '', Validators.required],
            scannerId: [this.scan.scannerId, Validators.required],
          });

          this.scanDetailsForm = fb.group({
            decimationLevel: this.decimationLevelForm,
            registrationError: this.registrationErrorForm,
            scanType: [this.scan.scanType, Validators.required],
          });

          this.form = fb.group({
            fileForm: this.fileForm,
            scanForm: this.scanForm,
            scannerForm: this.scannerForm,
            scanDetailsForm: this.scanDetailsForm,
          });
        }
      );
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  get canUsePCFileForPanos(): boolean {
    return this.canUsePointCloudFileForPanos;
  }

  get formTitle(): string {
    let returnValue = '';

    if (this.isNewScan) {
      returnValue = 'Add New Scan to ';
      if (this.parentShip) {
        returnValue += `Ship ${this.parentShip.name}`;
      } else if (this.parentVehicle) {
        returnValue += `Vehicle ${this.parentVehicle.name}`;
      }
    } else {
      returnValue = `Edit Scan ${this.scan.name} Scanned on ${this.scan.scanDate ? new Date(this.scan.scanDate).toLocaleDateString('en-US') : ''
        }`;
    }
    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get panoCloudFileExtensions(): string {
    return environment.panoCloudFileExtensions.join(',');
  }

  get pointCloudFileExtensions(): string {
    return environment.pointCloudFileExtensions.join(',');
  }

  addDataSource(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Scan Data Source',
      html: `<input type="text" id="newDataSourceName" class="swal2-input" placeholder="Organization Name" title="Organization Name" required>
      <input type="url" id="newDataSourceUrl" class="swal2-input" placeholder="http://www.example.com" pattern="https?://.+" title="Organization URL (optional)">
      <textarea id="newDataSourceDescription" class="swal2-input" placeholder="Organization Description" rows="2" cols="50" title="Organization Description (optional)">`,
      confirmButtonText: 'Add Data Source',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#newDataSourceName').value;
        const description = Swal.getPopup().querySelector('#newDataSourceDescription').value;
        const url = Swal.getPopup().querySelector('#newDataSourceUrl').value;

        if (!name) {
          Swal.showValidationMessage('Please enter scan data source organization name');
        }
        const ds: DataSource = {
          name: name,
          creatorId: this.currentUser._id,
        };

        if (url) {
          ds.url = url;
        }

        if (description) {
          ds.description = description;
        }

        return ds;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSourceService
          .createNewDataSource(result.value, _this.currentUser)
          .then((newDataSource: DataSource) => {
            console.log(`successfully created new data source: ${newDataSource.name}`);
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = error.message;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `Unable to create new data source: ${error.message}.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    });
  }

  addManufacturer(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Scanner Manufacturer',
      html: `<input type="text" id="newManufacturerName" class="swal2-input" placeholder="Organization Name" required>
      <input type="url" id="newManufacturerUrl" class="swal2-input" placeholder="http://www.example.com" pattern="https?://.+" title="Organization URL (optional)">`,
      confirmButtonText: 'Add Scanner Manufacturer',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#newManufacturerName').value;
        const url = Swal.getPopup().querySelector('#newManufacturerUrl').value;

        if (!name) {
          Swal.showValidationMessage('Please enter scanner manufacturer organization name');
        }
        const manufacturer: Manufacturer = {
          name: name,
          creatorId: this.currentUser._id,
        };

        if (url) {
          manufacturer.url = url;
        }

        return manufacturer;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.manufacturerService
          .createNewManufacturer(result.value, _this.currentUser)
          .then((manufacturer) => {
            this.errorMsg = false;
            this.errorText = '';
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = error.message;
            _this.errorService.handleError(
              `Error creating new manufacturer ${JSON.stringify(result.value)}: ${error.message}`
            );
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Creating Manufacturer`,
                `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      }
    });
  }

  addScanner(): void {
    const _this = this;
    const manufacturerId = this.scannerForm.controls.manufacturerId.value;

    this.manufacturerService
      .getManufacturerById(manufacturerId, _this.currentUser)
      .then((manufacturer) => {
        if (manufacturer) {
          Swal.fire({
            title: 'Add Scanner',
            html: `<input type="text" id="newScannerName" class="swal2-input" placeholder="Scanner Name" required>
        <input type="text" id="newScannerModelNumber" class="swal2-input" placeholder="Model number" required>
        <input type="number" id="newScannerAccuracy" class="swal2-input" placeholder="Positional Accuracy (mm)" min="0" pattern="[0-9]" step="1" required>
        <input type="number" id="newScannerPositionalDistance" class="swal2-input" placeholder="Positional Distance (m)" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" required>
        <input type="url" id="newScannerUrl" class="swal2-input" placeholder="http://www.example.com/scanner" pattern="https?://.+" title="Scanner URL (optional)">`,
            confirmButtonText: 'Add Scanner',
            showCancelButton: true,
            focusConfirm: false,
            width: '50%',
            preConfirm: () => {
              const name = Swal.getPopup().querySelector('#newScannerName').value;
              const modelNumber = Swal.getPopup().querySelector('#newScannerModelNumber').value;
              const accuracy = parseInt(Swal.getPopup().querySelector('#newScannerAccuracy').value);
              const distance = parseFloat(Swal.getPopup().querySelector('#newScannerPositionalDistance').value);
              const url = Swal.getPopup().querySelector('#newScannerUrl').value;

              if (!name || !modelNumber || isNaN(accuracy) || isNaN(distance)) {
                Swal.showValidationMessage('Scanner name, model number, accuracy and distance are required');
              }

              const scanner: Scanner = {
                name: name,
                manufacturerId: manufacturerId,
                modelNumber: modelNumber,
                positionalError: {
                  accuracy: accuracy,
                  accuracyUnits: 'mm',
                  distance: distance,
                  distanceUnits: 'm',
                },
                creatorId: _this.currentUser._id,
              };

              if (url) {
                scanner.url = url;
              }

              return scanner;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.scannerService
                .createNewScanner(result.value, _this.currentUser)
                .then((scanner: Scanner) => {
                  this.errorMsg = false;
                  this.errorText = '';
                })
                .catch((scannerError) => {
                  _this.errorMsg = true;
                  _this.errorText = scannerError.message;
                  _this.errorService.handleError(
                    `Error creating scanner ${JSON.stringify(result.value)}: ${scannerError.message}`
                  );
                  if (_this.settingsService.getShowPopupErrorMessages()) {
                    Swal.fire(
                      `Error Creating Scanner`,
                      `${scannerError}.  Please email ${environment.techSupportEmail} with any questions.`,
                      'error'
                    );
                  }
                });
            }
          });
        } else {
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Scanner Manufacturer is Required',
              'You must select a scanner manufacturer before adding a new scanner.',
              'error'
            );
          }
        }
      })
      .catch((error) => {
        _this.errorMsg = true;
        _this.errorText = error.message;
        _this.errorService.handleError(`Error getting scanner manufacturerId ${manufacturerId}: ${error.message}`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Getting Scanner Manufacturer',
            `There was an error getting the scanner manufacturer: ${error.message}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  getShowScanSaveButton(role: string) {
    const isAdmin = role === UserRolesEnum.ADMIN;
    let returnValue = false;

    if (this.isNewScan) {
      if (isAdmin && this.form.dirty && this.form.valid) {
        returnValue = true;
      }
    } else if (isAdmin && this.form.dirty && this.form.valid) {
      returnValue = true;
    }

    return returnValue;
  }

  onManufacturerChange(currentUser: User) {
    this.scannerService.refreshScannersByManufacturer(this.scannerForm.get('manufacturerId').value, currentUser);
  }

  async panoFileInputChange($event: Event): Promise<any> {
    this.errorMsg = false;

    const FILE = ($event.target as HTMLInputElement).files[0];
    this.panoFileObj = FILE;
    const fileType = this.panoFileObj.name.substring(this.panoFileObj.name.lastIndexOf('.')).toLowerCase();
    const fileTypeIdx = environment.pointCloudFileExtensions.indexOf(fileType);
    console.log(this.panoFileObj);

    if (!this.panoFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select a panoramic scan file';
    } else if (fileTypeIdx === -1) {
      this.errorMsg = true;
      this.errorText = `Only files with the following extensions are allowed: ${environment.panoCloudFileExtensions.join(',')}`;
    } else {
      this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.SCAN));

      if (this.parentShip) {
        this.fileService
          .uploadFile(
            this.panoFileObj,
            FileObjectTypesEnum.SHIP,
            this.parentShip._id,
            tags,
            TagFileTypesEnum.SCAN,
            this.currentUser
          )
          .then((results) => {
            this.fileForm.controls.panoUrl.setValue(results.locationUrl);
            this.fileForm.controls.panoUrl.markAsDirty();
            this.fileForm.controls.panoFileName.setValue(results.nameWithoutExtension);
            this.fileForm.controls.panoFileName.markAsDirty();
            this.fileForm.controls.panoFileType.setValue(fileType);
            this.fileForm.controls.panoFileType.markAsDirty();
            this.fileForm.controls.panoModId.setValue(new ObjectID().toString());
            this.fileForm.controls.panoModId.markAsDirty();
            this.hasPanoFileName = true;
            this.settingsService.setIsLoading(false);

            if (results.processing) {
              Swal.fire(
                'File Upload in Process',
                'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                'info'
              );
            }
          })
          .catch((uploadError) => {
            this.settingsService.setIsLoading(false);
            this.errorMsg = true;
            this.errorText = uploadError.errMessage;
            this.hasPanoFileName = false;
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Panoramic Scan File',
                `${uploadError}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      } else {
        this.settingsService.setIsLoading(false);
        const errMessage = this.errorService.handleError(`A panoramic scan must be tied to a parent ship.`);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `A panormic scan must be tied to a ship.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    }
  }

  async scanFileInputChange($event: Event): Promise<any> {
    const _this = this;
    console.log($event);
    _this.errorMsg = false;

    const FILE = ($event.target as HTMLInputElement).files[0];
    _this.scanFileObj = FILE;
    const fileType = _this.scanFileObj.name.substring(_this.scanFileObj.name.lastIndexOf('.')).toLowerCase();
    const fileTypeIdx = environment.pointCloudFileExtensions.indexOf(fileType);
    const panoFileTypeIdx = environment.panoCloudFileExtensions.indexOf(fileType);
    console.log(_this.scanFileObj);

    if (!_this.scanFileObj) {
      _this.errorMsg = true;
      _this.errorText = 'Please select a scan file';
    } else if (fileTypeIdx === -1) {
      _this.errorMsg = true;
      _this.errorText = `Only point cloud files with the following extensions are allowed: ${environment.pointCloudFileExtensions.join(',')}`;
    } else {
      _this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.SCAN));

      if (_this.parentShip) {
        _this.canUsePointCloudFileForPanos = panoFileTypeIdx >= 0;
        _this.usePointCloudFileForPanos = _this.canUsePointCloudFileForPanos; 

        _this.fileService
          .uploadFile(
            _this.scanFileObj,
            FileObjectTypesEnum.SHIP,
            _this.parentShip._id,
            tags,
            TagFileTypesEnum.SCAN,
            _this.currentUser
          )
          .then((results) => {
            const pcModId = new ObjectID().toString();
            const panoModId = new ObjectID().toString();
            const panoScanId = new ObjectID().toString();
            let siteFileNameWithoutExtension = `PC_${pcModId}`;

            _this.fileForm.controls.url.setValue(results.locationUrl);
            _this.fileForm.controls.url.markAsDirty();
            _this.fileForm.controls.scanFileName.setValue(results.nameWithoutExtension);
            _this.fileForm.controls.scanFileName.markAsDirty();
            _this.fileForm.controls.fileType.setValue(fileType);
            _this.fileForm.controls.fileType.markAsDirty();
            _this.scanForm.controls.modId.setValue(pcModId);
            _this.scanForm.controls.modId.markAsDirty();
            _this.fileForm.controls.scanModName.setValue(`PC_${pcModId}`);
            _this.fileForm.controls.scanModName.markAsDirty();
            _this.hasScanFileName = true;

            if (_this.canUsePointCloudFileForPanos) {
              //default to true if the file can be used
              siteFileNameWithoutExtension += `_P_${pcModId}`;
              _this.usePointCloudFileForPanos = true;
              _this.fileForm.controls.panoUrl.setValue(results.locationUrl);
              _this.hasPanoFileName = true;

              _this.fileForm.controls.panoModId.setValue(panoModId);
              _this.fileForm.controls.panoFileName.setValue(_this.scanFileObj.name);
              _this.fileForm.controls.panoFileType.setValue(fileType);
              _this.fileForm.controls.panoScanId.setValue(panoScanId);

            } else {
              _this.usePointCloudFileForPanos = false;
              _this.hasPanoFileName = false;

              _this.fileForm.controls.panoModId.setValue('');
              _this.fileForm.controls.panoFileName.setValue('');
              _this.fileForm.controls.panoScanId.setValue('');
            }

            _this.fileForm.controls.panoModName.setValue(`P_${pcModId}`);
            _this.fileForm.controls.siteFileName.setValue(`${siteFileNameWithoutExtension}.json`);

            _this.settingsService.setIsLoading(false);
            
            if (results.processing) {
              Swal.fire(
                'File Upload in Process',
                'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                'info'
              );
            }
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorMsg = true;
            _this.errorText = uploadError.errMessage;
            _this.hasScanFileName = false;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Scan',
                `There was an error uploading the scan.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      } else if (_this.parentVehicle) {
        _this.fileService
          .uploadFile(
            _this.scanFileObj,
            FileObjectTypesEnum.VEHICLE,
            _this.parentVehicle._id,
            tags,
            TagFileTypesEnum.SCAN,
            _this.currentUser
          )
          .then((results) => {
            const modId = new ObjectID().toString();

            _this.fileForm.controls.url.setValue(results.locationUrl);
            _this.fileForm.controls.url.markAsDirty();
            _this.fileForm.controls.scanFileName.setValue(results.nameWithoutExtension);
            _this.fileForm.controls.scanFileName.markAsDirty();
            _this.fileForm.controls.fileType.setValue(fileType);
            _this.fileForm.controls.fileType.markAsDirty();
            _this.scanForm.controls.modId.setValue(modId);
            _this.scanForm.controls.modId.markAsDirty();
            _this.fileForm.controls.scanModName.setValue(`SM_${modId}`);
            _this.fileForm.controls.scanModName.markAsDirty();
            _this.hasScanFileName = true;
            _this.settingsService.setIsLoading(false);

            if (results.processing) {
              Swal.fire(
                'File Upload in Process',
                'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                'info'
              );
            }
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorMsg = true;
            _this.errorText = uploadError.errMessage;
            _this.hasScanFileName = false;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Scan',
                `There was an error uploading the scan.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(`A scan must be tied to a parent ship or vehicle.`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `A scan must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    }
  }

  saveScanChanges(currentUser): void {
    this.settingsService.setIsLoading(true);
    const promises = [];

    if (this.isNewScan) {
      const scanInfo = {
        /* ...this.fileForm.value, */
        ...this.scanForm.value,
        ...this.scannerForm.value,
        ...this.scanDetailsForm.value,
      };

      const modInfo = {
        _id: scanInfo.modId,
        name: this.fileForm.controls.scanModName.value,
        creatorId: scanInfo.creatorId || currentUser._id,
        modSource: {
          _id: scanInfo._id,
          collection: DbCollectionsEnum.SCANS
        },
        modState: ModStatesEnum.IN_PROCESSING,
        modType: this.parentShip ? ModTypesEnum.STATIC_SCAN : ModTypesEnum.DYNAMIC_SCAN,
        parent: {
          _id: scanInfo.parent._id,
          collection: scanInfo.parent.collection
        }
      };

      //set the values from the fileForm controls
      scanInfo.editorId = this.fileForm.controls.editorId.value;
      scanInfo.fileType = this.fileForm.controls.fileType.value;
      scanInfo.scanDisplayType = ScanDisplayTypesEnum.POINT_CLOUD;
      scanInfo.scanFileName = this.fileForm.controls.scanFileName.value;
      scanInfo.siteFileName = this.fileForm.controls.siteFileName.value;
      scanInfo.url = this.fileForm.controls.url.value;

      //the values are in the controls but not in the .value object?
      scanInfo.creatorId = scanInfo.creatorId || currentUser._id;
      scanInfo.scanUploader = scanInfo.scanUploader || currentUser.fullName;
      scanInfo._id = scanInfo._id || this.scanForm.controls._id.value;

      //save pano scan and related info if appropriate
      if (this.hasPanos && this.hasPanoFileName && this.parentShip) {
        scanInfo.panoramic = {
          modId: this.fileForm.controls.panoModId.value,
          name: this.fileForm.controls.panoModName.value,
          scanId: this.fileForm.controls.panoScanId.value
        };

        const panoScanInfo = {
          ...this.scanForm.value,
          ...this.scannerForm.value,
          ...this.scanDetailsForm.value
        };

        panoScanInfo._id = this.fileForm.controls.panoScanId.value;
        panoScanInfo.creatorId = currentUser._id;
        panoScanInfo.editorId = currentUser._id;
        panoScanInfo.fileType = this.fileForm.controls.panoFileType.value;
        panoScanInfo.modId = this.fileForm.controls.panoModId.value;
        panoScanInfo.name = this.fileForm.controls.panoFileName.value;
        panoScanInfo.scanDisplayType = ScanDisplayTypesEnum.PANORAMIC_IMAGES;
        panoScanInfo.siteFileName = this.fileForm.controls.siteFileName.value;
        panoScanInfo.url = this.fileForm.controls.panoUrl.value;

        const panoModInfo = {
          _id: panoScanInfo.modId,
          name: this.fileForm.controls.panoModName.value,
          creatorId: panoScanInfo.creatorId || currentUser._id,
          modSource: {
            _id: panoScanInfo._id,
            collection: DbCollectionsEnum.SCANS
          },
          modState: ModStatesEnum.IN_PROCESSING,
          modType: ModTypesEnum.PANORAMIC_IMAGES,
          parent: {
            _id: panoScanInfo.parent._id,
            collection: panoScanInfo.parent.collection
          }
        }

        promises.push(this.shipService.saveNewShipScan(panoScanInfo, panoModInfo, currentUser));
      }

      if (this.parentShip) {
        promises.push(this.shipService.saveNewShipScan(scanInfo, modInfo, currentUser));
      } else if (this.parentVehicle) {
        promises.push(this.vehicleService.saveNewVehicleScan(scanInfo, modInfo, currentUser));
      } else {
        this.settingsService.setIsLoading(false);
        this.dialogRef.close();
        const errMessage = this.errorService.handleError(`A scan must be tied to a parent ship or vehicle.`);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `A scan must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }

      Promise.allSettled(promises).then((results) => {
        const errors = [];
        results.map((result) => {
          if (result.status !== 'fulfilled') {
            errors.push(result.reason);
          }

          if (errors.length === 0) {
            this.errorMsg = false;
            this.errorText = '';
            this.scanForm.markAsPristine();
            this.dialogRef.close();
            this.settingsService.setIsLoading(false);
          } else {
            this.settingsService.setIsLoading(false);
            this.errorMsg = true;
            this.errorText = errors.join(',');
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Creating Scan',
                `There were one or more errors creating the new scan(s):  ${this.errorText}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          }
        });
      });
    } else {
      let changes = {
        editorId: currentUser._id
      };

      const fileChanges = getUpdates(this.fileForm, changes);
      const scanChanges = getUpdates(this.scanForm, changes);
      const scannerChanges = getUpdates(this.scannerForm, changes);
      const scanDetailsChanges = getUpdates(this.scanDetailsForm, changes);

      const changedKeys = Object.keys(changes);

      if (changedKeys.length > 1) {
        if (this.parentShip) {
          if (this.hasPanos && this.fileForm.controls.panoScanId.value) {
            promises.push(this.shipService.saveShipScan(this.fileForm.controls.panoScanId.value, changes, currentUser));
          }

          promises.push(this.shipService.saveShipScan(this.scanForm.controls._id.value, changes, currentUser));

          Promise.allSettled(promises).then((results) => {
            const errors = [];
            results.map((result) => {
              if (result.status !== 'fulfilled') {
                errors.push(result.reason);
              }
    
              if (errors.length === 0) {
                this.settingsService.setIsLoading(false);
                this.dialogRef.close();
                this.form.markAsPristine();
              } else {
                this.settingsService.setIsLoading(false);
                this.errorMsg = true;
                this.errorText = errors.join(',');
                if (this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error Saving Vehicle Scan',
                    `There were one or more errors saving your scan changes:  ${this.errorText}.  Please email ${environment.techSupportEmail} with any questions.`,
                    'error'
                  );
                }
              }
            });
          });
        } else if (this.parentVehicle) {
          this.vehicleService
            .saveVehicleScan(this.scanForm.controls._id.value, changes, currentUser)
            .then((updatedScan: Scan) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              this.form.markAsPristine();
            })
            .catch((error) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `There was an error saving the scan changes.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            });
        } else {
          this.settingsService.setIsLoading(false);
          this.dialogRef.close();
          const errMessage = this.errorService.handleError(`A scan must be tied to a parent ship or vehicle.`);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `A scan must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      } else {
        this.settingsService.setIsLoading(false);
        this.dialogRef.close();
      }
    }
  }

  setHasPanos(hasPanos: boolean) {
    this.hasPanos = hasPanos;

    if (this.hasPanos){
      this.fileForm.controls.panoFileName.setValidators([Validators.required]);
      this.fileForm.controls.panoFileType.setValidators([Validators.required]);
      this.fileForm.controls.panoModId.setValidators([Validators.required]);
      this.fileForm.controls.panoModName.setValidators([Validators.required]);
      this.fileForm.controls.panoScanId.setValidators([Validators.required]);
      this.fileForm.controls.panoUrl.setValidators([Validators.required]);
    } else {
      this.fileForm.controls.panoFileName.clearValidators();
      this.fileForm.controls.panoFileType.clearValidators();
      this.fileForm.controls.panoModId.clearValidators();
      this.fileForm.controls.panoModName.clearValidators();
      this.fileForm.controls.panoScanId.clearValidators();
      this.fileForm.controls.panoUrl.clearValidators();
    }
  }

  setUsePointCloudFileForPanos(usePCFile: boolean) {
    this.usePointCloudFileForPanos = usePCFile;
  }
}
