import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Ship, User } from '@shared/models';
import { ErrorService, LogService, SettingsService, ShipService, UserService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';
import { environment } from '@environment';

@UntilDestroy()
@Component({
    selector: 'app-ship-list',
    templateUrl: './ship-list.component.html',
    styleUrls: ['./ship-list.component.css'],
    standalone: false
})
export class ShipListComponent implements OnInit {
  @Input()
  ships: Ship[];

  @ViewChild(MatSort) sort: MatSort;

  currentUser$: Observable<User>;
  dataSource;
  displayedShipColumns: string[] = ['name', 'designationWithHullNumber', 'updatedAt'];
  selectedShipId = null;
  selectedShip$: Observable<Ship>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.ships);
    this.selectedShip$ = this.shipService.currentShip$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  get formTitle(): string {
    let returnValue = 'Choose Ship';

    this.selectedShip$.subscribe((ship) => {
      if (ship) {
        returnValue = `Selected Ship: ${ship.name} | ${ship.designationWithHullNumber ? ship.designationWithHullNumber : ship.hullNumber.toString()
          }`;
      }
    });

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  getShipDisplayClass(ship: Ship): string {
    let returnValue = '';

    if (ship) {
      this.selectedShip$.subscribe((selectedShip) => {
        if (selectedShip && selectedShip._id === ship._id) {
          returnValue = 'selected-ship';
        }
      });
    }

    return returnValue;
  }

  getShipDesignation(ship: Ship): String {
    let returnValue = '';

    if (ship) {
      returnValue = ship.designationWithHullNumber || ship.hullNumber.toString();
    }

    return returnValue;
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }

  selectShip(ship: Ship): void {
    this.settingsService.setIsLoading(true);
    const currentUser = this.userService.getCurrentUser();

    this.shipService
      .getShipById(ship._id, currentUser)
      .then((shipWithChildData: Ship) => {
        //make sure table row is highlighted too, may have selected in the card view and then flip
        this.selectedShipId = ship._id;
      })
      .catch((error) => {
        this.settingsService.setIsLoading(false);
        this.selectedShipId = null;
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `Error selecting ship ${ship.name}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      })
      .finally(() => {
        this.settingsService.setIsLoading(false);
      });
  }
}
